import type { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

const FormPageLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="w-full h-screen flex flex-col relative">
      <div className="border-b border-b-gray-300 px-6 xl:px-20 flex justify-between items-center py-12 bg-white">
        <Link to="/" className="flex items-center">
          <img
            width="43px"
            height="31px"
            src="/logo.svg"
            className="w-8 h-6"
            alt="logo"
          />
          <h3 className="uppercase text-black lg:text-[30px] font-semibold mx-2 text-lg">
            Yepic
          </h3>
          <div className="flex h-8 items-center">
            <img
              src="/ai1.svg"
              className=" mr-1.5 w-[7px] lg:w-auto lg:h-auto"
            />
            <img src="/ai2.svg" className="mr-0.5 w-2.5 lg:w-auto lg:h-auto" />
            <img src="/ai3.svg" className="w-[3px] lg:w-auto lg:h-auto" />
          </div>
        </Link>
        <Link to="/" className="text-secondary font-normal flex justify-center">
          Go to Chat
        </Link>
      </div>
      <div className="w-full h-[calc(100vh-129px)] overflow-x-scroll">
        {children}
      </div>
    </div>
  );
};

export default FormPageLayout;
