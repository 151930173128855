import { create } from 'zustand';
import avatarService from '../services/avatar.service';
import { GenderOptions, IAvatar } from '../types/types';
import axios, { AxiosResponse } from 'axios';
import useVoiceStore, { getDefaultVoiceId } from './useVoiceStore';

type AvatarStore = {
  activeAvatar: IAvatar | null;
  publicAvatars: IAvatar[] | null;
  talkingPhotos: IAvatar[] | null;
  customTalkingPhotos: IAvatar[] | null;
  filteredAvatars: IAvatar[] | null;
  filteredTalkingPhotos: IAvatar[] | null;
  filteredCustomTalkingPhotos: IAvatar[] | null;
  setFilteredAvatars: () => void;
  setFilteredTalkingPhotos: () => void;
  setFilteredCustomTalkingPhotos: () => void;
  fetchAvatars: () => Promise<void>;
  selectAvatar: (avatar: IAvatar | IAvatar) => void;
  genderFilters: GenderOptions[];
  setGenderFilters: (value: GenderOptions) => void;
  resetGenders: () => void;
  deleteCustomTalkingPhoto: (avatarId: string) => Promise<AxiosResponse>;
};

const useAvatarStore = create<AvatarStore>((set, get) => ({
  activeAvatar: null,
  publicAvatars: null,
  customTalkingPhotos: null,
  talkingPhotos: [],
  fetchAvatars: async () => {
    try {
      const actors = (await avatarService.fetchAll()) as IAvatar[];

      const publicAvatars = actors.filter(
        (a) => a.publicAvatar && !a.talkingPhoto && !a.customAvatar
      );
      const talkingPhotos = actors.filter(
        (a) => a.talkingPhoto && !a.customAvatar
      );

      const customTalkingPhotos = actors.filter(
        (a) => a.talkingPhoto && a.customAvatar
      );

      set({
        publicAvatars,
        customTalkingPhotos,
        talkingPhotos
      });
      get().setFilteredAvatars();
      get().setFilteredTalkingPhotos();
      get().setFilteredCustomTalkingPhotos();

      const defaultActor = actors.find(
        (a) => a.id === '2ecf3901-f7a4-4478-a6d9-1c9dfe57dd6d'
      );
      if (defaultActor) get().selectAvatar(defaultActor);
    } catch (error) {
      console.error(error);
    }
  },
  selectAvatar: async (avatar) => {
    if (avatar.hasIdleLoop === undefined) {
      if (!avatar.idleLoopVideoUrl) {
        avatar.hasIdleLoop = false;
      } else {
        avatar.hasIdleLoop = await axios
          .head(avatar.idleLoopVideoUrl)
          .then(() => true)
          .catch(() => false);

        if (avatar.customAvatar) {
          set({
            customTalkingPhotos: get().customTalkingPhotos?.map((photo) =>
              photo.id === avatar.id ? avatar : photo
            )
          });
        } else {
          set({
            talkingPhotos: get().talkingPhotos?.map((photo) =>
              photo.id === avatar.id ? avatar : photo
            )
          });
        }
      }
    }

    const { chooseVoice, selectedVoice, voices } = useVoiceStore.getState();
    if (selectedVoice?.gender.toLowerCase() !== avatar.gender.toLowerCase()) {
      const voice = voices?.find(
        (v) => v.providerVoiceId === getDefaultVoiceId(avatar.gender)
      );
      if (voice) chooseVoice(voice);
    }
    set({
      activeAvatar: avatar
    });
  },
  filteredAvatars: null,
  filteredTalkingPhotos: null,
  filteredCustomTalkingPhotos: null,
  setFilteredAvatars: () => {
    const avatars = get().publicAvatars;
    const genderFilters = get().genderFilters;
    set({
      filteredAvatars: avatars
        ?.filter(
          (avatar) =>
            !genderFilters.length ||
            genderFilters.includes(
              avatar.gender?.toLowerCase() as GenderOptions
            )
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .sort((a, b) => {
          // Sort by presence of idle_video_url property
          const hasIdleVideoA = !!a.idleLoopVideoUrl;
          const hasIdleVideoB = !!b.idleLoopVideoUrl;

          if (hasIdleVideoA && !hasIdleVideoB) {
            return -1; // a has idle_video_url, prioritize a
          } else if (!hasIdleVideoA && hasIdleVideoB) {
            return 1; // b has idle_video_url, prioritize b
          }

          // Both have or don't have idle_video_url, sort alphabetically by name
          return a.name > b.name ? 1 : -1;
        })
    });
  },
  setFilteredTalkingPhotos: () => {
    const talkingPhotos = get().talkingPhotos;
    const genderFilters = get().genderFilters;
    set({
      filteredTalkingPhotos: talkingPhotos
        ?.filter(
          (avatar) =>
            !genderFilters.length ||
            genderFilters.includes(
              avatar.gender?.toLowerCase() as GenderOptions
            )
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .sort((a, b) => {
          // Sort by presence of idle_video_url property
          const hasIdleVideoA = !!a.idleLoopVideoUrl;
          const hasIdleVideoB = !!b.idleLoopVideoUrl;

          if (hasIdleVideoA && !hasIdleVideoB) {
            return -1; // a has idle_video_url, prioritize a
          } else if (!hasIdleVideoA && hasIdleVideoB) {
            return 1; // b has idle_video_url, prioritize b
          }

          // Both have or don't have idle_video_url, sort alphabetically by name
          return a.name > b.name ? 1 : -1;
        })
    });
  },
  setFilteredCustomTalkingPhotos: () => {
    const customTalkingPhotos = get().customTalkingPhotos;
    const genderFilters = get().genderFilters;
    set({
      filteredCustomTalkingPhotos: customTalkingPhotos?.filter(
        (avatar) =>
          !genderFilters.length ||
          genderFilters.includes(avatar.gender?.toLowerCase() as GenderOptions)
      )
    });
  },
  genderFilters: [],
  resetGenders: () => {
    set({
      genderFilters: []
    });
    get().setFilteredAvatars();
    get().setFilteredTalkingPhotos();
    get().setFilteredCustomTalkingPhotos();
  },
  setGenderFilters: (value) => {
    const genderFilters = get().genderFilters;
    if (genderFilters.includes(value)) {
      set({
        genderFilters: genderFilters.filter((filter) => filter !== value)
      });
    } else {
      set({
        genderFilters: [...genderFilters, value]
      });
    }
    get().setFilteredAvatars();
    get().setFilteredTalkingPhotos();
    get().setFilteredCustomTalkingPhotos();
  },
  deleteCustomTalkingPhoto: async (avatarId: string) => {
    const response = await avatarService.deleteCustomTalkingPhoto(avatarId);
    await get().fetchAvatars();
    return response;
  }
}));

export default useAvatarStore;
