import axios, { AxiosRequestConfig } from 'axios';
import { getMemberstackJWT } from '../../utils/getMemberstackJWT';

const baseURL = import.meta.env.VITE_BASEURL_NEW;

const instance = axios.create({
  baseURL,
  timeout: 60000
});

const httpDotenvService = {
  get: async (url: string, headers?: AxiosRequestConfig['headers']) => {
    const data = instance.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getMemberstackJWT()}`,
        ...headers
      }
    });
    return data;
  },
  post: async (
    url: string,
    payload: any = {},
    headers?: AxiosRequestConfig['headers']
  ) => {
    const data = instance.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getMemberstackJWT()}`,
        ...headers
      }
    });
    return data;
  },
  delete: async (url: string, headers?: AxiosRequestConfig['headers']) => {
    const data = instance.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getMemberstackJWT()}`,
        ...headers
      }
    });
    return data;
  }
};

export default httpDotenvService;
