import Avatar from '../Avatar/Avatar';
import Chat from './Chat';
import MessageInput from './MessageInput';
import { FC } from 'react';
import { IWebRTCProps } from '../../types/types';
import MatrixBackground from '../Avatar/MatrixBackground';

const FullscreenView: FC<IWebRTCProps> = ({ isWebRTC }) => {
  return (
    <div className="w-full h-full xl:border xl:rounded-lg sm:border-neutral-200 bg-white flex flex-col overflow-auto grow xl:grow-0 xl:shrink relative dark:bg-dark-primary dark:border-neutral-800">
      <Avatar isChatbot={false} isWebRTC={isWebRTC} />
      <MatrixBackground />
      <Chat isChatbot={false} />
      <div className="border-t border-neutral-200 dark:border-neutral-800" />
      <MessageInput isChatbot={false} />
    </div>
  );
};

export default FullscreenView;
