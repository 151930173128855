import { FC, useState, useReducer, useEffect, useContext } from 'react';
import ReactPlayer from 'react-player';
import { useChat } from '../../hooks/useChat';
import useAvatarStore from '../../stores/useAvatarStore';

interface Props {
  index: number;
  videosCounter: number;
  url: string;
  onVideoEnded: () => void;
  onVideoSliceEnded: () => void;
}
const AvatarPlayer: FC<Props> = ({
  videosCounter,
  index,
  url,
  onVideoEnded,
  onVideoSliceEnded
}) => {
  const { onVideoLoaded, avatarVideos } = useChat();
  const [refreshValue, refresh] = useReducer((x) => x + 1, 0);

  const handleVideoLoaded = () => {
    onVideoLoaded(null);
  };
  const handleVideoEnded = () => {
    onVideoEnded();
  };

  return (
    <ReactPlayer
      key={`${url}-${refreshValue}-player`}
      url={url}
      playing={index === videosCounter}
      onEnded={
        avatarVideos?.length === index + 1
          ? () => handleVideoEnded()
          : () => onVideoSliceEnded()
      }
      onError={() => refresh()}
      width="100%"
      height="100%"
      config={{
        file: {
          attributes: {
            preload: 'auto'
          }
        }
      }}
    />
  );
};

export default AvatarPlayer;
