import { FC, Fragment, useState } from 'react';
import { useChat } from '../../hooks/useChat';
import AvatarPlayer from './AvatarPlayer';
import { Transition } from '@headlessui/react';

const AvatarVideo: FC = () => {
  const { avatarVideos, handleVideoEnd } = useChat();
  const [isEnded, setIsEnded] = useState(false);
  const [videosCounter, setVideosCounter] = useState<number>(0);

  const handleVideoEnded = () => {
    setIsEnded(true);
    setTimeout(() => {
      handleVideoEnd();
      setVideosCounter(0);
      setIsEnded(false);
    }, 500);
  };
  const handleVideoSliceEnded = () => {
    setVideosCounter((prev) => prev + 1);
  };

  return (
    <Transition.Root show={!!avatarVideos?.length && !isEnded} as={Fragment}>
      <Transition.Child
        as={'div'}
        className="w-full h-full absolute z-10"
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>
          {avatarVideos?.map((url, index) =>
            (videosCounter > index || index - videosCounter > 4) &&
            index !== avatarVideos?.length - 1 ? null : ( // don't load too much videos
              <div
                key={`${url}-avatar-player`}
                className={`absolute w-full h-full top-0 left-0`}
                style={{
                  zIndex: 100 - index
                }}
              >
                <AvatarPlayer
                  onVideoEnded={handleVideoEnded}
                  onVideoSliceEnded={handleVideoSliceEnded}
                  index={index}
                  url={url}
                  videosCounter={videosCounter}
                />
              </div>
            )
          )}
        </div>
      </Transition.Child>
    </Transition.Root>
  );
};

export default AvatarVideo;
