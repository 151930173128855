import { useState } from 'react';
import ProfileModal from '../Modals/Profile';
import { useAuth } from '../../hooks/useAuth';

type Props = {
  navbarOpened: boolean;
};

const Profile = ({ navbarOpened }: Props) => {
  const { user, isAuth, setAuthModal, credits } = useAuth();
  const [opened, setOpened] = useState(false);
  const firstName =
    user?.firstName.trim() || user?.email.split('@')[0] || 'Anonymous';
  return (
    <>
      <div
        className={`w-full flex items-center cursor-pointer
        ${navbarOpened ? 'justify-start' : 'justify-center'}`}
        onClick={() =>
          isAuth ? user && setOpened(true) : setAuthModal('login')
        }
      >
        <div
          className={`rounded-full min-w-[2.5rem] w-10 h-10 flex bg-gradient-to-r from-[#C256FC] to-[#D12766] items-center justify-center 
        ${navbarOpened ? 'mr-4' : 'm-auto'}`}
        >
          <div className="text-white text-2xl font-medium">
            {user ? firstName[0] : 'G'}
          </div>
        </div>
        <div className={navbarOpened ? 'block truncate' : 'hidden'}>
          <div className="text-base text-neutral-900 font-semibold truncate w-full dark:text-white">
            {user ? `${firstName} ${user.lastName || ''}` : 'Guest'}
          </div>
          <div className="text-[#525252] text-xs font-light leading-5 whitespace-normal dark:text-neutral-200">
            {+credits.creditsAmount.toFixed(2)}/{+credits.creditsMax.toFixed(2)}{' '}
            chat credits
          </div>
        </div>
      </div>
      <ProfileModal onClose={() => setOpened(false)} open={opened} />
    </>
  );
};

export default Profile;
