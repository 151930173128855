import React, { useRef, useState } from 'react';

import { AiFillCaretDown } from 'react-icons/ai';
import { capitalize } from '../../lib/utils';
import useClickOutside from '../../hooks/useClickOutside';
import { VscChevronDown } from 'react-icons/vsc';

type SelectInputProps = {
  name: string;
  options: string[];
  value: string | null;
  onChange: (value: any) => void;
  disabled?: boolean;
  className?: string;
  error?: string | boolean;
  showFont?: boolean;
  scroll?: boolean;
  placeholder?: string;
  borderColorClass?: string;
};

const SelectInput: React.FC<SelectInputProps> = ({
  name,
  options,
  value,
  onChange,
  disabled,
  className,
  error,
  showFont = false,
  scroll = true,
  placeholder = 'Select...',
  borderColorClass = 'border-tertiary'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(selectRef, setIsOpen);

  return (
    <div className={`group relative text-xs mb-2 w-full`}>
      <div
        className="text-base border border-neutral-300 rounded-md text-neutral-500 focus-visible:outline-none disabled:bg-white disabled:opacity-50 text-md focus:outline-none shadow-sm  focus:ring-secondary focus:border-secondary cursor-pointer relative flex items-center justify-between w-full px-4 py-3 
        dark:border-neutral-700 dark:text-neutral-100"
        onClick={() => setIsOpen((prevState) => !prevState)}
        ref={selectRef}
      >
        {value && <span>{capitalize(value)}</span>}
        <VscChevronDown
          className={`right-4 absolute${isOpen && 'transform rotate-180'} `}
        />
        <div
          className={`absolute w-full max-h-[10rem] top-12 py-1 px-1 left-0 overflow-y-scroll bg-white z-50 border border-tertiary rounded-lg dark:bg-neutral-900 dark:text-neutral-100 dark:border-neutral-700 ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          {options?.map((opt) => (
            <div
              onClick={() => onChange(opt)}
              key={`${name}-${opt}`}
              className={`py-2 px-2 flex justify-between ${
                opt === value && 'bg-slate-200 rounded-lg dark:bg-neutral-800'
              }`}
            >
              <span>{opt}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectInput;
