import React, { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FiUploadCloud } from 'react-icons/fi';
import DropZone from '../components/DropZone';
import Loader from '../components/Loader';
import { CustomPhotoFileData } from '../types/types';
import talkingPhotoExample from '../images/talkPhotoExample.png';

type Step1Props = {
  onSetStep: Dispatch<SetStateAction<number>>;
  uploadProgress: number;
  uploadedPhoto: string;
  hasUploadStarted: boolean;
  onDeleteUploaded: () => void;
  onFileUpload: (file: File) => void;
  fileData: CustomPhotoFileData | null;
  isFaceChecking: boolean;
};

const Step1: React.FC<Step1Props> = ({
  onSetStep,
  uploadProgress,
  uploadedPhoto,
  hasUploadStarted,
  onDeleteUploaded,
  onFileUpload,
  fileData,
  isFaceChecking
}) => {
  // TODO: Add isDragActive zone change UI
  const [isDragActive, setIsDragActive] = useState(false);

  const handleDrag = useCallback(
    (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setIsDragActive(true);
      } else if (e.type === 'dragleave') {
        setIsDragActive(false);
      }
    },
    [setIsDragActive]
  );

  const handleUpload = useCallback(
    (files: File[]) => {
      if (files && files[0]) {
        onFileUpload(files[0]);
      }
    },
    [onFileUpload]
  );

  const handleChange = (e: any) => {
    e.preventDefault();
    handleUpload(e.currentTarget.files);
    e.target.value = '';
  };

  const handleDrop = useCallback(
    (e: any) => {
      e.preventDefault();
      e.stopPropagation();

      handleUpload(e.dataTransfer.files);

      // setIsDragActive(false);
    },
    [handleUpload]
  );

  return (
    <>
      <h1 className="text-lg font-semibold sm:mb-2 dark:text-white">
        Upload photo
      </h1>
      <p className="text-neutral-400 mb-2 sm:mb-8 dark:text-neutral-200">
        Photo requirements
      </p>
      <div className="flex flex-col sm:flex-row items-center sm:items-stretch gap-2 space-x-4">
        <div className="bg-gray-100 w-24 h-24 rounded-lg relative shrink-0">
          <img
            src={talkingPhotoExample}
            alt="Talking photo example"
            className="w-full h-full object-contain pt-2 z-10"
          />
          <div className="bg-white absolute right-1.5 bottom-1.5 w-3 h-3 rounded-full"></div>
          <BsFillCheckCircleFill className="text-green-500 absolute right-1 bottom-1" />
        </div>
        <div className="text-sm text-neutral-400 px-2 sm:px-4 flex flex-col sm:flex-row sm:space-x-4 mb-4 sm:mb-9 text-left sm:text-center">
          <ul className="sm:space-y-2 list-disc sm:w-3/5">
            <li>Front facing photo</li>
            <li>Looking directly to the camera</li>
            <li>Face should cover most of photo (~70% area or more)</li>
          </ul>
          <ul className="sm:space-y-2 list-disc sm:w-2/5">
            <li>Closed mouth</li>
            <li>Avoid shadows over face</li>
            <li>Avoid hair over face</li>
          </ul>
        </div>
      </div>

      <div
        className={`text-center rounded-xl p-4 border min-h-[130px] text-neutral-400 ${
          uploadedPhoto ? 'border-secondary' : 'border-gray-200'
        }`}
      >
        {hasUploadStarted ? (
          <div className="flex items-center relative">
            {uploadedPhoto && (
              <div
                className="absolute top-0 right-1 cursor-pointer"
                onClick={onDeleteUploaded}
              >
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 5L15.1327 17.1425C15.0579 18.1891 14.187 19 13.1378 19H4.86224C3.81296 19 2.94208 18.1891 2.86732 17.1425L2 5M7 9V15M11 9V15M12 5V2C12 1.44772 11.5523 1 11 1H7C6.44772 1 6 1.44772 6 2V5M1 5H17"
                    stroke="#ddd"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
            <div className="border border-gray-100 w-24 h-24 rounded relative shrink-0 mr-4 flex items-center justify-center overflow-hidden">
              {uploadedPhoto ? (
                <img
                  src={uploadedPhoto}
                  alt="Uploaded photo"
                  className="w-full h-full object-contain z-10"
                />
              ) : (
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-1/3 h-1/3 z-10"
                >
                  <path
                    d="M9.8 13H3.62091C3.21704 13 3.0151 13 2.92159 12.9201C2.84045 12.8508 2.79739 12.7469 2.80577 12.6405C2.81541 12.5179 2.95821 12.3751 3.24379 12.0895L8.91242 6.42091C9.17643 6.15691 9.30844 6.0249 9.46066 5.97544C9.59455 5.93193 9.73878 5.93193 9.87268 5.97544C10.0249 6.0249 10.1569 6.1569 10.4209 6.42091L13 9V9.8M9.8 13C10.9201 13 11.4802 13 11.908 12.782C12.2843 12.5903 12.5903 12.2843 12.782 11.908C13 11.4802 13 10.9201 13 9.8M9.8 13H4.2C3.0799 13 2.51984 13 2.09202 12.782C1.71569 12.5903 1.40973 12.2843 1.21799 11.908C1 11.4802 1 10.9201 1 9.8V4.2C1 3.0799 1 2.51984 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.51984 1 3.0799 1 4.2 1H9.8C10.9201 1 11.4802 1 11.908 1.21799C12.2843 1.40973 12.5903 1.71569 12.782 2.09202C13 2.51984 13 3.0799 13 4.2V9.8M6 4.66667C6 5.40305 5.40305 6 4.66667 6C3.93029 6 3.33333 5.40305 3.33333 4.66667C3.33333 3.93029 3.93029 3.33333 4.66667 3.33333C5.40305 3.33333 6 3.93029 6 4.66667Z"
                    stroke="#7F56D9"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            <div className="text-sm w-full text-left">
              <div className="text-black font-medium dark:text-white">
                {fileData?.name}
              </div>
              <div className="mb-2">{fileData?.size} Kb</div>
              <div className="flex items-center w-full">
                <div className="w-11/12 border border-gray-200 rounded-full h-3 mr-2 relative">
                  <div
                    style={{ width: `${uploadProgress}%` }}
                    className={`absolute top-0 left-0 h-full bg-secondary border border-secondary rounded-l-full ${
                      uploadProgress === 100 && 'rounded-r-full'
                    }`}
                  ></div>
                </div>
                <div className="text-black font-medium dark:text-white">
                  {uploadProgress}%
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <input
              type="file"
              id="input-file-upload"
              name="input-file-upload"
              multiple={false}
              accept="image/png, image/jpeg, image/jpg"
              className="hidden"
              onChange={handleChange}
            />
            <DropZone onDrag={handleDrag} onDrop={handleDrop}>
              <label htmlFor="input-file-upload" className="cursor-pointer">
                <div className="w-11 h-11 rounded-full bg-gray-50 mx-auto flex justify-center items-center mb-3 cursor-pointer">
                  <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center mx-auto">
                    {isFaceChecking ? (
                      <Loader />
                    ) : (
                      <FiUploadCloud className="mx-auto text-black text-lg" />
                    )}
                  </div>
                </div>
                <div className="text-sm mb-1">
                  <span className="text-secondary font-semibold mr-1 cursor-pointer hover:underline">
                    Click to upload
                  </span>
                  or drag and drop
                </div>
                <div className="text-xs">
                  PNG or JPG formats, HD (1920 high x 1080 wide), larger is
                  better
                </div>
              </label>
            </DropZone>
          </>
        )}
      </div>

      <div
        className="absolute flex flex-col-reverse sm:flex-row gap-2 mt-4 justify-between items-center 
            bottom-0 left-0 sm:left-6 sm:right-4 sm:bottom-4 bg-white z-20 sm:z-auto w-full sm:w-auto py-4 sm:py-0 dark:bg-dark-primary"
      >
        <button
          onClick={() => onSetStep((prevState) => prevState - 1)}
          className="bg-white border border-black text-sm font-semibold rounded py-2.5 px-16 min-w-[180px]"
        >
          Back
        </button>
        <button
          onClick={() => onSetStep((prevState) => prevState + 1)}
          className={`bg-secondary border border-secondary text-white text-sm font-semibold rounded py-2.5 px-16 min-w-[180px] 
            disabled:bg-gray-400 disabled:border-gray-400 disabled:cursor-not-allowed'
          }`}
          disabled={!uploadedPhoto}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default Step1;
