import type { FC } from 'react';
import ChatWrapper from '../components/Chat/ChatWrapper';
import Layout from '../components/Layout';
import { IWebRTCProps } from '../types/types';

const ChatPage: FC<IWebRTCProps> = ({ isWebRTC }) => {
  return (
    <Layout isWebRTC={isWebRTC}>
      <ChatWrapper isWebRTC={isWebRTC} />
    </Layout>
  );
};

export default ChatPage;
