import countries from '../lib/countries.json';
import httpDotenvService from './http/httpDotenv.service';
import { IVoice, Language } from '../types/types';

const voicesSlug = '/voices';

export const voiceService = {
  getVoices: async (
    isWebRTC: boolean
  ): Promise<[IVoice[], string[], Language[]]> => {
    const { data } = await httpDotenvService.get(voicesSlug, {
      'X-Api-Key': import.meta.env.VITE_UNAUTHORIZED_API_KEY
    });
    const voices = data as IVoice[];

    const voicesByLang: IVoice[] = [];

    const filteredVoices = isWebRTC
      ? voices.filter((v) => v.provider === 'Azure')
      : voices;
    for (const voice of filteredVoices) {
      if (!voicesByLang.some((v) => v.languageCode === voice.languageCode)) {
        voicesByLang.push(voice);
      }
    }

    let languages: Language[] = voicesByLang.map((v: any) => {
      const countryCode: keyof typeof countries = v.languageCode.slice(3);
      const country = countries[countryCode];

      return {
        language: v.language,
        country
      };
    });
    const allLangs = { language: 'All languages', country: null };
    const engLangs = languages
      .filter((el: any) => el.language.includes('English'))
      .reverse();
    const nonEngLangs = languages?.filter(
      (el: any) => !el.language.includes('English')
    );

    languages = [allLangs, ...engLangs, ...nonEngLangs];

    const voiceProviders = [
      ...new Set([...filteredVoices.map((v: any) => v.provider)])
    ].sort();

    voiceProviders.unshift('All providers');

    return [filteredVoices, voiceProviders, languages];
  }
};
