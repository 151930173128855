import React, { useState } from 'react';
import Input from '../shared/Input';
import { FiCheck, FiCopy } from 'react-icons/fi';
import {
  BsTwitter,
  BsFacebook,
  BsDiscord,
  BsWhatsapp,
  BsEnvelope,
  BsEnvelopeFill
} from 'react-icons/bs';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from 'react-share';
import ShareViaSMButton from './ShareViaSMButton';
import { IoLogoWhatsapp } from 'react-icons/io';
import getProceccedInviteLinkForSM from '../../utils/getProceccedInviteLinkForSM';

interface Props {
  link: string;
}

const ShareLinkSection = ({ link }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <div className="mt-5 text-sm text-neutral-700 border-b border-gray-200 dark:text-neutral-100 dark:border-gray-800">
      <div>
        Share link
        <div className="mt-1.5 flex gap-x-3.5 items-center">
          <Input
            className="w-full py-2.5 px-3.5 text-neutral-900 text-base truncate dark:text-white"
            readOnly
            value={link}
          />
          <button
            onClick={async () => {
              setIsCopied(true);
              await navigator.clipboard.writeText(link);
              setTimeout(() => setIsCopied(false), 1500);
            }}
          >
            {isCopied ? (
              <FiCheck className="w-5 h-5 text-green-600" />
            ) : (
              <FiCopy className="w-5 h-5" />
            )}
          </button>
        </div>
      </div>
      <div className="flex gap-2 my-4">
        <TwitterShareButton url={getProceccedInviteLinkForSM(link, 'twitter')}>
          <ShareViaSMButton>
            <BsTwitter className="w-6 h-6" />
          </ShareViaSMButton>
        </TwitterShareButton>
        <FacebookShareButton
          url={getProceccedInviteLinkForSM(link, 'facebook')}
        >
          <ShareViaSMButton>
            <BsFacebook className="w-6 h-6" />
          </ShareViaSMButton>
        </FacebookShareButton>
        <WhatsappShareButton
          url={getProceccedInviteLinkForSM(link, 'whatsapp')}
        >
          <ShareViaSMButton>
            <IoLogoWhatsapp className="w-6 h-6" />
          </ShareViaSMButton>
        </WhatsappShareButton>
        <EmailShareButton url={getProceccedInviteLinkForSM(link, 'email')}>
          <ShareViaSMButton>
            <BsEnvelopeFill className="w-6 h-6" />
          </ShareViaSMButton>
        </EmailShareButton>
      </div>
    </div>
  );
};

export default ShareLinkSection;
