import type { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';

const FilledButton: FC<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> = ({ className = '', ...props }) => {
  return (
    <button
      className={`bg-secondary rounded-md flex justify-center items-center text-white ${
        className.includes('text-') ? '' : 'text-sm'
      } disabled:bg-primary-200 dark:disabled:bg-primary-900 dark:disabled:opacity-50 ${className}`}
      {...props}
    />
  );
};

export default FilledButton;
