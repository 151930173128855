import type { FC } from 'react';

export interface Tab<Name extends string = string> {
  name: Name;
  title: string;
  icon: string;
  disabled?: boolean;
}

interface Props<T extends Tab = Tab> {
  tabs: T[] | readonly T[];
  activeTab: T['name'];
  toggleTab: (value: any) => void;
  onOpen: () => any;
  sidebarOpened: boolean;
}

const TabsGroup: FC<Props> = ({
  tabs,
  activeTab,
  toggleTab,
  onOpen,
  sidebarOpened
}) => {
  return (
    <div
      className="w-auto h-screen text-gray-700 text-sm text-center border-l border-solid border-neutral-200 bg-white dark:bg-neutral-900 dark:text-neutral-100 dark:border-neutral-800"
      onClick={onOpen}
    >
      <ul className="flex flex-col justify-center mx-auto mt-6 ">
        {tabs.map((t) => (
          <li
            key={t.name}
            className={`py-4 px-4 ${
              t.name === activeTab && sidebarOpened
                ? ' bg-neutral-50 dark:bg-neutral-800'
                : 'bg-none'
            }`}
          >
            <button
              className="flex flex-col items-center justify-center mx-auto disabled:opacity-50"
              onClick={() => {
                toggleTab(t.name);
              }}
              disabled={t.disabled}
            >
              <img src={t.icon} className="mb-1.5" />
              {t.title}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabsGroup;
