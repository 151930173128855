import React, { Dispatch, SetStateAction } from 'react';

type Step0Props = {
  onSetStep: Dispatch<SetStateAction<number>>;
};

const Step0: React.FC<Step0Props> = ({ onSetStep }) => {
  return (
    <div className="">
      <h1 className="text-lg font-semibold mb-2 dark:text-white">
        Create your Talking Photo
      </h1>
      <p className="text-neutral-400 mb-2 dark:text-neutral-200">
        Create your own talking photo in less than 4 simple steps.{' '}
      </p>
      <div className="my-6">
        <iframe
          className="w-full aspect-video"
          src="https://www.youtube.com/embed/ozpLvZEdVsA"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>

      <div className="sm:absolute right-4 bottom-4 flex justify-center sm:block mt-4">
        <button
          onClick={() => onSetStep((prevState) => prevState + 1)}
          className="bg-secondary border border-secondary text-white text-sm font-semibold rounded py-2.5 px-16 min-w-[180px]"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step0;
