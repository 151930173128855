import {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { setAuthTokenToAxios } from '../lib/setAuthTokenToAxios';
import { authService, userToCamelCase } from '../services/auth.service';
import { ICredits, IUser, IUserData, IUserMetadata } from '../types/types';
import { IUserLoginReq, IUserRegisterReq } from '../types/fetch-types';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AuthModalOpenT } from '../components/Modals/AuthModal';
import { useMemberstackLogin } from '../hooks/useMemberstackLogin';
import { userService } from '../services/user.service';
import {
  getMemberstackJWT,
  removeMemberstackJWT
} from '../utils/getMemberstackJWT';
import { billingService } from '../services/billing.service';
import NoConversations from '../components/Modals/NoConversations';
import Loader from '../components/Loader';
import { logEvent } from 'firebase/analytics';
import firebaseAnalytics from '../config/firebaseAnalytics';
import { useNotificationStore } from '../stores/useNotificationStore';

type AuthContextValue = {
  isAuth: boolean;
  logout: () => Promise<void>;
  login: (values: IUserLoginReq) => Promise<void>;
  signUp: (values: Omit<IUserRegisterReq, 'external_id'>) => Promise<void>;
  updateUser: (metadata: IUserMetadata) => Promise<void>;
  user: null | IUser;
  userData: null | IUserData;
  authModal: AuthModalOpenT;
  setAuthModal: Dispatch<SetStateAction<AuthModalOpenT>>;
  setNoCreditsModal: Dispatch<SetStateAction<boolean>>;
  credits: ICredits;
  getCredits: () => Promise<void>;
  checkCreditBalanceAndShowError: () => void;
  verify: (token: string) => Promise<void>;
};

export const AuthContext = createContext<AuthContextValue>({
  isAuth: false,
  logout: async () => {},
  signUp: async () => {},
  login: async () => {},
  updateUser: async () => {},
  user: null,
  userData: null,
  authModal: null,
  setAuthModal: () => {},
  setNoCreditsModal: () => {},
  credits: {
    creditsAmount: 10,
    creditsMax: 10
  },
  getCredits: async () => {},
  checkCreditBalanceAndShowError: () => {},
  verify: async (token: string) => {}
});

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<null | IUser>(null);
  const [userData, setUserData] = useState<null | IUserData>(null);
  const [credits, setCredits] = useState<null | ICredits>(null);
  const [isAuth, setIsAuth] = useState(!!localStorage.getItem('token'));
  const [authModal, setAuthModal] = useState<AuthModalOpenT>(null);
  const [noCreditsModal, setNoCreditsModal] = useState<boolean>(false);
  const { srcDoc, isLoading, login: memberstackLogin } = useMemberstackLogin();
  const { showError, showSuccessMessage, showMessage } = useNotificationStore();

  const getUser = async (ignoreError: boolean = false) => {
    return userService
      .me()
      .then(async ({ data }) => {
        if (!data.first_name || !data.company_name || !data.last_name) {
          await window.MemberStack.onReady.then(() => {
            const metadata = JSON.parse(
              localStorage.getItem('memberstack') || '""'
            );
            if (metadata && !data.first_name)
              data.first_name = metadata.information.name;
            data.company_name = metadata.information.company;
          });
        }
        await getCredits();
        setUser(userToCamelCase(data));
        setIsAuth(true);
        const userData = await userService.meData().then((res) => res.data[0]);
        setUserData(userData);
        return userToCamelCase(data) as IUser;
      })
      .catch(() => {
        setAuthTokenToAxios();
        setUser(null);
        setCredits(null);
        setIsAuth(false);
      });
  };

  const checkCreditBalanceAndShowError = () => {
    if (credits && credits.creditsAmount < 0.1) {
      showError(
        'Your credit balance is insufficient. Please add credits or upgrade your plan'
      );
      setNoCreditsModal(true);
    }
  };

  const getCredits = async () => {
    const credits = await billingService.getCredits();
    setCredits(credits);
    checkCreditBalanceAndShowError();
  };

  const logout = async () => {
    console.log('logout');
    await authService.logout();
    setIsAuth(false);
    setUser(null);
    setCredits(null);
    setAuthTokenToAxios();
    localStorage.removeItem('token');
    showSuccessMessage(`You have been successfully logged out. See you soon!`);
    setTimeout(() => {
      window.MemberStack.logout();
    }, 1000);
  };

  const requestVerify = async (email: string) => {
    setTimeout(() => {
      showMessage(`Verification link sent to your email. ${email}`);
    }, 800);
    await authService.requestVerify(email);
  };

  const login = async (
    values: IUserLoginReq,
    sendVerify: boolean = true,
    firstLogin = false
  ) => {
    const { access_token: token } = await authService
      .login(values)
      .catch(async () => {
        const token = await memberstackLogin(values);
        await authService.registerFromMemberStack({
          memberstack_jwt: token,
          ...values
        });
        return await authService.login(values);
      })
      .then(async (token) => {
        await memberstackLogin(values).catch(async () => {
          await authService.registerMemberStack(values);
          return await memberstackLogin(values);
        });
        return token;
      });
    localStorage.setItem('token', token);
    setAuthTokenToAxios(token);
    const user = await getUser();
    showSuccessMessage(
      `Welcome${firstLogin ? '' : ' back'}, ${user?.firstName}!`
    );
    logEvent(firebaseAnalytics, 'login_chat');
    if (user && !user.isVerified && sendVerify) await requestVerify(user.email);
  };
  const signUp = async (values: Omit<IUserRegisterReq, 'external_id'>) => {
    if (await authService.checkIfUserExist(values.email)) {
      throw new Error('This email is already used');
    } else {
      const externalId = await authService
        .registerMemberStack(values)
        .catch(() => {
          throw new Error('This email is already used');
        });
      const user = await authService.register({
        ...values,
        external_id: externalId
      });
      await login(
        {
          email: values.email,
          password: values.password
        },
        false,
        true
      );
      if (user) {
        logEvent(firebaseAnalytics, 'signup_chat');
        if (!user.isVerified) await requestVerify(user.email);
      } else {
        await logout();
      }
    }
  };

  const updateUser = async (metadata: IUserMetadata) => {
    await userService.update(metadata).then(() => {
      setUser((user) => ({ ...user, ...metadata } as IUser));
    });
  };

  useEffect(() => {
    const jwtLogin = async () => {
      const jwt = localStorage.getItem('token');
      if (jwt) {
        setAuthTokenToAxios(jwt);
        await getUser(true);
      }
    };
    jwtLogin();
  }, []);

  const verify = async (token: string) => {
    const { data, status } = await authService.verify(token);
    if (status === 200) {
      showSuccessMessage(`${data.email} verified`);
      logEvent(firebaseAnalytics, 'verify_email');
    }
    if (isAuth && user) {
      setUser(userToCamelCase(data));
    }
  };

  useEffect(() => {
    if (isLoading) {
      const memberStackToken = getMemberstackJWT();
      const jwt = localStorage.getItem('token');

      if (user) {
        if ((!memberStackToken && jwt) || (memberStackToken && !jwt)) {
          logout();
        }
      } else {
        localStorage.removeItem('jwt');
        removeMemberstackJWT();
      }
    }
  }, [isLoading, user]);

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        user,
        userData,
        setAuthModal,
        setNoCreditsModal,
        authModal,
        logout,
        login,
        signUp,
        updateUser,
        checkCreditBalanceAndShowError,
        credits: credits || {
          creditsMax: 10,
          creditsAmount: 10
        },
        getCredits,
        verify
      }}
    >
      {!user && isAuth ? (
        <div className="flex items-center justify-center text-center mt-52">
          <Loader size={50} />
        </div>
      ) : (
        children
      )}
      {<iframe className="hidden" srcDoc={srcDoc} />}
      {
        <NoConversations
          onClose={() => setNoCreditsModal(false)}
          open={noCreditsModal}
        />
      }
    </AuthContext.Provider>
  );
};

export default memo(AuthProvider);
