import { useRef, useState } from 'react';

const VOICE_MIN_DECIBELS = -45;
const DELAY_BETWEEN_DIALOGS = 2000;
const DIALOG_MAX_LENGTH = 60 * 1000;

export const useSpeak = (doWhateverWithAudio: (audio: Blob) => void) => {
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const isRecordingRef = useRef(isRecording);
  const onChangeIsRecording = (value: boolean) => {
    setIsRecording(value);
    isRecordingRef.current = value;
  };

  const startRecording = () => {
    onChangeIsRecording(true);
    record();
  };

  const stopRecording = () => {
    onChangeIsRecording(false);
    if (mediaRecorder.current !== null) mediaRecorder.current.stop();
  };

  const record = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.start();

      const audioChunks: Blob[] = [];
      mediaRecorder.current.addEventListener('dataavailable', (event) => {
        audioChunks.push(event.data);
      });

      const audioContext = new AudioContext();
      const audioStreamSource = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      analyser.minDecibels = VOICE_MIN_DECIBELS;
      audioStreamSource.connect(analyser);
      const bufferLength = analyser.frequencyBinCount;
      const domainData = new Uint8Array(bufferLength);

      // loop:
      const time = new Date();
      const startTime: number = time.getTime();
      let lastDetectedTime = time.getTime();
      let anySoundDetected = false;
      const detectSound = () => {
        // recording stopped by user:
        if (!isRecordingRef.current || !mediaRecorder.current) return;

        const currentTime = new Date().getTime();

        // time out:
        if (currentTime > startTime + DIALOG_MAX_LENGTH) {
          mediaRecorder.current.stop();
          return;
        }

        // a dialog detected:
        if (
          anySoundDetected &&
          currentTime > lastDetectedTime + DELAY_BETWEEN_DIALOGS
        ) {
          mediaRecorder.current.stop();
          return;
        }

        // check for detection:
        analyser.getByteFrequencyData(domainData);
        for (let i = 0; i < bufferLength; i++)
          if (domainData[i] > 0) {
            anySoundDetected = true;
            lastDetectedTime = new Date().getTime();
          }

        // continue the loop:
        window.requestAnimationFrame(detectSound);
      };
      window.requestAnimationFrame(detectSound);

      mediaRecorder.current.addEventListener('stop', () => {
        // stop all the tracks:
        stream.getTracks().forEach((track) => track.stop());
        if (!anySoundDetected) return;

        // send to server:
        const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
        doWhateverWithAudio(audioBlob);
        onChangeIsRecording(false);
        mediaRecorder.current = null;
      });
    });
  };
  return {
    isRecording,
    startRecording,
    stopRecording
  };
};
