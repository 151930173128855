import type { FC } from 'react';
import { Tab } from './TabsGroup';

interface Props<T extends Tab = Tab> {
  tabs: T[] | readonly T[];
  activeTab: T['name'];
  toggleTab: (value: any) => void;
  className: string;
}

const MobileTabsGroup: FC<Props> = ({
  tabs,
  activeTab,
  toggleTab,
  className
}) => {
  return (
    <div className={`w-full ${className}`}>
      {tabs.map((t) => (
        <div
          key={t.name}
          onClick={() => !t.disabled && toggleTab(t.name)}
          className={`flex items-center h-12 justify-center text-sm shrink-0 font-semibold  ${
            t.disabled ? 'opacity-50 cursor-default' : 'cursor-pointer'
          } ${
            activeTab === t.name
              ? 'pb-1 border-b-2 border-b-[#A732DC] text-[#A732DC] sm:cursor-default'
              : 'pb-[0.3125rem] border-b border-neutral-300 text-neutral-500 dark:text-neutral-300 dark:border-neutral-700'
          }`}
        >
          {t.title}
        </div>
      ))}
    </div>
  );
};

export default MobileTabsGroup;
