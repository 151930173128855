import type { FC, ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

const OutlinedButton: FC<
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > & { borderSecondary?: boolean; textSecondary?: boolean }
> = ({ className = '', borderSecondary, textSecondary, ...props }) => {
  return (
    <button
      className={`border ${
        borderSecondary
          ? 'border-secondary'
          : 'border-neutral-200 dark:border-neutral-700'
      } ${
        textSecondary
          ? 'text-secondary'
          : 'text-neutral-600 dark:text-neutral-300'
      } flex ${
        className.includes('justify-') ? '' : 'justify-center'
      } items-center text-sm disabled:opacity-50 rounded-lg ${className}`}
      {...props}
    />
  );
};

export default OutlinedButton;
