import { FC, useState } from 'react';
import FullscreenView from './FullscreenView';
import ChatbotView from './ChatbotView';
import ChatbotSwitch from './ChatbotSwitch';
import { IWebRTCProps } from '../../types/types';

const ChatWrapper: FC<IWebRTCProps> = ({ isWebRTC }) => {
  const [isChatbot, setIsChatbot] = useState(false);

  return (
    <div className="w-full sm:bg-neutral-50 dark:bg-dark-primary xl:py-4 xl:px-10 flex justify-start flex-col xl:gap-2 h-screen pt-14 xl:mt-0">
      <ChatbotSwitch
        onSwitch={() => setIsChatbot(!isChatbot)}
        isChatbot={isChatbot}
      />
      {isChatbot ? (
        <ChatbotView isWebRTC={isWebRTC} />
      ) : (
        <FullscreenView isWebRTC={isWebRTC} />
      )}
    </div>
  );
};

export default ChatWrapper;
