import type { FC, PropsWithChildren } from 'react';
import { useGuide } from '../hooks/useGuide';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import MobileNavBar from './MobileNavBar/MobileNavBar';
import NavBar from './NavBar/NavBar';
import SideBar from './SideBar/SideBar';
import { ToastContainer } from 'react-toastify';
import { HiOutlineX } from 'react-icons/hi';
import { IWebRTCProps } from '../types/types';

const Layout: FC<PropsWithChildren<IWebRTCProps>> = ({
  children,
  isWebRTC
}) => {
  const { stepIndex, isMobile } = useGuide();
  const [isNavBarOpened, setIsNavBarOpened] = useState<boolean>(
    window.innerWidth >= 1536
  );
  const [isDevSectionOpen, setIsDevSectionOpen] = useState(false);
  const [isSideBarOpened, setIsSideBarOpened] = useState<boolean>(false);

  const isSMWidth = useRef(window.innerWidth >= 640);
  const is2XLWidth = useRef(window.innerWidth >= 1536);

  const handleBarClose = () => {
    setIsNavBarOpened(false);
    setIsDevSectionOpen(false);
    setIsSideBarOpened(false);
  };

  const handleOpenNavBar = () => {
    setIsNavBarOpened(true);
    if (!isSMWidth.current) setIsDevSectionOpen(false);
    if (!is2XLWidth.current) setIsSideBarOpened(false);
  };

  const handleOpenSideBar = () => {
    setIsSideBarOpened(true);
    if (!is2XLWidth.current) {
      setIsNavBarOpened(false);
    }
    setIsDevSectionOpen(false);
  };

  const handleCloseNavBar = () => {
    setIsNavBarOpened(false);
    setIsDevSectionOpen(false);
    if (!is2XLWidth.current) setIsNavBarOpened(false);
  };

  const handleCloseSideBar = () => {
    setIsSideBarOpened(false);
    if (!is2XLWidth.current) {
      setIsNavBarOpened(false);
      setIsDevSectionOpen(false);
    }
  };

  const handleOpenDevSection = () => {
    setIsDevSectionOpen(true);
    setIsNavBarOpened(isSMWidth.current);
    setIsSideBarOpened(false);
  };
  const handleCloseDevSection = () => {
    setIsDevSectionOpen(false);
    if (!is2XLWidth.current) setIsSideBarOpened(false);
  };
  useEffect(() => {
    const onResize = () => {
      isSMWidth.current = window.innerWidth >= 640;
      is2XLWidth.current = window.innerWidth >= 1536;
      if (
        !is2XLWidth.current &&
        (isNavBarOpened || isDevSectionOpen) &&
        isSideBarOpened
      ) {
        handleBarClose();
      } else {
        if (isDevSectionOpen && is2XLWidth.current && !isNavBarOpened) {
          setIsNavBarOpened(true);
          return;
        }
        if (isDevSectionOpen && !isSMWidth.current && isNavBarOpened) {
          setIsNavBarOpened(false);
        }
        if (isDevSectionOpen && isSMWidth.current && !isNavBarOpened) {
          setIsNavBarOpened(true);
        }
      }
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isNavBarOpened, isSideBarOpened, isDevSectionOpen]);
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://assets.ziggeo.com/v2-stable/ziggeo.css"
        />
        <script src="https://assets.ziggeo.com/v2-stable/ziggeo.js"></script>
      </Helmet>
      <main className="lg:w-screen lg:max-h-max lg:min-h-screen dark lg:flex block relative overflow-x-hidden font-inter">
        <MobileNavBar
          onOpenNavbar={handleOpenNavBar}
          onOpenSidebar={handleOpenSideBar}
        />
        <NavBar
          devSectionOpened={isDevSectionOpen}
          onOpenDevSection={handleOpenDevSection}
          onCloseDevSection={handleCloseDevSection}
          onClose={handleCloseNavBar}
          navbarOpened={isNavBarOpened}
          onOpenNavbar={handleOpenNavBar}
        />
        {children}

        <SideBar
          isWebRTC={isWebRTC}
          onClose={handleCloseSideBar}
          sidebarOpened={
            !!(
              isSideBarOpened ||
              (stepIndex &&
                stepIndex >= 2 + +isMobile &&
                stepIndex <= 5 + +isMobile)
            )
          }
          onOpenSidebar={handleOpenSideBar}
        />
      </main>
      <ToastContainer
        closeButton={({ closeToast }) => (
          <button type="button" onClick={closeToast}>
            <HiOutlineX
              className="h-5 w-5 text-gray-200 hover:text-gray-100"
              aria-hidden="true"
            />
          </button>
        )}
        hideProgressBar
        theme="dark"
      />
      {(isSideBarOpened ||
        isNavBarOpened ||
        isDevSectionOpen ||
        stepIndex !== null) && (
        <div
          className={`w-screen top-0 left-0 h-screen bg-[#000000] opacity-70 z-10 fixed ${
            stepIndex === null ? 'xl:hidden' : ''
          }`}
          onClick={handleBarClose}
        />
      )}
    </>
  );
};

export default Layout;
