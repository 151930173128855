import { useState } from 'react';
import { getMemberstackJWT } from '../utils/getMemberstackJWT';

const getSrcDoc = (email: string, password: string) => {
  return `
  <script>
      const fn = window.fetch
      window.fetch = function() {
          if(arguments[0].includes('memberstack.io/member/login'))
              return fn(...arguments).catch((res) => {
                  parent.window.dispatchEvent(new Event('login_failed'))
                  return res
              }).then((res) => {
                  parent.window.dispatchEvent(new Event('login_success'))
                  return res
              })
        return fn(...arguments)
      }
     
      window.onload = () => {
        if('${email}' && '${password}'){
          if(!window.MemberStack){
            document.getElementById('memberstack-script').onload = () => {
              document.getElementById('submit')?.click()
            }
          } else {
             document.getElementById('submit')?.click()
          }
        }
      }
  </script>
  <script id="memberstack-script" src="https://api.memberstack.io/static/memberstack.js?custom"
          data-memberstack-id="706d8c8f22088da1c3071861be8e9fef"></script>
      <form
        data-ms-form="login"
        ms-internal-login="true"
        name="email-form"
        data-name="Email Form"
        data-hs-cf-bound="true"
        autocomplete="off"
      >
        <input
          id="email"
          name="email"
          type="email"
          autocomplete="off"
          required
          data-name="email"
          data-ms-member="email"
          ms-lang="profile_email"
          value="${email}"
        />
        <input
          id="password"
          name="Password-2"
          type="password"
          autocomplete="off"
          data-name="Password 2"
          data-ms-member="password"
          ms-lang="signup_password"
          required
          value="${password}"
        />
        <button
          id="submit"
          type="submit"
          ms-lang="login_button"
        >
          Sign in
        </button>
      </form>
`;
};

export const useMemberstackLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [srcDoc, setSrcDoc] = useState<string | undefined>(getSrcDoc('', ''));

  const login = async ({
    email,
    password
  }: {
    email: string;
    password: string;
  }): Promise<string> => {
    setIsLoading(true);
    setSrcDoc(getSrcDoc(email, password));

    const token = await new Promise<string>((resolve, reject) => {
      window.addEventListener('login_success', () => {
        // @ts-ignore
        window.MemberStack.reload();
        setTimeout(() => {
          resolve(
            // @ts-ignore
            getMemberstackJWT()
          );
        }, 1000);
      });

      window.addEventListener('login_failed', () =>
        reject(new Error('The email or password is incorrect'))
      );
    });

    setSrcDoc(getSrcDoc('', ''));
    setIsLoading(false);
    return token;
  };

  return {
    isLoading,
    login,
    srcDoc
  };
};
