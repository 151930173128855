import axios from 'axios';
import httpInviteService from './http/httpInvite.service';
import { IRegisterUserInViralLoopPayload } from '../stores/useInviteStore';

// invite is campaign tag name, could be "invite" or "prelaunch" for different campaigns
const viralLoopsEndpoint = '/viral-loops/invite';

const inviteService = {
  getLink: async (email: string, name?: string) => {
    const slug = '/invite-link';
    const encodedEmail = encodeURIComponent(email);
    const query = name
      ? `?email=${encodedEmail}&name=${name}`
      : `?email=${encodedEmail}`;
    const redirectTo = window.location.href.split('?')[0];
    const url =
      viralLoopsEndpoint + slug + query + `&redirect_to=${redirectTo}`;
    const { data } = await httpInviteService.get(url);
    return data;
  },
  getRank: async (email: string) => {
    const slug = '/rank';
    const encodedEmail = encodeURIComponent(email);
    const query = `?email=${encodedEmail}`;
    const url = viralLoopsEndpoint + slug + query;
    const { data } = await httpInviteService.get(url);
    return data;
  },
  registerUser: async ({
    email,
    firstName,
    refCode,
    refSource,
    location
  }: IRegisterUserInViralLoopPayload) => {
    const slug = '/register';
    const payload = {
      first_name: firstName,
      email,
      ref_source: refSource,
      referrer_code: refCode,
      acquired_from: location
    };
    const url = viralLoopsEndpoint + slug;
    const { data } = await httpInviteService.post(url, payload);
    return data;
  }
};

export default inviteService;
