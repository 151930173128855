import { create } from 'zustand';
import { toast } from 'react-toastify';
import {
  HiExclamationCircle,
  HiOutlineCheckCircle,
  HiXCircle
} from 'react-icons/hi';
import React from 'react';

interface Actions {
  showMessage: (text: string) => void;
  showSuccessMessage: (text: string) => void;
  showError: (text: string) => void;
  showWarn: (text: string) => void;
}

export const useNotificationStore = create<Actions>(() => ({
  showMessage: (text: string) => {
    toast(text);
  },

  showSuccessMessage: (text: string) => {
    toast.success(text, {
      icon: (
        <HiOutlineCheckCircle
          className="h-6 w-6 text-green-400"
          aria-hidden="true"
        />
      )
    });
  },

  showError: (text: string) => {
    toast.error(text, {
      icon: <HiXCircle className="h-6 w-6 text-red-400" aria-hidden="true" />
    });
  },
  showWarn: (text: string) => {
    toast.warn(text, {
      icon: (
        <HiExclamationCircle
          className="h-6 w-6 text-yellow-400"
          aria-hidden="true"
        />
      )
    });
  }
}));
