import type { FC } from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import { GrClose } from 'react-icons/gr';
import Input from '../shared/Input';
import inviteService from '../../services/invite.service';
import { useAuth } from '../../hooks/useAuth';
import useInviteStore from '../../stores/useInviteStore';
import { BsCheck, BsClipboard } from 'react-icons/bs';
import { FiCheck, FiCopy } from 'react-icons/fi';
import Loader from '../Loader';
import ShareLinkSection from '../Invite/ShareLinkSection';

interface Props {
  onClose: () => void;
  open: boolean;
}

const getEarnedCredits = (referralCount: number) => {
  // TODO: CLARIFY MAX CREDITS
  const max = 1000;
  const earned = referralCount * 25;
  return earned > max ? max : earned;
};

const InviteFriends: FC<Props> = ({ onClose, open }) => {
  const { user } = useAuth();
  const { link, rank, referralCountTotal, init, loading } = useInviteStore();

  useEffect(() => {
    if (user && open && !link) {
      init(user.email);
    }
  }, [user, open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000] bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto dark">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all w-[25rem] dark:bg-neutral-800">
                {loading ? (
                  <div className="flex justify-center py-10">
                    <Loader size={50} />
                  </div>
                ) : (
                  <>
                    <div>
                      <div className="w-full">
                        <div className="flex justify-center mb-4">
                          <div className=" bg-primary-100 rounded-full p-4">
                            <img
                              src="/friends.svg"
                              className="w-5 h-5"
                              alt="friends"
                            />
                          </div>
                        </div>
                        <Dialog.Title
                          as="h3"
                          className="text-lg text-center font-semibold leading-7 text-gray-900 mb-1 dark:text-white"
                        >
                          Invite friends
                        </Dialog.Title>
                        <div className="px-4 justify-center">
                          <p className="text-sm text-gray-600 text-center dark:text-gray-200">
                            Get 25 free chat credits for each friend invited
                            that sign up
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-5 text-sm text-neutral-700 pb-5 border-b border-gray-200">
                      Share link
                      <div className="mt-1.5 flex gap-x-3.5 items-center">
                        <Input
                          className="w-full py-2.5 px-3.5 text-neutral-900 text-base truncate"
                          readOnly
                          value={link}
                        />
                        <button
                          onClick={async () => {
                            setIsCopied(true);
                            await navigator.clipboard.writeText(link);
                            setTimeout(() => setIsCopied(false), 1500);
                          }}
                        >
                          {isCopied ? (
                            <FiCheck className="w-5 h-5 text-green-600" />
                          ) : (
                            <FiCopy className="w-5 h-5" />
                          )}
                        </button>
                      </div>
                    </div> */}
                    <ShareLinkSection link={link} />
                    <div>
                      <p className="mt-5 text-sm text-gray-600 dark:text-gray-200">
                        Here you can see the rewards you won when your friends
                        signed-up.
                        {/* TODO: CLARIFY COUNT */}
                        {/* Get up to X credits. */}
                      </p>
                      <div className="flex justify-between gap-4 text-neutral-700 mt-5 dark:text-neutral-100">
                        <div className="aspect-square bg-neutral-100 text-center py-2 px-4 rounded-lg flex flex-col justify-center gap-2 dark:bg-neutral-900">
                          <p className="text-sm font-medium dark:text-white">
                            Signed up
                          </p>
                          <p className="font-semibold text-3xl">
                            {referralCountTotal}
                          </p>
                        </div>
                        <div className="flex-1 bg-neutral-100 text-center py-2 px-4 rounded-lg flex flex-col justify-center gap-2 dark:bg-neutral-900">
                          <p className="text-sm font-medium dark:text-white">
                            Credits earned
                          </p>
                          <p className="font-semibold text-3xl">
                            {getEarnedCredits(referralCountTotal)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InviteFriends;
