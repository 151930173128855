export function capitalize(str: string) {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export function getParams(url: string) {
  const params = {};
  url.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    // @ts-ignore
    function (_, key: string, value: string) {
      // @ts-ignore
      params[key] = value;
    }
  );

  return params;
}

export function getVideoDimensions(url: string) {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.addEventListener(
      'loadedmetadata',
      function () {
        const height = this.videoHeight;
        const width = this.videoWidth;

        resolve({ width, height });
      },
      false
    );

    video.src = url;
  });
}

export const isObject = (value: string) =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const formatTime = (slideTime: number) => {
  if (typeof +slideTime !== 'number' || slideTime === null || isNaN(slideTime))
    return '00:00:00';
  const leadingZero = (n: number) => (n >= 10 ? `${n}` : `0${n}`);
  // @ts-ignore
  const minutes = parseInt(slideTime / 60);
  const seconds = slideTime % 60;
  return `00:${leadingZero(minutes)}:${leadingZero(seconds)}`;
};

export const removeItem = <T>(arr: Array<T>, value: T): Array<T> => {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};
