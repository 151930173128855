import httpDotenvService from './http/httpDotenv.service';

const avatarEndpoint = '/avatars';

const avatarService = {
  fetchAll: async () => {
    const { data } = await httpDotenvService.get(avatarEndpoint, {
      'X-Api-Key': import.meta.env.VITE_UNAUTHORIZED_API_KEY
    });
    return data;
  },
  createCustomTalkingPhoto: async (payload: any) => {
    const data = await httpDotenvService.post(avatarEndpoint, payload);
    return data;
  },
  deleteCustomTalkingPhoto: async (id: string) => {
    const data = httpDotenvService.delete(avatarEndpoint + `/${id}`);
    return data;
  }
};

export default avatarService;
