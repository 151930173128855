import { useState } from 'react';
import filesService from '../services/files.service';
import { toast } from 'react-toastify';
import { useNotificationStore } from '../../../../../stores/useNotificationStore';

interface IUploadFile {
  isLoading: boolean;
  error: string | null;
  uploadFile: (file: File) => Promise<any>;
  uploadFileWithProgress: (
    file: File,
    onProgressChange: (progress: number) => void
  ) => Promise<any>;
  urlToFile: (url: string, filename: string, mimeType: string) => Promise<File>;
  detectFace: (file: File) => Promise<any>;
}

export const useUploadFile = (): IUploadFile => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const showError = useNotificationStore((state) => state.showError);

  const urlToFile = async (
    url: string,
    filename: string,
    mimeType: string
  ): Promise<File> => {
    const data = await fetch(url);
    const buffer = await data.arrayBuffer();
    return new File([buffer], filename, { type: mimeType });
  };

  const uploadFile = async (file: File): Promise<any> => {
    if (!file) {
      setError('No file chosen');
      return;
    }
    setError(null);
    setIsLoading(true);

    try {
      const data = await filesService.upload(file);
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      showError(`Failed to upload file: ${(error as Error).message}`);
      setError((error as Error).message);
    }
  };

  const uploadFileWithProgress = async (
    file: File,
    onProgressChange: (progress: number) => void
  ): Promise<any> => {
    if (!file) {
      setError('No file chosen');
      return;
    }
    setError(null);
    setIsLoading(true);

    try {
      const data = await filesService.uploadWithProgress(file, (e) =>
        onProgressChange(Math.round((100 * e.loaded) / e.total))
      );
      setIsLoading(false);
      return data;
    } catch (error) {
      setIsLoading(false);
      showError(
        `We couldn't upload your file. Please try again later. Error: ${
          (error as Error).message
        }`
      );
      setError((error as Error).message);
    }
  };

  const detectFace = async (file: File): Promise<any> => {
    const formData = new FormData();
    formData.append('Photo', file);
    try {
      const data = await filesService.detectFace(formData);
      if (
        Object.prototype.hasOwnProperty.call(data, 'errorCode') &&
        data.errorCode !== null &&
        data.errorCode !== '' &&
        typeof data.errorCode !== 'undefined'
      ) {
        if (data?.status?.http_code === 400) {
          showError(
            'An error occurred while detecting the face on your photo, check if the photo is correct.'
          );
        } else if (data?.status?.http_code === 500) {
          showError(
            'Sorry, there was an error detecting the face due to a server issue. Please try again later.'
          );
        } else {
          showError(
            'An unexpected error occurred while detecting the face on your photo. Please try again later.'
          );
        }
        return false;
      } else {
        return data;
      }
    } catch (error) {
      showError(
        `There was a problem when we tried to detect the face on your photo. Error: "${
          (error as Error).message
        }"`
      );
      return false;
    }
  };

  return {
    isLoading,
    error,
    uploadFile,
    uploadFileWithProgress,
    urlToFile,
    detectFace
  };
};
