import { create } from 'zustand';
import { voiceService } from '../services/voice.service';
import { GenderOptions, IVoice, Language } from '../types/types';
import useAvatarStore from './useAvatarStore';

type Filter = {
  language?: string;
  provider?: string;
};

interface VoiceStore {
  selectedVoice: IVoice | null;
  voices: IVoice[] | null;
  fetchVoices: (isWebRTC: boolean) => void;
  chooseVoice: (voice: IVoice) => void;
  providers: string[] | null;
  languages: Language[] | null;
  loading: boolean;
  setFilters: (filter: Filter) => void;
  filters: Filter | null;
  filteredVoices: IVoice[] | null;
  filterVoices: () => void;
  genders: GenderOptions[];
  setGenders: (genders: GenderOptions[]) => void;
  changeGender: (gender: GenderOptions) => void;
  voicesError: string | null;
}

export const getDefaultVoiceId = (
  gender: 'Male' | 'Female' | 'Other' = 'Male'
) =>
  gender === 'Female' ? 'en-US-JennyMultilingualNeural' : 'en-GB-ThomasNeural';

const useVoiceStore = create<VoiceStore>((set, get) => ({
  voices: null,
  fetchVoices: async (isWebRTC: boolean) => {
    try {
      set((state) => ({ loading: true }));
      const [voices, providers, languages] = await voiceService.getVoices(
        isWebRTC
      );
      // set default filtering to prevent animation lag
      if (providers.includes('ElevenLabs')) {
        const usEnglish = languages.find(
          (l) => l.language === 'English (United States)'
        );
        set((state) => ({
          filters: { provider: 'ElevenLabs', language: usEnglish?.language }
        }));
      }
      set((state) => {
        return {
          voices,
          providers,
          languages,
          loading: false
        };
      });
      if (isWebRTC) {
        const gender =
          useAvatarStore.getState().activeAvatar?.gender || 'Female';
        const voice = voices.find(
          (v) => v.providerVoiceId === getDefaultVoiceId(gender)
        );
        if (voice) get().chooseVoice(voice);
      } else {
        const voice = voices.find(
          (v) => v.providerVoiceId === '2EiwWnXFnvU5JabPnv8n'
        );
        if (voice) get().chooseVoice(voice);
      }
      get().filterVoices();
    } catch (err) {
      console.error(err);
    }
  },
  chooseVoice: (voice) => {
    set((state) => ({ selectedVoice: voice }));
  },
  selectedVoice: null,
  providers: null,
  languages: null,
  loading: false,
  filters: {
    language: 'All languages',
    provider: 'All providers'
  },
  setFilters: (filter) => {
    set((state) => {
      return { filters: filter };
    });
    get().filterVoices();
  },
  filteredVoices: null,
  genders: [],
  setGenders: (genders: GenderOptions[]) => {
    set(() => ({ genders }));
    get().filterVoices();
  },
  changeGender: (value: GenderOptions) => {
    set((state) => {
      let newGenders: GenderOptions[];
      if (state.genders.includes(value)) {
        newGenders = state.genders.filter((g) => g !== value);
      } else {
        newGenders = [...state.genders, value];
      }
      return {
        genders: newGenders
      };
    });
    get().filterVoices();
  },
  filterVoices() {
    const voices = get().voices;
    const filter = get().filters;
    const genders = get().genders;
    if (!voices) return;
    const filtered = voices
      ?.filter((v) =>
        filter?.provider === 'All providers'
          ? true
          : filter?.provider === v.provider
      )
      .filter((v) =>
        filter?.language === 'All languages'
          ? true
          : filter?.language === v.language
      )
      .filter((v) => (genders?.length ? genders.includes(v.gender) : true));
    set((state) => ({ filteredVoices: filtered }));
  },
  voicesError: null
}));

export default useVoiceStore;
