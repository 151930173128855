import { prices } from '../components/Forms/IntegrateForm/IntegrateForm';
import { JsonValue } from 'react-use-websocket/src/lib/types';
import { JsonObject } from 'react-use-websocket/dist/lib/types';

export enum Role {
  USER = 'user',
  ASSISTANT = 'assistant'
}

export type IMessage = {
  role: Role;
  content: string;
  // url?: string;
  urls?: string[];
  chatId?: string;
  id: string;
  isLoading?: boolean;
};

export interface GuideContextI {
  step: GuideStepI | null;
  stepIndex: number | null;
  handleStartGuide: () => void;
  handleEndGuide: () => void;
  handleNextStep: () => void;
  stepsLength: number;
  isMobile: boolean;
}

export enum GuideSteps {
  AVATARS = 'Avatars',
  VOICES = 'Voices',
  STYLE = 'Style',
  ENGINE = 'Engine',
  TYPE_OR_SPEAK = 'Type or speak',
  OPEN_TOOLS_PANEL = 'Open the tools panel'
}

export type PositionT =
  | `bottom-right`
  | `bottom-center`
  | 'top-right'
  | 'right-top'
  | 'top-center';

export interface GuideStepI {
  position: PositionT;
  hash?: string;
  name: GuideSteps;
  text: string;
}

export interface IUserMetadata {
  lastName: string;
  firstName: string;
  companyName: string;
}

export interface ICredits {
  creditsAmount: number;
  creditsMax: number;
}

export interface IUser extends IUserMetadata {
  id: string;
  email: string;
  isVerified: boolean;
}

export interface IUserData {
  id: string;
  country: null | string;
  name: string;
  email: string;
  organizationName: string | null;
  apiKey: string;
}

export interface IDefaultMessage {
  _id: string;
  question: string;
  answer: string;
  hidden: boolean;
  priority: number;
  tags: string[];
}

export interface IMessageResponse {
  userMessageId: string;
  message: IMessage;
  // url: string;
  urls: string[];
}

export interface ISocketResponse extends JsonObject {
  userMessageId: string;
  message: IMessage;
}

export interface IWebRTCProps {
  isWebRTC: boolean;
}

export type GenderOptions = 'male' | 'female' | 'other';

export type AvatarOptions = 'talkingPhoto' | 'avatar' | 'custom';

export interface IAvatar {
  age?: number;
  avatarStatus: string | null;
  customAvatar: boolean;
  fullTrial: boolean | null;
  gender: 'Male' | 'Female' | 'Other'; // probably could be changed to GenderOptions when will not be capitalized
  id: string;
  inputImageUrl?: string;
  memberstackId?: string;
  menuCircleImageUrl: string | null;
  menuImageUrl: string | null;
  name: string;
  organizationId?: string | null;
  publicAvatar: boolean;
  rawImageUrl?: string | null;
  talkingPhoto: boolean;
  talkingPhotoUpperBody?: boolean;
  userId?: string;
  idleLoopVideoUrl?: string;
  hasIdleLoop?: boolean;
}

export type Country = {
  name: string;
  emoji?: string;
  unicode?: string;
  image?: string;
};

export type Language = {
  language: string;
  country: Country | null;
};

export interface IVoice {
  audioSampleUrl: string | null;
  gender: 'male' | 'female';
  id: string;
  isNeural: boolean;
  language: string;
  languageCode: string;
  languageCodes: string[];
  languageCodesJson: string;
  name: string;
  provider: string;
  providerVoiceId: string;
  textToSpeechApiProvider: string;
  voiceId: string;
  maxCharacterLimit: number | string;
  isPremiumVoice: boolean;
}

export interface IIntegrateFormValues {
  integration: string;
  usage: string;
  price: (typeof prices)[number];
}

// export interface IIntegratePayload extends IIntegrateFormValues {
//   user_id
// }

declare global {
  interface Window {
    MemberStack?: any;
    ZiggeoApi?: any;
  }
}

export interface ISocketMessage extends JsonObject {
  chatId: string;
  message: string;
  avatarId: string;
  voiceId: string;
  language: string;
  channelId: string;
}
