import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import SwitchButton from './SwitchButton';

type ChatbotSwitchProps = {
  onSwitch: () => void;
  isChatbot: boolean;
};

const ChatbotSwitch = ({ onSwitch, isChatbot }: ChatbotSwitchProps) => {
  return (
    <div className="flex items-center justify-center self-center xl:self-start md:justify-start bg-neutral-100 border border-neutral-200 rounded-lg w-min p-0.5 gap-1 text-sm font-semibold m-1 xl:m-0 dark:bg-neutral-800 dark:border-neutral-700">
      <SwitchButton
        onSwitch={onSwitch}
        isActive={!isChatbot}
        label="Full screen"
      />
      <SwitchButton
        onSwitch={onSwitch}
        isActive={isChatbot}
        label="Chatbot view"
      />
    </div>
  );
};

export default ChatbotSwitch;
