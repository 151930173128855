import { FC, useEffect, useReducer, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

export const useLoadVideos = () => {
  const [videos, setVideos] = useState<string[]>([]);
  const resolveRef = useRef<(() => void) | null>(null);
  const loadVideos = async (videos: string[]) => {
    setVideos(videos);
    await new Promise<void>((res) => {
      resolveRef.current = res;
    });
    resolveRef.current = null;
    setVideos([]);
  };

  const Videos = () => {
    return (
      <div className="hidden">
        {videos.map((url) => (
          <Video url={url} key={`load-video-${url}`} />
        ))}
      </div>
    );
  };
  useEffect(() => {
    if (videos.length === 0 && resolveRef.current) resolveRef.current();
  }, [videos]);
  const Video: FC<{ url: string }> = ({ url }) => {
    const [refreshValue, refresh] = useReducer((x) => x + 1, 0);
    return (
      <ReactPlayer
        key={`${url}-${refreshValue}-player`}
        url={url}
        onError={() => {
          setTimeout(() => {
            refresh();
          }, 50);
        }}
        onReady={() => {
          setVideos((prev) => prev.filter((video) => video !== url));
        }}
        width="100%"
        height="100%"
        config={{
          file: {
            attributes: {
              preload: 'auto'
            }
          }
        }}
      />
    );
  };

  return {
    loadVideos,
    Videos
  };
};
