import { RefObject, useRef } from 'react';
import {
  GetIceServerConfigCommand,
  KinesisVideoSignalingClient
} from '@aws-sdk/client-kinesis-video-signaling';
import { ChannelProtocol, KinesisVideo } from '@aws-sdk/client-kinesis-video';
import { Role, SignalingClient } from 'amazon-kinesis-video-streams-webrtc';
import { useLocation } from 'react-router';
import { Credentials } from 'amazon-kinesis-video-streams-webrtc/lib/SignalingClient';
import { RTCIceServer } from '../types/webrtc';

export const useKinesis = (
  remoteView: RefObject<HTMLVideoElement>,
  ChannelARN: string,
  config: {
    natTraversalDisabled: boolean;
    forceTURN: false | 'all' | 'relay';
    openDataChannel: boolean;
    useTrickleICE: boolean;
    region: string;
    clientId?: string;
    accessKeyId: string;
    secretAccessKey: string;
    sessionToken?: string;
    endpoint?: string;
  },
  onStart: () => void,
  onEnd: () => void,
  onError: (e: Error) => void,
  onRemoteDataMessage?: (this: RTCDataChannel, ev: MessageEvent) => void
) => {
  const signalingClientRef = useRef<SignalingClient | null>(null);
  const peerConnectionRef = useRef<RTCPeerConnection | null>(null);
  const endpointsByProtocol = useRef<Record<ChannelProtocol, string>>({
    HTTPS: '',
    WSS: '',
    WEBRTC: ''
  });
  const dataChannel = useRef<RTCDataChannel | null>(null);
  const remoteStream = useRef<MediaStream | null>(null);
  const { pathname } = useLocation();
  const isChunk = pathname === '/stream-chunk';

  const credentials: Credentials = {
    accessKeyId: config.accessKeyId,
    secretAccessKey: config.secretAccessKey,
    sessionToken: config.sessionToken
  };

  const getIceServers = async (): Promise<RTCIceServer[]> => {
    const kinesisVideoSignalingChannelsClient = new KinesisVideoSignalingClient(
      {
        region: config.region,
        credentials,
        endpoint: endpointsByProtocol.current.HTTPS
        // correctClockSkew: true
      }
    );

    const getIceServerConfigResponse =
      await kinesisVideoSignalingChannelsClient.send(
        new GetIceServerConfigCommand({
          ChannelARN
        })
      );
    const iceServers = [];
    if (!config.natTraversalDisabled && !config.forceTURN) {
      iceServers.push({
        urls: `stun:stun.kinesisvideo.${config.region}.amazonaws.com:443`
      });
    }
    if (!config.natTraversalDisabled) {
      getIceServerConfigResponse.IceServerList?.forEach((iceServer) =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password
        })
      );
    }

    console.log('[VIEWER] ICE servers: ', iceServers);

    return iceServers;
  };

  const createRTCPeerConnection = async () => {
    const iceServers = await getIceServers();
    peerConnectionRef.current = new RTCPeerConnection({
      iceServers,
      iceTransportPolicy: config.forceTURN || 'all'
    });
    if (config.openDataChannel) {
      dataChannel.current =
        peerConnectionRef.current.createDataChannel('kvsDataChannel');
      peerConnectionRef.current.ondatachannel = (event) => {
        if (onRemoteDataMessage) event.channel.onmessage = onRemoteDataMessage;
      };
    }
  };

  const createSignalClient = () => {
    signalingClientRef.current = new KVSWebRTC.SignalingClient({
      channelARN: ChannelARN,
      channelEndpoint: endpointsByProtocol.current.WSS,
      clientId:
        config.clientId ||
        Math.random().toString(36).substring(2).toUpperCase(),
      role: 'VIEWER' as Role,
      region: config.region,
      credentials,
      systemClockOffset: 0
    });
  };

  const signalClientAddListeners = () => {
    signalingClientRef.current!.on('open', async () => {
      // Create an SDP offer to send to the master
      console.log('[VIEWER] Creating SDP offer');
      await peerConnectionRef.current!.setLocalDescription(
        await peerConnectionRef.current!.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true
        })
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (config.useTrickleICE) {
        console.log('[VIEWER] Sending SDP offer');
        signalingClientRef.current!.sendSdpOffer(
          peerConnectionRef.current!.localDescription!
        );
      }
      console.log('[VIEWER] Generating ICE candidates');
    });

    // @ts-ignore
    signalingClientRef.current!.on('sdpAnswer', async (answer) => {
      // Add the SDP answer to the peer connection
      console.log('[VIEWER] Received SDP answer');
      await peerConnectionRef.current!.setRemoteDescription(answer);
    });

    // @ts-ignore
    signalingClientRef.current!.on('iceCandidate', (candidate) => {
      // Add the ICE candidate received from the MASTER to the peer connection
      console.log('[VIEWER] Received ICE candidate');
      peerConnectionRef.current!.addIceCandidate(candidate);
    });

    signalingClientRef.current!.on('close', () => {
      console.log('[VIEWER] Disconnected from signaling channel');
      // onEnd();
    });

    // @ts-ignore
    signalingClientRef.current!.on('error', (error) => {
      console.error('[VIEWER] Signaling client error: ', error);
    });
  };

  const peerConnectionAddListeners = () => {
    let isAllICEGenerated = false;
    let isOnUnmute = false;
    let isStarted = false;
    const checkIsStarted = () => {
      if (isAllICEGenerated && isOnUnmute && !isStarted) {
        isStarted = true;
        onStart();
      }
      return isAllICEGenerated && isOnUnmute && !isStarted;
    };

    peerConnectionRef.current!.addEventListener(
      'icecandidate',
      ({ candidate }) => {
        if (candidate) {
          console.log('[VIEWER] Generated ICE candidate');

          // When trickle ICE is enabled, send the ICE candidates as they are generated.
          if (config.useTrickleICE) {
            console.log('[VIEWER] Sending ICE candidate');
            signalingClientRef.current!.sendIceCandidate(candidate);
          }
        } else {
          console.log('[VIEWER] All ICE candidates have been generated');
          isAllICEGenerated = true;
          checkIsStarted();

          // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
          if (!config.useTrickleICE) {
            console.log('[VIEWER] Sending SDP offer');
            signalingClientRef.current!.sendSdpOffer(
              peerConnectionRef.current!.localDescription!
            );
          }
        }
      }
    );

    // As remote tracks are received, add them to the remote view
    peerConnectionRef.current!.addEventListener('track', (event) => {
      console.log('[VIEWER] Received remote track');
      if (remoteStream.current) {
        return;
      }
      remoteStream.current = event.streams[0];
      const mediaStream = remoteStream.current?.getVideoTracks()[0];
      // eslint-disable-next-line no-undef
      let onEndedTimeout: NodeJS.Timeout;
      mediaStream.onmute = () => {
        console.log('onmute');
        if (isStarted) {
          if (isChunk) {
            onEndedTimeout = setTimeout(() => {
              onEnd();
            }, 4000);
          } else {
            onEnd();
          }
        }
      };
      mediaStream.onunmute = () => {
        console.log('unmute');
        isOnUnmute = true;
        clearTimeout(onEndedTimeout);
        checkIsStarted();
      };
      remoteView.current!.srcObject = remoteStream.current;
    });
  };

  const startViewer = async () => {
    try {
      // Create KVS client
      const kinesisVideoClient = new KinesisVideo({
        region: config.region,
        credentials,
        endpoint: config.endpoint
      });

      console.log('[VIEWER] Channel ARN: ', ChannelARN);

      // Get signaling channel endpoints
      const getSignalingChannelEndpointResponse =
        await kinesisVideoClient.getSignalingChannelEndpoint({
          ChannelARN,
          SingleMasterChannelEndpointConfiguration: {
            Protocols: ['WSS', 'HTTPS'],
            Role: 'VIEWER'
          }
        });

      endpointsByProtocol.current =
        getSignalingChannelEndpointResponse.ResourceEndpointList!.reduce(
          (endpoints, endpoint) => {
            if (endpoint.Protocol && endpoint.ResourceEndpoint)
              endpoints[endpoint.Protocol as ChannelProtocol] =
                endpoint.ResourceEndpoint;

            return endpoints;
          },
          {} as {
            [T in ChannelProtocol]: string;
          }
        );
      console.log('[VIEWER] Endpoints: ', endpointsByProtocol.current);

      createSignalClient();

      await createRTCPeerConnection();
      console.log('[VIEWER] Connected to signaling service');

      signalClientAddListeners();

      peerConnectionAddListeners();

      console.log('[VIEWER] Starting viewer connection');
      signalingClientRef.current!.open();
      // @ts-ignore
      window.webrtc = {
        peerConnection: peerConnectionRef.current,
        signalingClient: signalingClientRef.current
      };
    } catch (e: unknown) {
      onError(e as Error);
    }
  };

  const stopViewer = async () => {
    console.log('[VIEWER] Stopping viewer connection');
    if (signalingClientRef.current) {
      signalingClientRef.current.close();
      signalingClientRef.current = null;
    }

    if (peerConnectionRef.current) {
      peerConnectionRef.current.close();
      peerConnectionRef.current = null;
    }

    if (remoteStream.current) {
      remoteStream.current.getTracks().forEach((track) => track.stop());
      remoteStream.current = null;
    }

    if (remoteView.current) {
      remoteView.current.srcObject = null;
    }
  };

  const refreshIce = async () => {
    console.log('refreshIce');
    if (remoteStream.current) {
      remoteStream.current.getTracks().forEach((track) => track.stop());
      remoteStream.current = null;
    }
    if (signalingClientRef.current) signalingClientRef.current.close();

    if (remoteView.current) {
      remoteView.current.srcObject = null;
    }

    createSignalClient();
    await createRTCPeerConnection();
    console.log('[VIEWER] Connected to signaling service');

    signalClientAddListeners();
    peerConnectionAddListeners();

    console.log('[VIEWER] Starting viewer connection');
    signalingClientRef.current!.open();
  };

  return {
    startViewer,
    stopViewer,
    refreshIce
  };
};
