import { Dispatch, FC, Fragment, SetStateAction, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import LoginForm from '../Forms/LoginForm/LoginForm';
import RegisterForm from '../Forms/RegisterForm/RegisterForm';

export type AuthModalOpenT = null | 'register' | 'login';

type AuthModalProps = {
  open: null | 'register' | 'login';
  setOpen: Dispatch<SetStateAction<AuthModalOpenT>>;
};

const AuthModal = ({ setOpen, open }: AuthModalProps) => {
  const onClose = () => {
    setOpen(null);
  };

  return (
    <Transition.Root show={!!open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000] bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center md:p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={'div'}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform md:rounded-lg bg-white text-left shadow-xl transition-all md:my-8 md:w-[552px]">
                {open === 'login' && (
                  <LoginForm
                    openRegisterForm={() => setOpen('register')}
                    onClose={onClose}
                  />
                )}
                {open === 'register' && (
                  <RegisterForm
                    openLoginForm={() => setOpen('login')}
                    onClose={onClose}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AuthModal;
