import { AxiosResponse } from 'axios';
import { IUserMetadataReq, IUserRes } from '../types/fetch-types';
import httpService from './http/http.service';
import { IUserData, IUserMetadata } from '../types/types';
import httpDotenvService from './http/httpDotenv.service';

const host = import.meta.env.VITE_YEPIC_AUTH_BACKEND;
const slug = '/users';

export const userService = {
  me: (): Promise<AxiosResponse<IUserRes>> =>
    httpService.get(`${host}${slug}/me`),
  update: async (data: IUserMetadata) => {
    // @ts-ignore
    await window.MemberStack.onReady.then(async ({ updateProfile }) => {
      await updateProfile({
        name: `${data.firstName} ${data.lastName}`,
        company: data.companyName
      });
    });
    return httpService.patch(`${host}${slug}/me`, {
      company_name: data.companyName,
      last_name: data.lastName,
      first_name: data.firstName
    } as IUserMetadataReq);
  },
  meData: (): Promise<AxiosResponse<IUserData[]>> =>
    httpDotenvService.get(`/users`)
};
