import type { FC } from 'react';
import FormPageLayout from '../components/FormPageLayout';
import { useEffect } from 'react';

export const EnterpriseCall: FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
  return (
    <FormPageLayout>
      <div
        className="meetings-iframe-container"
        data-src="https://meetings.hubspot.com/fergie?embed=true"
        data-formz-type="simple"
      />
    </FormPageLayout>
  );
};

export default EnterpriseCall;
