export const getMemberstackJWT = () => {
  const memberstackToken = document.cookie
    .split('; ')
    .find((row) => row.startsWith('__ms'))
    ?.split('=')[1];

  return memberstackToken;
};

export const removeMemberstackJWT = () => {
  document.cookie = '__ms=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
};
