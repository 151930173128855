import { MouseEvent } from 'react';
import { useGuide } from '../../hooks/useGuide';
import Step from '../Step';

type Props = {
  onOpenNavbar: () => void;
  onOpenSidebar: () => void;
};

const MobileNavBar = ({ onOpenNavbar, onOpenSidebar }: Props) => {
  const { isMobile, stepIndex, handleNextStep } = useGuide();
  return (
    <div
      className={`flex items-center absolute py-0 px-7 justify-center shadow-sm bg-white dark:bg-dark-primary w-full xl:hidden ${
        isMobile && stepIndex === 2 ? 'z-20' : 'z-10'
      }`}
    >
      <button
        className='bg-[url("/menuIcon.svg")] w-4 h-3.5 bg-no-repeat bg-cover bg-center mr-auto'
        onClick={() => {
          if (stepIndex === null) onOpenNavbar();
        }}
      />
      <div className="flex items-center p-3 justify-center">
        <img width="43px" height="31px" src="/logo.svg" className="mr-3" />
        <h3 className="uppercase text-black dark:text-white text-7 font-semibold mx-auto">
          Yepic
        </h3>

        <div className="flex h-8 items-center ml-3">
          <img src="/ai1.svg" className="h-2 mr-1" />
          <img src="/ai2.svg" className="mr-0.5" />
          <img src="/ai3.svg" />
        </div>
      </div>
      <div className="relative ml-auto">
        <button
          className='bg-[url("/ap.svg")] w-4 h-4 bg-no-repeat bg-cover bg-center'
          onClick={() => {
            if (isMobile && stepIndex === 2) handleNextStep();
            if (stepIndex === 2 || stepIndex === null) onOpenSidebar();
          }}
        />
        <Step mobileSteps={2} className="-right-1/2 mt-8" />
      </div>
    </div>
  );
};

export default MobileNavBar;
