import React, { Dispatch, SetStateAction, useState } from 'react';
import avatarService from '../../../../../services/avatar.service';
import Loader from '../components/Loader';
import { CustomPhotoDetails } from '../types/types';
import { toast } from 'react-toastify';
import { capitalize } from '../utils/utils';
import { useNotificationStore } from '../../../../../stores/useNotificationStore';

type Step4Props = {
  onSetStep: Dispatch<SetStateAction<number>>;
  uploadedPhoto: string;
  uploadedPhotoDetails: CustomPhotoDetails;
  onCloseModal: () => void;
  showDelayWarning?: boolean;
};

const Step4: React.FC<Step4Props> = ({
  onSetStep,
  uploadedPhoto,
  uploadedPhotoDetails,
  onCloseModal,
  showDelayWarning = false
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showSuccessMessage, showError } = useNotificationStore();
  const handleCreatePhoto = async () => {
    try {
      setIsLoading(true);
      const { status } = await avatarService.createCustomTalkingPhoto(
        uploadedPhotoDetails
      );
      if (status === 200 || status === 201) {
        showSuccessMessage(
          'Your custom talking photo was successfully submitted!'
        );
        onCloseModal();
      } else {
        console.error('Something went wrong while creating photo');
        showError('Something went wrong while creating photo');
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showError('Something went wrong while creating photo');
    }
  };
  return (
    <>
      <h1 className="text-lg font-semibold mb-2 dark:text-white">Review</h1>
      <p className="text-neutral-400 mb-8 dark:text-neutral-200">
        Review and create your talking photo.
      </p>
      <div className="text-sm flex space-x-4 text-neutral-400 mb-5">
        <div className="border border-gray-200 w-24 h-24 rounded-lg relative shrink-0 overflow-hidden">
          <img
            src={uploadedPhoto}
            alt="Talking photo example"
            className="w-full h-full object-contain z-10"
          />
        </div>
        <div className="text-sm text-neutral-400">
          <div>
            Name:
            <span className="text-black ml-2 dark:text-white">
              {uploadedPhotoDetails.name}
            </span>
          </div>
          <div>
            Age:
            <span className="text-black ml-2 dark:text-white">
              {uploadedPhotoDetails.age}
            </span>
          </div>
          {uploadedPhotoDetails.gender && (
            <div>
              Gender:
              <span className="text-black ml-2 dark:text-white">
                {capitalize(uploadedPhotoDetails.gender)}
              </span>
            </div>
          )}
          <div>
            Status:
            <span className="text-black ml-2 dark:text-white">in process*</span>
          </div>
        </div>
      </div>
      <div className="w-11/12 bg-gray-100 mb-6 h-px"></div>
      <div className="text-sm text-neutral-400">
        <p className="mb-4">
          Our team will verify and approve your talking photo shortly. We have
          an automated system being tested, but humans are verifying the
          results. Estimated approval time: 1 business day
        </p>
        <p>Working hours 10am-6pm GMT Monday to Friday</p>
        {showDelayWarning && (
          <p className="text-red-600 mt-4">
            There is currently a delay in approval and production of custom
            talking photo avatars. We expect this to be resolved within 48
            hours. We apologise for any inconvenience caused.
          </p>
        )}
      </div>
      <div
        className="absolute flex flex-col-reverse sm:flex-row gap-2 mt-4 justify-between items-center 
          bottom-0 left-0 sm:left-6 sm:right-4 sm:bottom-4 bg-white z-20 sm:z-auto w-full sm:w-auto py-4 sm:py-0 dark:bg-dark-primary"
      >
        <button
          onClick={() => onSetStep((prevState) => prevState - 1)}
          className={`bg-white border border-black  text-sm font-semibold rounded py-2.5 px-16 min-w-[180px]`}
        >
          Back
        </button>
        <button
          onClick={handleCreatePhoto}
          className="bg-secondary border border-secondary text-white text-sm font-semibold rounded py-2.5 px-16 min-w-[180px] flex justify-center items-center"
        >
          {isLoading ? <Loader size={18} color="#ffffff" /> : 'Create'}
        </button>
      </div>
    </>
  );
};

export default Step4;
