/* eslint-disable camelcase */
import { IDefaultMessage, IMessage, IMessageResponse } from '../types/types';
import httpService from './http/http.service';
import { getMemberstackJWT } from '../utils/getMemberstackJWT';
import { AxiosResponse } from 'axios';

export const chatService = {
  get: async function (): Promise<string[]> {
    const { data } = await httpService.get('/chat/');
    return data.chats;
  },
  getHistory: async function (chatId: string): Promise<IMessage[] | any[]> {
    const { data } = await httpService.get(`/chat/history?chatId=${chatId}`);
    return data;
  },
  create: async function (): Promise<string> {
    const { data } = await httpService.post(`/chat/create`);
    return data.chatId;
  },
  sendMessage: (
    chatId: string | null = null,
    message: string,
    avatarId = '3bfc9743-a530-44d7-9980-6af1993e81b2',
    voiceId = 'en-US_AllisonV3Voice',
    language = 'English (United States)'
  ): Promise<IMessageResponse> => {
    return httpService
      .post(
        `/chat/matrix`,
        {
          chatId,
          message,
          voiceId,
          avatarId,
          language
        },
        { 'ms-token': getMemberstackJWT() }
      )
      .then(({ data }) => {
        return data;
      });
  },
  streamVideo: (videoUrl: string): string => {
    return `/chat/video/?video_url=${videoUrl}`;
  },
  translateAudio: (audio: Blob): Promise<string> => {
    const formData = new FormData();
    formData.append('recording', audio);
    return httpService
      .post(`/chat/audio`, formData, {
        'Content-Type': 'multipart/form-data'
      })
      .then((res) => res.data.text);
  },
  delete: (chatId: string): Promise<string> => {
    return httpService
      .delete(`/chat/?chatId=${chatId}`)
      .then((res) => res.data);
  },
  getDefaultMessages: (): Promise<IDefaultMessage[] | void> => {
    return httpService
      .get(`/chat/default-messages`)
      .then((res) => res.data)
      .then((res) => res.default_messages);
  },
  useDefaultMessage: (
    chatId: string | null = null,
    defaultMessageId: string,
    isAuthorized: boolean,
    avatarId = '3bfc9743-a530-44d7-9980-6af1993e81b2',
    voiceId = 'en-US_AllisonV3Voice',
    avatarName = 'Lyanna'
  ): Promise<IMessageResponse> => {
    const slug = isAuthorized
      ? '/chat/default-message'
      : '/chat/default-message-public';
    const payload = {
      defaultMessageId,
      voiceId,
      avatarId,
      avatarName
    };
    return httpService
      .post(slug, isAuthorized ? { ...payload, chatId } : payload, {
        'ms-token': isAuthorized ? getMemberstackJWT() : ''
      })
      .then(({ data }) => data);
  },
  createChannel: (
    channelName: string
  ): Promise<AxiosResponse<{ channelARN: string }>> =>
    httpService.post(`/kinesis/?name=${channelName}`),
  deleteChannel: (channelName: string) =>
    httpService.delete(`/kinesis/?name=${channelName}`)
};
