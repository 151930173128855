import type { FC } from 'react';
import { Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import Input from '../shared/Input';
import { GrClose } from 'react-icons/gr';

interface Props {
  onClose: () => void;
  open: boolean;
}

const NoConversations: FC<Props> = ({ onClose, open }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000] bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-6 pt-7 text-left shadow-xl transition-all w-[25rem]">
                <div className="border-b border-neutral-200 -mx-6 px-6">
                  <div className="flex h-14 w-14 items-center justify-center rounded-full relative">
                    <img
                      src="/messages.svg"
                      className="h-14 w-14 text-green-600"
                      alt="messages"
                    />
                    <button
                      onClick={onClose}
                      className="h-fit absolute right-0 top-0"
                    >
                      <GrClose className="w-6 h-6 p-1" />
                    </button>
                  </div>
                  <div className="mt-6 mb-5 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-7 text-neutral-900"
                    >
                      Get more chat credits
                    </Dialog.Title>
                  </div>
                </div>
                <div className="mt-6 flex flex-col gap-y-2">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold text-neutral-600"
                  >
                    Invite a friend
                  </Dialog.Title>
                  <p className="text-sm text-gray-600 mb-2">
                    Get 10 new chat credits for each friend invited that{' '}
                    <span className="whitespace-nowrap">sign up</span>
                  </p>
                  <div className="flex gap-x-3.5 items-center">
                    <Input
                      className="w-full py-2.5 px-3.5 text-neutral-900 text-base"
                      readOnly
                      value="join.untitledui.com/project"
                    />
                    <button>
                      <svg
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.16675 11.9993C3.39018 11.9993 3.00189 11.9993 2.69561 11.8725C2.28723 11.7033 1.96277 11.3789 1.79362 10.9705C1.66675 10.6642 1.66675 10.2759 1.66675 9.49935V3.83268C1.66675 2.89926 1.66675 2.43255 1.8484 2.07603C2.00819 1.76243 2.26316 1.50746 2.57676 1.34767C2.93328 1.16602 3.39999 1.16602 4.33341 1.16602H10.0001C10.7767 1.16602 11.1649 1.16602 11.4712 1.29288C11.8796 1.46204 12.2041 1.7865 12.3732 2.19488C12.5001 2.50116 12.5001 2.88945 12.5001 3.66602M10.1667 17.8327H15.6667C16.6002 17.8327 17.0669 17.8327 17.4234 17.651C17.737 17.4912 17.992 17.2363 18.1518 16.9227C18.3334 16.5661 18.3334 16.0994 18.3334 15.166V9.66602C18.3334 8.73259 18.3334 8.26588 18.1518 7.90937C17.992 7.59576 17.737 7.34079 17.4234 7.18101C17.0669 6.99935 16.6002 6.99935 15.6667 6.99935H10.1667C9.23333 6.99935 8.76662 6.99935 8.4101 7.18101C8.09649 7.34079 7.84153 7.59576 7.68174 7.90937C7.50008 8.26588 7.50008 8.7326 7.50008 9.66602V15.166C7.50008 16.0994 7.50008 16.5661 7.68174 16.9227C7.84153 17.2363 8.09649 17.4912 8.4101 17.651C8.76662 17.8327 9.23333 17.8327 10.1667 17.8327Z"
                          stroke="#737373"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex gap-x-2 items-center">
                    <div className="border-t border-neutral-200 w-full" />
                    <span>Or</span>
                    <div className="border-t border-neutral-200 w-full" />
                  </div>
                </div>
                <div className="mt-6 flex flex-col gap-y-2">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold text-neutral-600"
                  >
                    Upgrade
                  </Dialog.Title>
                  <p className="text-sm text-gray-600 mb-2">
                    Upgrade to a developer mode to get X conversations
                  </p>
                </div>
                <div className="mt-5 sm:mt-6">
                  <a
                    href="https://www.yepic.ai/pricing"
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-secondary px-3 py-2 text-sm font-semibold text-white shadow-sm"
                    onClick={onClose}
                  >
                    Upgrade
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default NoConversations;
