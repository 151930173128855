import { createRef, FC, useEffect, useState } from 'react';
import { useChat } from '../../hooks/useChat';
import Avatar from '../Avatar/Avatar';
import Chat from './Chat';
import MessageInput from './MessageInput';
import FilledButton from '../shared/Buttons/FilledButton';
import IntegrateModal from '../Modals/IntegrateModal';
import { useAuth } from '../../hooks/useAuth';
import { IWebRTCProps } from '../../types/types';
import MatrixBackground from '../Avatar/MatrixBackground';

const ChatboxView: FC<IWebRTCProps> = ({ isWebRTC }) => {
  const { chat } = useChat();
  const { user } = useAuth();
  const [isIntegrateOpened, setIsIntegrateOpened] = useState(false);

  const containerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    containerRef.current?.scroll({
      top: containerRef.current?.scrollHeight
    });
  }, [chat]);

  return (
    <div className="xl:h-full xl:border xl:rounded-lg sm:border-neutral-200 bg-white flex xl:items-center  justify-center grow shrink px-4 xl:p-10 xl:overflow-hidden relative dark:sm:border-neutral-800 dark:bg-dark-primary">
      <MatrixBackground />
      <div className="h-full xl:max-w-xl xl:max-h-[36rem] flex justify-center items-center bg-white xl:bg-neutral-50 rounded-full xl:aspect-square shrink z-10 dark:bg-neutral-900">
        <div className="max-w-sm border border-neutral-200 rounded-3xl bg-white shadow-xl dark:bg-dark-primary dark:border-neutral-800">
          <Avatar isChatbot={true} isWebRTC={isWebRTC} />
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="py-4">
            <div
              ref={containerRef}
              className="h-[230px] overflow-y-auto no-scrollbar"
            >
              <Chat isChatbot={true} />
            </div>
          </div>
          <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="overflow-hidden rounded-b-3xl">
            <MessageInput isChatbot={true} />
          </div>
        </div>
      </div>
      <FilledButton
        onClick={() => setIsIntegrateOpened(true)}
        className="absolute bottom-4 right-4 px-3 py-2 font-semibold text-white group"
        disabled={!user}
      >
        <span className="animate-ping absolute inline-flex h-full w-full rounded-xl bg-secondary opacity-50 blur-sm group-disabled:animate-none group-disabled:hidden" />
        <span className="z-10 text-white">Integrate</span>
      </FilledButton>
      <IntegrateModal
        onClose={() => setIsIntegrateOpened(false)}
        isOpened={isIntegrateOpened}
      />
    </div>
  );
};

export default ChatboxView;
