import { FC, useState } from 'react';
import { useFormik } from 'formik';
import FormError from '../FormError/FormError';
import { Dialog } from '@headlessui/react';
import { MdOutlineClose } from 'react-icons/md';
import { AiFillGithub } from 'react-icons/ai';
import { FcGoogle } from 'react-icons/fc';
import { AxiosError } from 'axios';
import { useAuth } from '../../../hooks/useAuth';
import FilledButton from '../../shared/Buttons/FilledButton';
import Loader from '../../Loader';
import useInviteStore from '../../../stores/useInviteStore';

type Props = {
  onClose: () => void;
  openLoginForm: () => void;
};

type ValidationErrors = {
  password?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
};

const validate = (values: {
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
}) => {
  const errors: ValidationErrors = {};
  if (!values.password) {
    errors.password = 'Required';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email';
  }

  if (!values.firstName) {
    errors.firstName = 'Required';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  return errors;
};

const RegisterForm: FC<Props> = ({ onClose, openLoginForm }: Props) => {
  const { signUp } = useAuth();
  const [responseStatus, setResponseStatus] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const searchParams = new URLSearchParams(window.location.search);
  const registerUserInInviteFlow = useInviteStore(
    (state) => state.registerUser
  );
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      companyName: ''
    },
    validate,
    onSubmit: async (
      { password, firstName, companyName, lastName, email },
      { resetForm }
    ) => {
      try {
        setIsLoading(true);
        await signUp({
          password,
          first_name: firstName,
          last_name: lastName,
          company_name: companyName,
          email
        });
        registerUserInInviteFlow({
          email,
          firstName,
          refCode: searchParams.get('ref_code') || null,
          refSource: searchParams.get('ref_source') || null,
          location: window.location.href.split('?')[0]
        });
        setResponseStatus(null);
        onClose();
      } catch (e: any) {
        if (e instanceof AxiosError) {
          setResponseStatus(
            e.response?.request.status === 400
              ? 'This email is already used'
              : `Sorry, our server is experiencing some issues. Please try again later. Error: ${e.response?.statusText}`
          );
        } else {
          if (e?.message) setResponseStatus(e.message);
        }
      }
      resetForm();
      setIsLoading(false);
    }
  });
  return (
    <form
      className="bg-white md:rounded-xl w-full flex max-md:min-h-screen flex-col md:gap-5 z-20 h-full px-6 pb-4 pt-5 sm:p-6"
      onSubmit={formik.handleSubmit}
    >
      <button
        className="max-md:hidden w-4 h-4 bg-no-repeat bg-cover absolute left-full -translate-x-[300%]"
        onClick={onClose}
        type="button"
      >
        <MdOutlineClose size={20} style={{ color: '#454545' }} />
      </button>
      <div className="max-md:mb-5 flex flex-col justify-center items-center gap-1">
        <div className="bg-[url('/logo.svg')] bg-no-repeat bg-cover w-[43px] h-[30px] mb-3" />
        <h2 className=" text-lg font-medium text-neutral-900 leading-7">
          Sign up
        </h2>
        <p className="text-neutral-600 text-sm font-light">
          Sign up to start a conversation
        </p>
      </div>
      <div className="max-md:mb-8 flex flex-col gap-4 md:gap-3">
        <div className="flex flex-col sm:flex-row sm:justify-between gap-5">
          <div className="flex flex-col w-full text-sm sm:flex-row gap-4">
            <label className="flex flex-col gap-1.5 text-neutral-700 w-full">
              Name*
              <input
                name="firstName"
                className={`border-solid border border-neutral py-2 px-3 rounded-md 
                              ${
                                formik.errors.firstName &&
                                formik.touched.firstName
                                  ? 'ring-red-300 placeholder:text-red-300 text-red-900 focus:outline-red-500  focus:border-red-500'
                                  : 'text-gray-900 placeholder:text-gray-400 focus:outline-secondary'
                              }`}
                placeholder="Enter your first name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <FormError>{formik.errors.firstName}</FormError>
              ) : null}
            </label>
            <label className="flex flex-col gap-1.5 text-neutral-700 w-full">
              Last Name*
              <input
                name="lastName"
                className={`border-solid border border-neutral py-2 px-3 rounded-md 
                              ${
                                formik.errors.lastName &&
                                formik.touched.lastName
                                  ? 'ring-red-300 placeholder:text-red-300 text-red-900 focus:outline-red-500  focus:border-red-500'
                                  : 'text-gray-900 placeholder:text-gray-400 focus:outline-secondary'
                              }`}
                placeholder="Enter your last name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <FormError>{formik.errors.lastName}</FormError>
              ) : null}
            </label>
          </div>
        </div>
        <div className="flex flex-col text-sm">
          <label className="flex flex-col gap-1.5 text-neutral-700">
            Email*
            <input
              className={`border-solid border border-neutral py-2 px-3 rounded-md 
                            ${
                              formik.errors.email && formik.touched.email
                                ? 'ring-red-300 placeholder:text-red-300 text-red-900 focus:outline-red-500  focus:border-red-500'
                                : 'text-gray-900 placeholder:text-gray-400 focus:outline-secondary'
                            }`}
              placeholder="Enter your email"
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name="email"
            />
            {formik.errors.email && formik.touched.email ? (
              <FormError>{formik.errors.email}</FormError>
            ) : null}
          </label>
        </div>
        <div className="flex flex-col text-sm">
          <label className="flex flex-col gap-1.5 text-neutral-700">
            Password*
            <input
              className={`text-base border-solid border border-neutral-300 py-1.5 px-3 rounded-md 
                            ${
                              formik.errors.password && formik.touched.password
                                ? 'ring-red-300 placeholder:text-red-300 text-red-900 focus:ring-red-500 focus:border-red-500'
                                : 'text-gray-900 placeholder:text-gray-400 focus:ring-secondary focus:border-secondary '
                            }`}
              placeholder="•••••••"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              name="password"
              type="password"
            />
            {formik.errors.password && formik.touched.password ? (
              <FormError>{formik.errors.password}</FormError>
            ) : null}
          </label>
        </div>
        <div className="flex flex-col text-sm">
          <label className="flex flex-col gap-1.5 text-neutral-700">
            Company Name
            <input
              className="border-solid border border-neutral py-2 px-3 rounded-md focus:outline-secondary"
              placeholder="Optional"
              name="companyName"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.companyName}
            />
          </label>
        </div>
        <div className="flex flex-col gap-2">
          <p className="text-sm text-gray-secondary leading-5 tracking-[0.0156rem]">
            Have you read and accepted our
            <a href="#" className="text-secondary mx-1 tracking-[0.0156rem]">
              Content Guidelines
            </a>
            and
            <a href="#" className="text-secondary mx-1 tracking-[0.0156rem]">
              Terms & Conditions
            </a>
            and agree to receive product information as per our Privacy Policy?
          </p>
          <div>
            <label className="leading-5 text-dark-pink text-sm flex flex-row-reverse justify-end gap-1.5 items-center">
              I agree*
              <input
                className="h-4 w-4 rounded border-gray-secondary text-secondary focus:outline-none my-auto"
                type="checkbox"
                required
              />
            </label>
          </div>
        </div>
      </div>
      <FormError>{responseStatus}</FormError>
      <div className="max-md:mb-6 flex flex-col gap-2">
        <FilledButton className="py-2 text-base h-11" type="submit">
          {isLoading ? <Loader color="#fff" /> : 'Sign up'}
        </FilledButton>
        <div className="justify-around gap-2 hidden">
          <a
            href="#"
            className="flex justify-center gap-2 text-sm font-semibold leading-6 text-neutral-700 items-center border-solid border border-neutral w-full rounded-md py-2"
          >
            <AiFillGithub size={28} />
            Github
          </a>
          <a
            href="#"
            className="flex justify-center gap-2 text-sm font-semibold leading-6 text-neutral-700 items-center w-full rounded-md border-solid border border-neutral py-2"
          >
            <FcGoogle size={28} />
            Google
          </a>
        </div>
      </div>
      <div className="flex items-center justify-center gap-1 text-base">
        <p className="  font-light leading-7">Already an user?</p>
        <button
          className=" text-secondary font-semibold"
          onClick={openLoginForm}
        >
          Sign in
        </button>
      </div>
    </form>
  );
};

export default RegisterForm;
