import { FC, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MdOutlineClose } from 'react-icons/md';
import OutlinedButton from '../shared/Buttons/OutlinedButton';
import { Link } from 'react-router-dom';

type Props = {
  open: boolean;
  onClose: () => void;
};

const EnterpriseModal: FC<Props> = ({ open, onClose }) => {
  return (
    <Transition.Root show={!!open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000] bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center md:p-4 items-center sm:p-0">
            <Transition.Child
              as={'div'}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all mx-2 sm:mx-0 ">
                <div className="bg-white rounded-xl w-full flex flex-col z-20 h-full py-6 sm:w-[25rem]">
                  <div className="flex flex-col justify-center items-center  ">
                    <div className="px-6 flex items-center justify-center mb-6 mx-auto">
                      <img
                        src="/logo.svg"
                        className="w-10 h-[1.875rem]"
                        alt="logo"
                      />
                      <h3
                        className={`uppercase text-black text-[26px] leading-8 font-semibold mx-2`}
                      >
                        Yepic
                      </h3>
                      <div className={`flex h-8 items-center`}>
                        <img src="/ai1.svg" className=" mr-1.5 w-auto h-auto" />
                        <img src="/ai2.svg" className="mr-0.5 w-auto h-auto" />
                        <img src="/ai3.svg" className="w-auto h-auto" />
                      </div>
                    </div>
                    <h2 className="px-6 text-lg font-semibold text-neutral-900 leading-7">
                      Enterprise
                    </h2>
                    <p className="px-6 text-neutral-600 text-sm font-light text-center pt-1 pb-8">
                      This is an enterprise feature. Book a call with us to
                      <br />
                      discuss it.
                    </p>
                    <div className="border-t border-solid border-neutral-300 w-full mb-6" />
                    <div className="px-6 flex justify-around gap-3 w-full">
                      <OutlinedButton
                        className="!text-base w-full font-semibold leading-6 text-neutral-700 py-2.5"
                        onClick={onClose}
                      >
                        Cancel
                      </OutlinedButton>
                      <Link to="/enterprise-call" className="w-full">
                        <OutlinedButton className="!text-base w-full font-semibold leading-6 text-white py-2.5 bg-secondary">
                          Book a call
                        </OutlinedButton>
                      </Link>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default EnterpriseModal;
