import { useChat } from '../../hooks/useChat';
import AvatarVideo from './AvatarVideo';
import { BsFillStopFill } from 'react-icons/bs';
import useAvatarStore from '../../stores/useAvatarStore';
import clsx from 'clsx';
import { VideoWithFallback } from '../VideoWithFallback';
import { IWebRTCProps } from '../../types/types';
import WebRtcVideo from './WebRTCVideo';

type AvatarProps = {
  isChatbot: boolean;
};

const Avatar = ({ isChatbot, isWebRTC }: AvatarProps & IWebRTCProps) => {
  const { avatarVideos, handleVideoEnd, isLoading } = useChat();
  const activeAvatar = useAvatarStore((state) => state.activeAvatar);

  return (
    <div
      className={clsx(
        'select-none w-full relative',
        !isChatbot &&
          ' py-3 sm:pt-4 sm:pb-5 border-b border lg:rounded-lg border-neutral-100 lg:border-neutral-200 bg-white flex justify-center items-center px-6 dark:bg-dark-primary dark:border-neutral-800 '
      )}
    >
      <div
        className={clsx(
          'aspect-square overflow-clip relative z-10',
          isChatbot
            ? 'w-40 mx-auto -mt-12 rounded-full border-8 border-white dark:border-dark-primary'
            : 'w-full rounded-lg max-w-[calc(42.3vh-56px)] lg:w-[42vh] lg:max-w-[42vh] xl:w-[50vh] xl:max-w-[50vh]'
        )}
      >
        {isWebRTC && !avatarVideos?.length ? (
          <WebRtcVideo key="WebRtcVideo" />
        ) : (
          <AvatarVideo key="AvatarVideo" />
        )}

        {(activeAvatar?.talkingPhoto && activeAvatar?.hasIdleLoop && (
          <VideoWithFallback
            key={
              `idle-loop-${activeAvatar?.idleLoopVideoUrl}` ||
              new Date().toString()
            }
            src={activeAvatar?.idleLoopVideoUrl}
            className="w-full h-full absolute z-0"
            loop
            autoPlay
            muted
            fallbackElement={() => (
              <img
                src={
                  activeAvatar?.inputImageUrl ||
                  activeAvatar?.menuImageUrl ||
                  ''
                }
                className="w-full h-full absolute z-0"
                alt="avatar"
              />
            )}
          />
        )) ||
          activeAvatar?.inputImageUrl ||
          (activeAvatar?.menuImageUrl && (
            <img
              src={
                activeAvatar?.inputImageUrl || activeAvatar?.menuImageUrl || ''
              }
              className="w-full h-full absolute z-0"
              alt="avatar"
            />
          )) ||
          null}
      </div>
      {isLoading && (
        <div className="absolute bottom-6 right-10 hover:scale-110">
          <img
            src="/gpt.png"
            className="w-6 h-6 animate-[spin_2s_linear_infinite]"
            alt="chat-gpt"
          />
        </div>
      )}
      {!!avatarVideos?.length && !isLoading && (
        <button
          className="absolute bottom-6 right-10 hover:scale-110"
          type="button"
          onClick={handleVideoEnd}
          title="stop video"
        >
          <BsFillStopFill className="w-6 h-6" />
        </button>
      )}
    </div>
  );
};

export default Avatar;
