import { create } from 'zustand';
import inviteService from '../services/invite.service';

export interface IRegisterUserInViralLoopPayload {
  email: string;
  firstName: string;
  refCode: string | null;
  refSource: string | null;
  location: string;
}

interface InviteStore {
  link: string;
  rank: string;
  referralCountTotal: number;
  init: (email: string) => void;
  registerUser: (payload: IRegisterUserInViralLoopPayload) => void;
  loading: boolean;
}

const useInviteStore = create<InviteStore>((set, get) => ({
  link: '',
  rank: '',
  loading: false,
  referralCountTotal: 0,
  init: async (email) => {
    try {
      set((state) => ({ loading: true }));
      const link = await inviteService.getLink(email);
      const { rank, referralCountTotal } = await inviteService.getRank(email);
      set((state) => ({ link, rank, referralCountTotal, loading: false }));
    } catch (err) {
      console.error(err);
      set((state) => ({ loading: false }));
    }
  },
  registerUser: async (payload) => {
    try {
      const user = await inviteService.registerUser(payload);
      if (user.link) set((state) => ({ link: user.link }));
    } catch (err) {
      console.error(err);
    }
  }
}));

export default useInviteStore;
