import React from 'react';
import { IAvatar } from '../../../types/types';
import { FaInfoCircle } from 'react-icons/fa';

type AvatarItemProps = {
  avatar: IAvatar;
  onChooseAvatar: (avatar: IAvatar) => void;
  isActive?: boolean;
  openModal?: (avatar: IAvatar) => void;
};

const AvatarItem: React.FC<AvatarItemProps> = ({
  avatar,
  onChooseAvatar,
  isActive,
  openModal
}) => {
  // const isDisabled = !avatar.idleLoopVideoUrl;
  const imgUrl =
    avatar.inputImageUrl ?? avatar.menuImageUrl ?? avatar.rawImageUrl;
  return (
    <div
      onClick={() => onChooseAvatar(avatar)}
      // onClick={() => isDisabled || onChooseAvatar(avatar)}
      className={`relative group ${!isActive ? 'opacity-60' : ''}`}
    >
      {imgUrl && (
        <img
          src={imgUrl}
          alt={avatar.name}
          className={`w-full aspect-square border ${
            isActive ? 'border-secondary' : 'border-gray-300'
          } rounded`}
        />
      )}
      <div className="hidden group-hover:block absolute bottom-0 top-2/3 left-0 right-0 bg-title-gradient rounded-t" />
      {openModal && (
        <button
          onClick={() => {
            openModal(avatar);
          }}
          className=" absolute w-5 h-5 !rounded-full top-1 right-1  hidden
         justify-center items-center z-20 group-hover:flex opacity-75 hover:opacity-100"
        >
          <FaInfoCircle className="text-sm bg-white rounded-full" />
        </button>
      )}
      <span className="text-white text-sm hidden group-hover:inline absolute bottom-2 left-2">
        {avatar.name}
      </span>
    </div>
  );
};

export default AvatarItem;
