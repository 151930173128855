import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../../hooks/useAuth';
import SideBar from './SideBar';
import { capitalize } from '../utils/utils';
import Modal from '../../../../shared/Modal';
import { IAvatar } from '../../../../../types/types';
import useAvatarStore from '../../../../../stores/useAvatarStore';
import Loader from './Loader';
import { toast } from 'react-toastify';

type CustomTalkingPhotoInfoProps = {
  onClose: () => void;
  avatar: IAvatar;
};

const CustomTalkingPhotoInfo: React.FC<CustomTalkingPhotoInfoProps> = ({
  onClose,
  avatar
}) => {
  const imgUrl =
    avatar.menuImageUrl ?? avatar.inputImageUrl ?? avatar.rawImageUrl;

  const { deleteCustomTalkingPhoto } = useAvatarStore();

  const [isDeleteConfirmationOpened, setIsDeleteConfirmationOpened] =
    useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeletePhoto = async (id: string) => {
    try {
      setIsDeleting(true);
      const data = await deleteCustomTalkingPhoto(id);
      setIsDeleting(false);
      if (data) {
        setIsDeleteConfirmationOpened(false);
        toast('Custom photo deleted successfully');
        onClose();
      }
    } catch (err) {
      setIsDeleting(false);
      console.error(err);
      toast.error(`Failed to delete photo. Error: ${(err as Error).message}`);
    }
  };

  useEffect(() => {
    if (isDeleteConfirmationOpened) {
      setTimeout(() => {
        setIsDeleteConfirmationOpened(false);
      }, 3000);
    }
  }, [isDeleteConfirmationOpened]);

  return (
    <div className="relative w-full md:flex md:min-h-[500px] md:overflow-hidden">
      <svg
        onClick={onClose}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-4 h-4 absolute top-4 right-4 md:top-6 md:right-6 cursor-pointer z-10 text-neutral-400 md:text-black"
      >
        <path
          d="M1.60938 14.846L14.453 1.8418M1.60938 1.8418L14.453 14.846"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <SideBar currentStep={null} />

      <div className="pt-8 pl-4 md:pl-6 pr-4 pb-20 w-full relative ">
        <div className="flex gap-4 mt-20">
          {imgUrl && (
            <img
              crossOrigin="anonymous"
              className={`w-32 md:w-72 aspect-square object-cover rounded-lg`}
              src={imgUrl}
              alt={avatar.name}
            />
          )}
          <div className="text-gray-400 flex flex-col gap-1 font-normal text-base leading-6">
            <p>
              Name:{' '}
              <span className="text-black"> {capitalize(avatar.name)}</span>
            </p>
            {avatar.age && (
              <p>
                Age: <span className="text-black"> {avatar.age}</span>
              </p>
            )}
            <p>
              Gender:{' '}
              <span className="text-black"> {capitalize(avatar?.gender)}</span>
            </p>
            <p>
              Status:{' '}
              <span className="text-black">
                {' '}
                {avatar.avatarStatus || 'Under review'}
              </span>
            </p>
            <button
              onClick={() =>
                isDeleteConfirmationOpened
                  ? handleDeletePhoto(avatar.id)
                  : setIsDeleteConfirmationOpened(true)
              }
              className="w-full font-semibold border flex justify-center border-red-700 hover:bg-red-700
               active:bg-red-500 active:border-red-500 text-red-700 hover:text-white py-1.5 mt-8"
            >
              {isDeleting ? (
                <Loader color="#fff" />
              ) : isDeleteConfirmationOpened ? (
                'Confirm'
              ) : (
                'Delete photo'
              )}
            </button>
            {/* <Modal
              open={isDeleteConfirmationOpened}
              setOpen={setIsDeleteConfirmationOpened}
              // className="w-[360px] p-4"
            >
              <ConfirmationContent
                text={
                  <span className="tracking-wide">
                    Are you sure you want to delete this photo?
                  </span>
                }
                btnLabel="Delete photo"
                onClick={handleDeletePhoto}
                isDeleting={isDeleting}
                onToggle={() =>
                  setIsDeleteConfirmationOpened((prevState) => !prevState)
                }
              />
            </Modal> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomTalkingPhotoInfo;
