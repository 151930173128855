import clsx from 'clsx';
import { ChangeEvent, InputHTMLAttributes } from 'react';
import { prices } from './IntegrateForm';

type PriceRadioBtnProps = {
  isActive: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  value: (typeof prices)[number];
};

const PriceRadioBtn = ({
  isActive,
  onChange,
  value,
  id
}: PriceRadioBtnProps) => {
  return (
    <>
      <input
        type="radio"
        name="price"
        id={id}
        value={value as string}
        onChange={onChange}
        className="hidden"
      />
      <label
        className={clsx(
          'mb-1.5 text-neutral-700 py-2 px-3.5 block border border-neutral-300 rounded-md cursor-pointer',
          isActive && 'border-primary-600 cursor-default'
        )}
        htmlFor={id}
      >
        {value as string}
      </label>
    </>
  );
};

export default PriceRadioBtn;
