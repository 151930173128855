import httpService from './http/http.service';
import { ICredits } from '../types/types';

const host = import.meta.env.VITE_YEPIC_BILLING_BACKEND;

export const billingService = {
  getCredits: (): Promise<ICredits> =>
    httpService
      .get(
        `${host}/credits/?memberstack_id=${
          JSON.parse(localStorage.getItem('memberstack') || '{}')?.information
            ?.id
        }`
      )
      .then((res) => res.data)
};
