import React from 'react';
import { ThreeCircles } from 'react-loader-spinner';

type LoaderProps = {
  size?: number;
  color?: string;
};

const Loader: React.FC<LoaderProps> = ({ size, color }) => {
  return (
    <ThreeCircles
      color={color || '#112943'}
      height={size || 25}
      width={size || 25}
      ariaLabel="three-circles-rotating"
    />
  );
};

export default Loader;
