import React, { useCallback, useEffect, useRef } from 'react';

type DropZoneProps = {
  children: React.ReactNode;
  onDrop: (e: Event) => void;
  onDrag: (e: Event) => void;
};

const DropZone: React.FC<DropZoneProps> = ({ children, onDrop, onDrag }) => {
  const drop = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const { current } = drop;

    if (!current) return;

    current.addEventListener('dragover', onDrag);
    current.addEventListener('drop', onDrop);

    return () => {
      current.removeEventListener('dragover', onDrag);
      current.removeEventListener('drop', onDrop);
    };
  }, [onDrag, onDrop]);

  return <div ref={drop}>{children}</div>;
};

export default DropZone;
