import type { FC } from 'react';
import { Fragment, useEffect } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import Input from '../shared/Input';
import { GrClose } from 'react-icons/gr';
import FilledButton from '../shared/Buttons/FilledButton';
import { useAuth } from '../../hooks/useAuth';
import { Link } from 'react-router-dom';
import Divider from '../Divider';
import { useFormik } from 'formik';
import { AxiosError } from 'axios';
import { IUserMetadata } from '../../types/types';
import Loader from '../Loader';
import { toast } from 'react-toastify';
import { useNotificationStore } from '../../stores/useNotificationStore';

interface Props {
  onClose: () => void;
  open: boolean;
}

const ProfileModal: FC<Props> = ({ onClose, open }) => {
  const { user, logout, updateUser, userData } = useAuth();
  const { showMessage, showSuccessMessage, showError } = useNotificationStore();

  const handleLogout = () => {
    onClose();
    logout();
  };

  const getInitialValues = (): IUserMetadata => ({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    companyName: user?.companyName || ''
  });

  const { isSubmitting, values, handleChange, handleSubmit, setValues } =
    useFormik({
      initialValues: getInitialValues(),
      onSubmit: async (values, { resetForm }) => {
        try {
          await updateUser(values);
          showMessage('Profile updated successfully.');
        } catch (e: any) {
          if (e instanceof AxiosError) {
            showError(
              'Sorry, our server is experiencing some issues. Please try again later.'
            );
          } else {
            if (e?.message)
              showError(`Failed to update profile. Error: ${e.message}`);
          }
        }
        resetForm();
      }
    });

  useEffect(() => {
    setValues(getInitialValues());
  }, [user]);

  const touched =
    (user?.firstName || '') !== values.firstName ||
    (user?.lastName || '') !== values.lastName ||
    (user?.companyName || '') !== values.companyName;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000] bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center md:p-4 text-center items-center dark">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="max-md:min-h-screen max-md:w-screen relative transform overflow-hidden md:rounded-lg bg-white text-left shadow-xl transition-all w-[34rem] dark:bg-neutral-800">
                <form
                  onSubmit={handleSubmit}
                  className="max-md:min-h-screen max-md:w-screen flex flex-col justify-between h-full"
                >
                  <div className="p-6 pt-2 md:pt-7">
                    <div className="w-full flex min-h-[3rem] md:min-h-[3.5rem] items-center justify-between gap-x-4 relative">
                      <div className="rounded-full w-10 h-10 md:w-12 md:h-12 aspect-square flex bg-gradient-to-r from-[#C256FC] to-[#D12766] items-center justify-center">
                        <div className="text-white text-2xl font-medium">
                          {(user?.firstName || 'A')[0]}
                        </div>
                      </div>
                      <div className="w-full h-12 md:h-14">
                        <div className="flex justify-between text-neutral-900 text-lg font-semibold dark:text-white">
                          Profile
                          <button
                            onClick={onClose}
                            className="h-fit max-md:hidden"
                            type="button"
                          >
                            <GrClose className="w-6 h-6 p-1" />
                          </button>
                        </div>
                        <span className="text-neutral-600 text-sm dark:text-neutral-200">
                          Your details and API KEY
                        </span>
                      </div>
                      <button
                        onClick={onClose}
                        className="h-fit p-2 md:hidden"
                        type="button"
                      >
                        <GrClose className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                  <Divider />
                  <div className="flex flex-col mt-5 gap-y-4 p-6 max-md:pt-5 flex-1">
                    <div className="flex flex-col gap-y-4">
                      <div className="flex max-md:flex-col gap-y-1.5 gap-x-8">
                        <div className="w-40 min-w-[10rem] text-neutral-700 text-sm font-medium dark:text-neutral-100">
                          First name
                        </div>
                        <Input
                          className="w-full py-2.5 px-3.5 text-neutral-500 dark:text-neutral-200"
                          value={values.firstName}
                          onChange={handleChange}
                          name="firstName"
                        />
                      </div>
                      <div className="flex max-md:flex-col gap-y-1.5 gap-x-8">
                        <div className="w-40 min-w-[10rem] text-neutral-700 text-sm font-medium dark:text-neutral-100">
                          Last name
                        </div>
                        <Input
                          className="w-full py-2.5 px-3.5 text-neutral-500 dark:text-neutral-200"
                          value={values.lastName}
                          onChange={handleChange}
                          name="lastName"
                        />
                      </div>
                      <div className="flex max-md:flex-col gap-y-1.5 gap-x-8">
                        <div className="w-40 min-w-[10rem] text-neutral-700 text-sm font-medium dark:text-neutral-100">
                          Company Name
                        </div>
                        <Input
                          className="w-full py-2.5 px-3.5 text-neutral-500 dark:text-neutral-200"
                          value={values.companyName}
                          onChange={handleChange}
                          name="companyName"
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="flex flex-col gap-y-2">
                      <div className="flex max-md:flex-col gap-y-1.5 gap-x-8">
                        <div className="w-40 min-w-[10rem] text-neutral-700 text-sm font-medium dark:text-neutral-100">
                          Email
                        </div>
                        <Input
                          className="w-full py-2.5 px-3.5 text-neutral-500 dark:text-neutral-200"
                          readOnly
                          value={user?.email}
                          disabled
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="flex flex-col gap-y-2">
                      <div className="flex max-md:flex-col gap-y-1.5 gap-x-8">
                        <div className="w-40 min-w-[10rem] text-neutral-700 text-sm font-medium dark:text-neutral-100">
                          API KEY
                        </div>
                        <Input
                          className="w-full py-2.5 px-3.5 text-neutral-500 cursor-copy dark:text-neutral-200"
                          onMouseDown={() =>
                            navigator.clipboard
                              .writeText(userData?.apiKey || '')
                              .then(() => showSuccessMessage('API Key Copied'))
                          }
                          readOnly
                          value={userData?.apiKey || ''}
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="flex justify-between items-center max-md:flex-1 max-md:mt-2">
                      <button
                        className="items-center flex gap-x-2 text-[#A732DC] fill-[#A732DC]"
                        onClick={handleLogout}
                        type="button"
                      >
                        <svg
                          className="w-5 h-5 text-[#A732DC] fill-[#A732DC] ml-0.5"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="#525252"
                        >
                          <path
                            d="M19.0469 10.7969L15.1094 14.7344C15.0046 14.8388 14.8803 14.9215 14.7436 14.9778C14.6069 15.0341 14.4604 15.0629 14.3125 15.0625C14.0143 15.0612 13.7283 14.9435 13.5156 14.7344C13.4107 14.6299 13.3275 14.5057 13.2707 14.3689C13.214 14.2322 13.1847 14.0856 13.1847 13.9375C13.1847 13.7894 13.214 13.6428 13.2707 13.5061C13.3275 13.3693 13.4107 13.2451 13.5156 13.1406L15.5312 11.125H7.75C7.45163 11.125 7.16548 11.0065 6.9545 10.7955C6.74353 10.5845 6.625 10.2984 6.625 10C6.625 9.70163 6.74353 9.41548 6.9545 9.2045C7.16548 8.99353 7.45163 8.875 7.75 8.875H15.5312L13.5156 6.85938C13.3043 6.64803 13.1855 6.36139 13.1855 6.0625C13.1855 5.76361 13.3043 5.47697 13.5156 5.26562C13.727 5.05428 14.0136 4.93555 14.3125 4.93555C14.6114 4.93555 14.898 5.05428 15.1094 5.26562L19.0469 9.20312C19.1518 9.30764 19.235 9.43183 19.2918 9.56858C19.3485 9.70533 19.3778 9.85193 19.3778 10C19.3778 10.1481 19.3485 10.2947 19.2918 10.4314C19.235 10.5682 19.1518 10.6924 19.0469 10.7969ZM7.75 17.125H2.875V2.875H7.75C8.04837 2.875 8.33452 2.75647 8.5455 2.5455C8.75647 2.33452 8.875 2.04837 8.875 1.75C8.875 1.45163 8.75647 1.16548 8.5455 0.954505C8.33452 0.743526 8.04837 0.625 7.75 0.625H2.5C2.00348 0.627464 1.528 0.825799 1.1769 1.1769C0.825799 1.528 0.627464 2.00348 0.625 2.5V17.5C0.627464 17.9965 0.825799 18.472 1.1769 18.8231C1.528 19.1742 2.00348 19.3725 2.5 19.375H7.75C8.04837 19.375 8.33452 19.2565 8.5455 19.0455C8.75647 18.8345 8.875 18.5484 8.875 18.25C8.875 17.9516 8.75647 17.6655 8.5455 17.4545C8.33452 17.2435 8.04837 17.125 7.75 17.125Z"
                            fill="currentValue"
                          />
                        </svg>
                        <div className="text-sm font-semibold">Log out</div>
                      </button>
                      <FilledButton
                        className="py-2.5 px-4 relative max-md:hidden"
                        disabled={!touched}
                        type="submit"
                      >
                        <span className={isSubmitting ? 'opacity-0' : ''}>
                          Update changes
                        </span>
                        {isSubmitting && (
                          <div className="absolute w-full h-full flex justify-center items-center">
                            <Loader size={20} color="white" />
                          </div>
                        )}
                      </FilledButton>
                    </div>
                  </div>
                  <Divider className="md:hidden" />
                  <div className="m-6 md:hidden">
                    <FilledButton
                      className="w-full py-2.5 px-4 relative"
                      disabled={!touched}
                      type="submit"
                    >
                      <span className={isSubmitting ? 'opacity-0' : ''}>
                        Update changes
                      </span>
                      {isSubmitting && (
                        <div className="absolute w-full h-full flex justify-center items-center">
                          <Loader size={20} />
                        </div>
                      )}
                    </FilledButton>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ProfileModal;
