import React, { useState, useCallback, useEffect } from 'react';
import SideBar from './components/SideBar';
import Step0 from './steps/Step0';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { useUploadFile } from './hooks/useUploadFile';
import { useAuth } from '../../../../hooks/useAuth';
import { CustomPhotoFileData, CustomPhotoDetails } from './types/types';
import { useNotificationStore } from '../../../../stores/useNotificationStore';

type CustomTalkingPhotoFlowProps = {
  onClose: () => void;
  imgUrl?: string | null;
};

const initialFileData: CustomPhotoFileData = {
  name: '',
  size: '',
  data: null
};

const initialPhotoDetails: CustomPhotoDetails = {
  memberstackId: '',
  inputImageUrl: '',
  ziggeoConsentId: '',
  gender: null,
  name: '',
  age: null
};

const CustomTalkingPhotoFlow: React.FC<CustomTalkingPhotoFlowProps> = ({
  onClose,
  imgUrl
}) => {
  const { user } = useAuth();
  const showError = useNotificationStore((state) => state.showError);
  const memberstack = localStorage.getItem('memberstack');
  const parsed = memberstack ? JSON.parse(memberstack) : null;
  const [currentStep, setCurrentStep] = useState(0);
  const [uploadedPhotoDetails, setUploadedPhotoDetails] =
    useState<CustomPhotoDetails>({
      ...initialPhotoDetails,
      memberstackId: parsed.information?.id ?? user?.id
    });
  const { uploadFileWithProgress, detectFace } = useUploadFile();
  const [hasUploadStarted, setHasUploadStarted] = useState(false);
  const [fileData, setFileData] = useState(initialFileData);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isFaceChecking, setIsFaceChecking] = useState(false);
  const handleUpdatePhotoDetails = useCallback((values: CustomPhotoDetails) => {
    setUploadedPhotoDetails(values);
  }, []);

  const [imageSize, setImageSize] = useState({ width: null, height: null });

  const startUpload = async (file: File) => {
    setHasUploadStarted(true);
    setFileData({ name: file.name, size: (file.size / 1024).toFixed() });
    const data = await uploadFileWithProgress(file, setUploadProgress);
    setFileData((prevState) => ({ ...prevState, data }));
    setUploadedPhotoDetails((prevState) => ({
      ...prevState,
      inputImageUrl: data.mediaLink
    }));
  };

  const handlePhotoUpload = useCallback(
    async (file: File) => {
      if (file.size > 5 * 1024 * 1024)
        return showError('Talking photo size must be less than 5mb.');
      setIsFaceChecking(true);
      const data = await detectFace(file);
      if (!data) {
        setIsFaceChecking(false);
        return;
      }
      if (!data.isValid || (data.isValid && data.count > 1)) {
        showError(
          'Please try another photo. No face recognized or there are too many people'
        );
        setIsFaceChecking(false);
        return;
      }
      setIsFaceChecking(false);

      // Check photo min size
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        const img = new Image();
        // @ts-ignore
        img.src = e.target?.result;
        img.onload = function () {
          // @ts-ignore
          const height = this.height;
          // @ts-ignore
          const width = this.width;
          if (height < 256 || width < 256) {
            handleDeletePhoto();
            showError('Please upload a photo min 256x256 pixels');
          } else {
            startUpload(file);
          }
        };
      };
    },
    [setFileData, setHasUploadStarted]
  );

  const handleDeletePhoto = useCallback(() => {
    setFileData(initialFileData);
    setHasUploadStarted(false);
    setUploadedPhotoDetails(initialPhotoDetails);
  }, [setFileData, setHasUploadStarted, setUploadedPhotoDetails]);

  useEffect(() => {
    if (imgUrl) {
      setUploadedPhotoDetails((prevState) => ({
        ...prevState,
        inputImageUrl: imgUrl
      }));
      setCurrentStep(2);
    }
  }, []);

  const renderSteps = (currentStep: number) => {
    switch (currentStep) {
      case 0:
        return <Step0 onSetStep={setCurrentStep} />;
      case 1:
        return (
          <Step1
            onSetStep={setCurrentStep}
            uploadProgress={uploadProgress}
            uploadedPhoto={fileData.data?.mediaLink}
            hasUploadStarted={hasUploadStarted}
            onDeleteUploaded={handleDeletePhoto}
            onFileUpload={handlePhotoUpload}
            fileData={fileData}
            isFaceChecking={isFaceChecking}
          />
        );
      case 2:
        return (
          <Step2
            onSetStep={setCurrentStep}
            uploadedPhoto={fileData.data?.mediaLink || imgUrl}
            uploadedPhotoDetails={uploadedPhotoDetails}
            onUpdateDetails={handleUpdatePhotoDetails}
            hideBackButton={Boolean(imgUrl)}
          />
        );
      // case 3:
      //   return (
      //     <Step3
      //       onSetStep={setCurrentStep}
      //       onVideoUpload={handleUpdatePhotoDetails}
      //       uploadedPhotoDetails={uploadedPhotoDetails}
      //     />
      //   );
      case 3:
        return (
          <Step4
            onSetStep={setCurrentStep}
            uploadedPhoto={fileData.data?.mediaLink || imgUrl}
            uploadedPhotoDetails={uploadedPhotoDetails}
            onCloseModal={onClose}
          />
        );
    }
  };

  return (
    <div className="relative w-full md:flex md:min-h-[500px] md:overflow-hidden dark">
      <svg
        onClick={onClose}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-4 h-4 absolute top-4 right-4 md:top-6 md:right-6 cursor-pointer z-10 text-neutral-400 md:text-black dark:text-white"
      >
        <path
          d="M1.60938 14.846L14.453 1.8418M1.60938 1.8418L14.453 14.846"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <SideBar currentStep={currentStep} showSupportButton />

      <div className="sm:relative pt-4 sm:pt-8 pl-4 md:p-6 pr-6 pb-32 sm:pb-20 w-full overflow-y-auto max-h-[80vh] sm:max-h-none dark:bg-dark-primary">
        {renderSteps(currentStep)}
      </div>
    </div>
  );
};

export default CustomTalkingPhotoFlow;
