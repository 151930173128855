import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { chatService } from '../services/chat.service';
import {
  IDefaultMessage,
  IMessage,
  IMessageResponse,
  ISocketMessage,
  ISocketResponse,
  Role
} from '../types/types';
import { useAuth } from '../hooks/useAuth';
import { useLocation, useNavigate } from 'react-router';
import useAvatarStore from '../stores/useAvatarStore';
import useVoiceStore from '../stores/useVoiceStore';
import { AxiosError } from 'axios';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useLoadVideos } from '../hooks/useLoadVideos';
import { v4 as uuid } from 'uuid';
import { useSearchParams } from 'react-router-dom';
import { useNotificationStore } from '../stores/useNotificationStore';

type ChatContextValue = {
  chat: IMessage[];
  channelARN: string;
  sendMessage: (text: string) => Promise<void>;
  sendDefaultMessage: (defaultMessage: IDefaultMessage) => Promise<void>;
  avatarVideos: string[] | null;
  setAvatarVideos: Dispatch<SetStateAction<string[] | null>>;
  createNewChat: () => void;
  onVideoLoaded: (message: IMessage | null) => void;
  assistantMessage: IMessage | null;
  isLoading: boolean;
  defaultMessages: IDefaultMessage[] | null;
  sendJsonMessage: (jsonMessage: any, keep?: boolean | undefined) => void; // TODO: Change any
  connectionStatus: ReadyState;
  message: string;
  onMessageChange: (message: string) => void;
  handleVideoEnd: () => void;
};

export const ChatContext = createContext({} as ChatContextValue);

const socketsUrls = {
  '/stream': import.meta.env.VITE_YEPIC_WS_BACKEND as string,
  '/stream-test': import.meta.env.VITE_YEPIC_WS_TEST as string,
  '/stream-chunk': import.meta.env.VITE_YEPIC_WS_CHUNK as string
};

const ChatProvider = ({ children }: PropsWithChildren) => {
  const [channelName, setChannelName] = useState<string>('');
  const [channelARN, setChannelARN] = useState<string>('');
  const createChannelPromise = useRef<null | ReturnType<
    typeof chatService.createChannel
  >>(null);
  const [message, setMessage] = useState('');
  const { isAuth, user, verify, getCredits, setNoCreditsModal, setAuthModal } =
    useAuth();
  const showError = useNotificationStore((state) => state.showError);
  const [chat, setChat] = useState<IMessage[]>([]);
  const [chatId, setChatId] = useState<string | null>(null);
  const selectedVoice = useVoiceStore((state) => state.selectedVoice);
  const [avatarVideos, setAvatarVideos] = useState<string[] | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [assistantMessage, setAssistantMessage] = useState<IMessage | null>(
    null
  );
  const [defaultMessages, setDefaultMessages] = useState<
    IDefaultMessage[] | null
  >(null);
  const [unansweredMessage, setUnansweredMessage] = useState<string | null>();
  const activeAvatar = useAvatarStore((state) => state.activeAvatar);
  const { pathname } = useLocation();
  const socketUrl = useMemo(
    () =>
      pathname in socketsUrls
        ? socketsUrls[pathname as keyof typeof socketsUrls]
        : socketsUrls['/stream'],
    [pathname]
  );

  const [isUnauthorizedMessage, setIsUnauthorizedMessage] = useState(false);
  // * Websocket

  const { sendJsonMessage, lastJsonMessage, readyState } =
    useWebSocket<ISocketResponse>(socketUrl, {
      shouldReconnect: () => true
    });

  const generateChannelName = async () => {
    const newChannelName = uuid();
    setChannelName('');
    setChannelARN('');
    createChannelPromise.current = chatService.createChannel(newChannelName);
    const channelARN = await createChannelPromise.current.then(
      (res) => res.data?.channelARN
    );
    setChannelName(newChannelName);
    setChannelARN(channelARN);
    if (channelName) await chatService.deleteChannel(channelName).catch();
  };

  useEffect(() => {
    if (lastJsonMessage !== null) {
      console.log(lastJsonMessage);
      updateChat(true, lastJsonMessage);
    }
  }, [lastJsonMessage]);

  const getChatHistory = async (chatId: string, greeting?: IMessage) => {
    setChat([]);
    try {
      const chat = await chatService.getHistory(chatId as string);
      const messages = greeting ? [...chat, greeting] : chat;
      Array.isArray(chat) && setChat(messages);
    } catch (error) {
      showError((error as Error).message);
    }
  };

  const getLastChat = () => {
    if (user) {
      return chatService.get().then(async (chatsIds) => {
        let id = chatsIds[chatsIds.length - 1];
        const welcomeBackMessage: IMessage = {
          role: Role.ASSISTANT,
          content: `Welcome ${id ? 'back ' : ' '}${user.firstName ?? ''}`,
          id: 'welcome-back-msg-id'
        };
        if (!id) {
          id = await chatService.create();
        }
        setChatId(id);
        await getChatHistory(id, welcomeBackMessage);
        return id;
      });
    }
  };
  const isWebRTC = /^\/stream((-test)|(-chunk))?$/gm.test(pathname);

  const { Videos, loadVideos } = useLoadVideos();
  const updateChat = async (
    isWebRTC: boolean,
    response: IMessageResponse | ISocketResponse
  ) => {
    if (isWebRTC) {
      const { message, userMessageId } = response as ISocketResponse;

      setAssistantMessage(message);
      setChat((messages) => {
        if (messages && messages.length)
          messages[messages.length - 1].id = userMessageId;
        return [...messages];
      });
      return;
    }

    const { message, urls, userMessageId } = response as IMessageResponse;
    setAssistantMessage(message);
    setChat((messages) => {
      messages[messages.length - 1].id = userMessageId;
      return [...messages];
    });
    if (!Array.isArray(urls) || !urls.length || urls[0] === '') {
      onVideoLoaded(message);
      handleVideoEnd();
      return;
    }
    await loadVideos(urls.slice(0, 4));
    setAvatarVideos(urls);
  };

  const sendMessage = async (text: string, currentChatId = chatId) => {
    setLoading(true);
    try {
      setMessage('');
      setChat((messages) => [
        ...(messages ?? []),
        {
          content: text,
          chatId: currentChatId as string,
          role: Role.USER,
          id: user?.id ?? Date.now().toString()
        }
      ]);
      if (!user) {
        setUnansweredMessage(text);

        const pleaseLoginMessage = defaultMessages?.find((msg) =>
          msg.tags.includes('unauthorized-send-message')
        );

        if (!pleaseLoginMessage) return;

        const response = await chatService.useDefaultMessage(
          currentChatId,
          pleaseLoginMessage?._id,
          Boolean(user),
          activeAvatar?.id,
          selectedVoice?.voiceId,
          activeAvatar?.name
        );
        setIsUnauthorizedMessage(true);
        await updateChat(false, response);
      } else {
        if (isWebRTC) {
          if (!channelName) {
            if (createChannelPromise.current instanceof Promise) {
              await createChannelPromise.current;
            } else {
              await generateChannelName();
            }
          }

          sendJsonMessage({
            chatId: currentChatId,
            message: text,
            avatarId: activeAvatar?.id,
            voiceId: selectedVoice?.voiceId,
            language: selectedVoice?.language,
            channelId: channelName
          } as ISocketMessage);

          setAvatarVideos([]);
          return;
        }

        const response = await chatService.sendMessage(
          currentChatId,
          text,
          activeAvatar?.id,
          selectedVoice?.voiceId,
          selectedVoice?.language
        );

        setTimeout(() => {
          getCredits();
        }, 5000);

        await updateChat(isWebRTC, response);
        onVideoLoaded();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error && error instanceof AxiosError) {
        if (error.response && error.response.status === 400) {
          showError(
            'Your credit balance is insufficient. Please add credits or upgrade your plan'
          );
          setTimeout(() => {
            setNoCreditsModal(true);
          }, 1000);
        }
      } else {
        showError((error as Error).message);
      }
    }
  };

  const sendDefaultMessage = async (defaultMessage: IDefaultMessage) => {
    setLoading(true);

    try {
      setChat((messages) => [
        ...messages,
        {
          content: defaultMessage.question,
          chatId: chatId as string,
          role: Role.USER,
          id: user?.id ?? `${messages.length}`
        }
      ]);
      const response = await chatService.useDefaultMessage(
        chatId,
        defaultMessage._id,
        Boolean(user),
        activeAvatar?.id,
        selectedVoice?.voiceId,
        activeAvatar?.name
      );
      await updateChat(false, response);
      onVideoLoaded();
    } catch (error) {
      setLoading(false);
      showError((error as Error).message);
    }
  };

  const createNewChat = () => {
    chatService.create().then(async (chatsIds) => {
      setChatId(chatsIds);
      const welcomeMessage: IMessage = {
        role: Role.ASSISTANT,
        content: `Welcome ${user?.firstName}`,
        id: 'welcome-new-chat-msg-id'
      };
      await getChatHistory(chatsIds, welcomeMessage);
    });
  };

  const onVideoLoaded = (message: IMessage | null = null) => {
    if (message) {
      setChat((messages) => [...messages, message]);
    }

    setAssistantMessage((assistantMessage) => {
      if (assistantMessage && !message)
        setChat((messages) => [...messages, assistantMessage]);

      return null;
    });
    setLoading(false);
  };

  const handleVideoEnd = () => {
    setIsUnauthorizedMessage((isUnauthorizedMessage) => {
      if (isUnauthorizedMessage) setAuthModal((prev) => prev || 'register');
      setAvatarVideos(null);
      return false;
    });
  };

  useEffect(() => {
    if (user) {
      getLastChat()?.then((id) => {
        if (unansweredMessage && user && id) {
          sendMessage(unansweredMessage, id);
          setUnansweredMessage(null);
        }
      });
    } else {
      const greeting: IMessage = {
        role: Role.ASSISTANT,
        content: `Hi, my name is ${
          activeAvatar?.name ?? 'Agent 7'
        }, how can I help you today? Ask by typing or recording your message`,
        id: 'greeting-msg-id'
      };
      setChat([greeting]);
    }
  }, [user?.id]);

  useEffect(() => {
    if (user && isWebRTC) {
      // TODO remove on commit
      generateChannelName();
    }
  }, [user?.id]);

  useEffect(() => {
    chatService.getDefaultMessages().then((messages) =>
      setDefaultMessages(
        // TODO: implement order property for default messages
        messages
          ? [...messages].sort((a, b) => (a.question > b.question ? 1 : -1))
          : null
      )
    );
  }, []);

  const [params] = useSearchParams();
  const nav = useNavigate();
  useEffect(() => {
    if ((isAuth && user) || !isAuth) {
      const verifyRegex = /\/email-verify(\/)?/;
      if (verifyRegex.test(pathname)) {
        const token = params.get('token');
        if (token)
          verify(token).finally(() => {
            nav('/', { replace: true });
          });
      }
    }
  }, [isAuth, user, pathname, params]);

  return (
    <ChatContext.Provider
      value={{
        chat,
        sendMessage,
        avatarVideos,
        setAvatarVideos,
        createNewChat,
        onVideoLoaded,
        assistantMessage,
        isLoading,
        defaultMessages,
        sendDefaultMessage,
        sendJsonMessage,
        connectionStatus: readyState,
        message,
        onMessageChange: setMessage,
        channelARN,
        handleVideoEnd
      }}
    >
      <Videos />
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
