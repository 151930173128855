import React from 'react';
import { BsTwitter } from 'react-icons/bs';

interface Props {
  children: React.ReactNode;
}

const ShareViaSMButton = ({ children }: Props) => {
  return (
    <div className=" w-11 aspect-square rounded-lg border border-neutral-300 flex justify-center items-center text-gray-400 hover:bg-neutral-50">
      {children}
    </div>
  );
};

export default ShareViaSMButton;
