import { useEffect, useRef } from 'react';
import { useChat } from '../../hooks/useChat';
import { IMessage, Role } from '../../types/types';
import Message from './Message';
import clsx from 'clsx';

type ChatProps = {
  isChatbot: boolean;
};

const Chat = ({ isChatbot }: ChatProps) => {
  const { chat, isLoading } = useChat();
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    divRef.current?.scroll({
      top: divRef.current?.scrollHeight
    });
  }, [chat]);

  return (
    <div
      className={clsx(
        'h-full gap-y-4 no-scrollbar flex flex-col overflow-y-auto grow z-10',
        isChatbot ? 'bg-white dark:bg-dark-primary' : 'w-full py-4 '
      )}
      ref={divRef}
    >
      {chat &&
        chat.length > 0 &&
        chat.map((msg: IMessage, idx: number) => (
          <Message
            key={msg.id ?? `${msg.content.substring(0, 10)}${idx}`}
            {...msg}
            isChatbot={isChatbot}
          />
        ))}
      {isLoading && chat.length > 0 && (
        <Message
          id="gggg"
          chatId={chat[0].chatId}
          role={Role.ASSISTANT}
          content="..."
          isLoading
          isChatbot={isChatbot}
        />
      )}
    </div>
  );
};

export default Chat;
