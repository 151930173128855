import React from 'react';
import { Menu, Transition } from '@headlessui/react';

type DropdownProps = {
  options?: any[];
  children?: React.ReactNode;
  label: string;
  icon: React.ReactNode;
  buttonClassName?: string;
};

const Dropdown: React.FC<DropdownProps> = ({
  options,
  children,
  label,
  icon,
  buttonClassName = ''
}) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button
        className={`${buttonClassName} flex items-center gap-2 justify-center border border-gray-300 rounded-lg py-2.5 px-4 w-full`}
      >
        <span className="text-sm font-inter font-semibold dark:text-white">
          {label}
        </span>
        {icon}
      </Menu.Button>

      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute right-0 z-10 mt-0.5 w-full rounded-md bg-white shadow-lg border border-neutral-200 dark:bg-neutral-800 dark:border-neutral-700 dark:text-whites">
          {options?.map((option, index) => (
            <Menu.Item key={index}>{option}</Menu.Item>
          ))}
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
