import { DetailedHTMLProps, FC, useState, VideoHTMLAttributes } from 'react';

export const VideoWithFallback: FC<
  Omit<
    DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>,
    'onError'
  > & { fallbackElement: FC<{ resetError: () => void }> }
> = ({ fallbackElement: Fallback, ...props }) => {
  const [hasError, setHasError] = useState(false);
  return hasError ? (
    <Fallback resetError={() => setHasError(false)} />
  ) : (
    <video
      {...props}
      onError={() => {
        setHasError(true);
      }}
    />
  );
};
