import React, { useState } from 'react';
import useAvatarStore from '../../../stores/useAvatarStore';
import FilledButton from '../../shared/Buttons/FilledButton';
import AvatarItem from './AvatarsItem';
import { useAuth } from '../../../hooks/useAuth';
import CustomTalkingPhotoFlow from './CustomTalkingPhotoFlow/CustomTalkingPhotoFlow';
import Modal from '../../shared/Modal';
import CustomTalkingPhotoItem from './CustomTalkingPhotoFlow/components/CustomTalkingPhotoItem';
import { GenderOptions } from './CustomTalkingPhotoFlow/types/types';
import Checkbox from '../../shared/Checkbox';
import { AvatarOptions, IAvatar } from '../../../types/types';
import Dropdown from '../../shared/Dropdown';
import { BsFilter } from 'react-icons/bs';
import Divider from '../../Divider';
import CustomTalkingPhotoInfo from './CustomTalkingPhotoFlow/components/CustomTalkingPhotoInfo';

const AvatarsList = () => {
  const { user, setAuthModal } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [activePhoto, setActivePhoto] = useState<IAvatar | null>(null);
  const [typeFilters, setTypeFilters] = useState<AvatarOptions[]>([]);

  const {
    activeAvatar,
    selectAvatar,
    filteredAvatars,
    filteredTalkingPhotos,
    filteredCustomTalkingPhotos,
    genderFilters,
    setGenderFilters,
    resetGenders
  } = useAvatarStore();

  const openInfoModal = (photo: IAvatar) => {
    setActivePhoto(photo);
    setIsInfoOpen(true);
  };

  const handleSetTypeFilter = (type: AvatarOptions) => {
    const newFilter = [...typeFilters];
    if (newFilter.includes(type)) {
      newFilter.splice(newFilter.indexOf(type), 1);
    } else {
      newFilter.push(type);
    }
    setTypeFilters(newFilter);
  };

  return (
    <>
      <div className="px-4">
        <FilledButton
          className="w-full py-2.5 font-semibold"
          onClick={() => (user ? setIsModalOpen(true) : setAuthModal('login'))}
        >
          Upload talking photo
        </FilledButton>
        <div className="border-t border-neutral-200 my-6 darl:border-neutral-700" />
      </div>
      <div className="mx-4 z-20">
        <Dropdown label="Filters" icon={<BsFilter className="w-5 h-5" />}>
          <div className="pt-5 pb-4">
            <div className="pl-3.5 pr-4 flex justify-between w-full items-baseline">
              <span className="text-sm font-inter font-semibold dark:text-white">
                Filters
              </span>
              <button
                className={`text-sm font-inter font-semibold ${
                  typeFilters.length || genderFilters.length
                    ? 'text-secondary'
                    : 'text-gray-300 pointer-events-none'
                }`}
                onClick={() => {
                  setTypeFilters([]);
                  resetGenders();
                }}
              >
                Reset
              </button>
            </div>
            <div className="flex flex-col items-start gap-4 justify-evenly mt-5 pl-3.5 pr-4">
              <Checkbox
                label="Talking Photo"
                onClick={(value) => handleSetTypeFilter(value as AvatarOptions)}
                choosenValues={typeFilters}
                value="talkingPhoto"
              />
              <Checkbox
                label="Avatar"
                onClick={(value) => handleSetTypeFilter(value as AvatarOptions)}
                choosenValues={typeFilters}
                value="avatar"
              />
              <Checkbox
                label="Custom"
                onClick={(value) => handleSetTypeFilter(value as AvatarOptions)}
                choosenValues={typeFilters}
                value="custom"
              />
            </div>
            <Divider className="w-full my-2" />
            <div className="flex flex-col items-start gap-4 justify-evenly pl-3.5 pr-4">
              <Checkbox
                label="Male"
                onClick={(value) => setGenderFilters(value as GenderOptions)}
                choosenValues={genderFilters}
                value="male"
              />
              <Checkbox
                label="Female"
                onClick={(value) => setGenderFilters(value as GenderOptions)}
                choosenValues={genderFilters}
                value="female"
              />
            </div>
          </div>
        </Dropdown>
      </div>
      <div className="px-4 overflow-auto no-scrollbar">
        <div className="overflow-auto">
          <div className="overflow-y-auto">
            {filteredCustomTalkingPhotos &&
              filteredCustomTalkingPhotos?.length > 0 &&
              (!typeFilters.length || typeFilters.includes('custom')) && (
                <div className="my-6">
                  <h3 className="text-sm text-black font-semibold mb-2 dark:text-white">
                    Custom{' '}
                  </h3>
                  <div className="grid grid-cols-2 gap-2 font-inter">
                    {filteredCustomTalkingPhotos?.map((avatar, idx) => (
                      <AvatarItem
                        key={avatar.id}
                        onChooseAvatar={(avatar) => selectAvatar(avatar)}
                        isActive={activeAvatar?.id === avatar.id}
                        avatar={avatar}
                        openModal={() => openInfoModal(avatar)}
                      />
                    ))}
                  </div>
                </div>
              )}
            {filteredTalkingPhotos &&
              filteredTalkingPhotos?.length > 0 &&
              (!typeFilters.length || typeFilters.includes('talkingPhoto')) && (
                <div className="my-6">
                  <h3 className="text-sm text-black font-semibold mb-2 dark:text-white">
                    Talking photos{' '}
                  </h3>
                  <div className="grid grid-cols-2 gap-2 font-inter">
                    {filteredTalkingPhotos?.map((avatar, inx) => (
                      <AvatarItem
                        key={inx}
                        avatar={avatar}
                        onChooseAvatar={(avatar) => selectAvatar(avatar)}
                        isActive={activeAvatar?.id === avatar.id}
                      />
                    ))}
                  </div>
                </div>
              )}
            {/* {filteredAvatars &&
              filteredAvatars?.length > 0 &&
              (!typeFilters.length || typeFilters.includes('avatar')) && (
                <div className="my-6">
                  <h3 className="text-sm text-black font-semibold mb-2">
                    Avatars{' '}
                  </h3>
                  <div className="grid grid-cols-2 gap-2 font-inter">
                    {filteredAvatars?.map((avatar, inx) => (
                      <AvatarItem
                        key={inx}
                        avatar={avatar}
                        onChooseAvatar={(avatar) => selectAvatar(avatar)}
                        isActive={activeAvatar?.id === avatar.id}
                      />
                    ))}
                  </div>
                </div>
              )} */}
          </div>
        </div>
        {isModalOpen && (
          <Modal open={isModalOpen} setOpen={setIsModalOpen} noPadding>
            <CustomTalkingPhotoFlow onClose={() => setIsModalOpen(false)} />
          </Modal>
        )}
        {isInfoOpen && activePhoto && (
          <Modal open={isInfoOpen} setOpen={setIsInfoOpen} noPadding>
            <CustomTalkingPhotoInfo
              avatar={activePhoto}
              onClose={() => setIsInfoOpen(false)}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default AvatarsList;
