import type { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { IMessage, Role } from '../../types/types';
import { ThreeDots } from 'react-loader-spinner';
import './message.css';
import { useChat } from '../../hooks/useChat';
import { BsFillStopFill } from 'react-icons/bs';
import useAvatarStore from '../../stores/useAvatarStore';
import clsx from 'clsx';

type MessageProps = IMessage & {
  isChatbot: boolean;
};

const Message = ({
  content,
  role,
  isLoading,
  // url,
  urls,
  isChatbot
}: MessageProps) => {
  const { setAvatarVideos, handleVideoEnd, avatarVideos } = useChat();
  const activeAvatar = useAvatarStore((state) => state.activeAvatar);

  const handlePlay = () => {
    if (urls && urls.length) {
      setAvatarVideos(urls);
    }
  };

  const hasUrl = Array.isArray(urls) && urls.length > 0;

  return (
    <div
      className={clsx(
        'w-full flex px-6',
        role === Role.USER && 'justify-end',
        ''
      )}
    >
      <div
        className={clsx(
          isChatbot
            ? 'w-full'
            : 'max-sm:w-full sm:min-w-[442px] sm:max-w-[560px]'
        )}
      >
        <div className={clsx(role === Role.USER && 'text-end')}>
          <span className="text-sm dark:text-white">
            {role === Role.USER ? 'You' : activeAvatar?.name ?? 'Lyanna'}
          </span>
        </div>
        <div
          className={clsx(
            'animate-msg mt-1.5 py-2.5 px-3.5 flex justify-between rounded-lg relative ',
            isChatbot ? 'pr-4' : 'pr-7',
            role === Role.USER
              ? 'bg-secondary text-white rounded-tr-none'
              : 'bg-neutral-100 rounded-tl-none dark:bg-neutral-800 dark:text-neutral-100',
            role === Role.USER
              ? isChatbot
                ? ''
                : 'ml-8 sm:ml-9'
              : isChatbot
              ? ''
              : 'mr-8 sm:mr-9 '
          )}
        >
          {role === Role.USER ? (
            content
          ) : isLoading ? (
            <ThreeDots
              height="20"
              width="20"
              radius="2"
              color="#fff"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              visible={true}
            />
          ) : (
            <div>
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
          )}
          {hasUrl && (
            <button
              className={clsx(
                'flex flex-col justify-center items-center opacity-0 transition-all absolute top-0 h-full right-2'
              )}
              onClick={() => {
                if (avatarVideos && avatarVideos?.length > 0) {
                  handleVideoEnd();
                } else {
                  handlePlay();
                }
              }}
            >
              {avatarVideos && avatarVideos?.length > 0 ? (
                <BsFillStopFill className="w-4 h-4 text-secondary" />
              ) : (
                <svg
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.525 13.0248C1.19167 13.2415 0.854333 13.2542 0.513 13.0628C0.171666 12.8715 0.000666667 12.5755 0 12.1748V1.82485C0 1.42485 0.171 1.12885 0.513 0.936846C0.855 0.744846 1.19233 0.757512 1.525 0.974846L9.675 6.14985C9.975 6.34985 10.125 6.63318 10.125 6.99985C10.125 7.36651 9.975 7.64985 9.675 7.84985L1.525 13.0248Z"
                    fill={role === Role.USER ? 'white' : '#C34DFF'}
                  />
                </svg>
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
