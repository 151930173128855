import type { FC } from 'react';

const Divider: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={`border-b border-neutral-200 dark:border-neutral-700 ${
        className ?? ''
      }`}
    />
  );
};

export default Divider;
