import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';
import { MdOutlineClose } from 'react-icons/md';
import FormError from '../FormError/FormError';
import FilledButton from '../../shared/Buttons/FilledButton';
import { useFormik } from 'formik';
import Loader from '../../Loader';
import clsx from 'clsx';
import OutlinedButton from '../../shared/Buttons/OutlinedButton';
import PriceRadioBtn from './PriceRadioBtn';
import { IIntegrateFormValues } from '../../../types/types';
import integrationService from '../../../services/integration.service';
import { AxiosError } from 'axios';
import { useNotificationStore } from '../../../stores/useNotificationStore';

export const prices = ['30-50$', '50-100$', '100-300$', '300-1000$'] as const;

type IntegrateFormProps = {
  onClose: () => void;
};

const IntegrateForm = ({ onClose }: IntegrateFormProps) => {
  const { showSuccessMessage, showError } = useNotificationStore();
  const formik = useFormik<IIntegrateFormValues>({
    initialValues: {
      integration: '',
      usage: '',
      price: '30-50$'
    },
    validationSchema: Yup.object({
      integration: Yup.string().required('Please enter required integration'),
      usage: Yup.string().required('Please enter usage')
    }),
    onSubmit: async (values) => await handleSubmit(values)
  });

  const handleSubmit = async (values: IIntegrateFormValues) => {
    try {
      const data = await integrationService.postIntegrationRequest(values);
      if (data.status === 201) {
        showSuccessMessage('Integration request sent successfully');
        onClose();
      }
    } catch (error) {
      if (error instanceof Error) {
        if (error instanceof AxiosError && error.response) {
          if (error.response.status === 400) {
            showError(error.response?.data.detail);
            onClose();
          } else {
            showError(
              `There was an error when we tried to sent your request. Error message: ${error.message}`
            );
          }
        } else {
          showError(
            `There was an error when we tried to sent your request. Error message: ${error.message}`
          );
        }
      }
    }
  };

  return (
    <Dialog.Panel className="relative transform md:rounded-lg bg-white text-left shadow-xl transition-all w-screen md:w-[502px]">
      <form
        className="bg-white md:rounded-xl w-full flex max-md:min-h-screen flex-col md:gap-5 z-20 h-full px-6 pb-4 pt-6 md:pt-5 md:my-8 md:p-6"
        onSubmit={formik.handleSubmit}
      >
        <button
          className="max-md:hidden w-4 h-4 bg-no-repeat bg-cover absolute right-5 top-4"
          onClick={onClose}
          type="button"
        >
          <MdOutlineClose className="text-neutral-700 text-xl" />
        </button>
        <div className="max-md:mb-5 ">
          <h2 className=" text-lg font-medium text-neutral-900 leading-7">
            Integrate
          </h2>
          <p className="text-neutral-600 text-sm font-light">
            Tell us how you would like to use video chat
          </p>
        </div>
        <div className="max-md:mb-8 flex flex-col gap-4 md:gap-3 text-sm">
          <div className="flex flex-col text-sm">
            <label className="mb-1.5 text-neutral-700" htmlFor="integration">
              Integration required*
            </label>

            <input
              className={clsx(
                'border border-neutral-300 py-2 px-3 rounded-md',
                formik.errors.integration && formik.touched.integration
                  ? 'ring-red-300 placeholder:text-red-300 text-red-900 focus:outline-red-500  focus:border-red-500'
                  : 'text-gray-900 placeholder:text-gray-400 focus:outline-secondary'
              )}
              placeholder="e.g Intercom, Live person, Zapier "
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.integration}
              name="integration"
              id="integration"
            />
            {formik.errors.integration && formik.touched.integration && (
              <FormError>{formik.errors.integration}</FormError>
            )}
          </div>
          <div className="flex flex-col text-sm">
            <label className="mb-1.5 text-neutral-700" htmlFor="usage">
              How would you like to use Chat GPT-ME?*
            </label>
            <textarea
              className={clsx(
                'border border-neutral-300 py-1.5 px-3 h-32 rounded-md text-sm',
                formik.errors.usage && formik.touched.usage
                  ? 'ring-red-300 placeholder:text-red-300 text-red-900 focus:ring-red-500 focus:border-red-500'
                  : 'text-gray-900 placeholder:text-gray-400 focus:ring-secondary focus:border-secondary '
              )}
              placeholder="e.g. I would want to connect it with my intercom chatbot and bring a face to my customer experience. "
              name="usage"
              id="usage"
              rows={5}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.usage}
            />
            {formik.errors.usage && formik.touched.usage && (
              <FormError>{formik.errors.usage}</FormError>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2 text-sm font-semibold">
          {prices.map((price, index: number) => (
            <PriceRadioBtn
              key={price}
              id={`price+${price}`}
              value={price}
              onChange={formik.handleChange}
              isActive={formik.values.price === price}
            />
          ))}
        </div>
        <div className="grid grid-cols-2 gap-2">
          <OutlinedButton
            className="py-2 text-base h-11 grow"
            onClick={onClose}
          >
            Cancel
          </OutlinedButton>
          <FilledButton className="py-2 text-base h-11 grow" type="submit">
            {formik.isSubmitting ? (
              <Loader color="#fff" />
            ) : (
              'Request Integration'
            )}
          </FilledButton>
        </div>
      </form>
    </Dialog.Panel>
  );
};

export default IntegrateForm;
