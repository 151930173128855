import { useEffect, useRef, useState } from 'react';
import { VscChevronDown } from 'react-icons/vsc';

import { useAuth } from '../../../hooks/useAuth';
import useClickOutside from '../../../hooks/useClickOutside';
import { capitalize } from '../../../lib/utils';
import SelectInput from '../../shared/SelectInput';

import useVoiceStore from '../../../stores/useVoiceStore';
import useAvatarStore from '../../../stores/useAvatarStore';
import Loader from '../../Loader';
import Checkbox from '../../shared/Checkbox';
import Voice from './Voice';
import Dropdown from '../../shared/Dropdown';
import { BsFilter } from 'react-icons/bs';
import { GenderOptions } from '../../../types/types';

const VoicesPanel = () => {
  const {
    selectedVoice,
    voices,
    filteredVoices,
    setFilters,
    chooseVoice,
    providers,
    languages,
    loading,
    filters,
    voicesError,
    changeGender,
    genders,
    setGenders
  } = useVoiceStore();
  const activeAvatar = useAvatarStore((state) => state.activeAvatar);

  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [currentAudio, setCurrentAudio] = useState<any | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const langRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(langRef, setIsOpen);

  const handlePlayAudio = (audio: any) => {
    if (isPlaying) {
      handlePauseAudio();
    }
    setCurrentAudio(audio);
    setIsPlaying(true);
    audio.play();
  };

  const handlePauseAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0;
      currentAudio.removeEventListener('ended', () => setIsPlaying(false));
      setCurrentAudio(null);
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (currentAudio) {
      currentAudio.addEventListener('ended', () => setIsPlaying(false));
    }
  }, [currentAudio]);

  const handleClearFilters = () => {
    setGenders([]);
  };

  return voicesError ? (
    <div className="text-base text-neutral-500 w-full mx-auto p-3 text-center">
      {voicesError}
    </div>
  ) : (
    <div className="flex flex-col gap-4 px-4 overflow-auto pb-6 h-full">
      <div className="flex items-center relative flex-col">
        <div className="flex items-center relative w-full flex-col">
          <div className={`group relative text-xs mb-2 w-full`}>
            <div
              className="text-base border border-neutral-300 rounded-md text-neutral-500 focus-visible:outline-none disabled:bg-white disabled:opacity-50 text-md focus:outline-none shadow-sm  focus:ring-secondary focus:border-secondary cursor-pointer relative flex items-center justify-between w-full px-4 py-3 dark:border-neutral-700 dark:text-neutral-100"
              onClick={() => setIsOpen((prevState) => !prevState)}
              ref={langRef}
            >
              {filters?.language && (
                <span>{capitalize(filters?.language)}</span>
              )}
              <VscChevronDown
                className={`right-4 absolute${
                  isOpen && 'transform rotate-180'
                } `}
              />
              <div
                className={`absolute w-full max-h-[10rem] top-12 py-1 px-1 left-0 overflow-y-scroll bg-white z-50 border border-tertiary rounded-lg dark:bg-neutral-900 dark:border-neutral-700 ${
                  isOpen ? 'block' : 'hidden'
                }`}
              >
                {languages?.map((lang) => (
                  <div
                    onClick={() =>
                      setFilters({
                        ...filters,
                        language: lang.language
                      })
                    }
                    key={`languages-${lang.language}`}
                    className={`py-2 px-2 flex justify-between ${
                      lang.language === filters?.language &&
                      'bg-slate-200 rounded-lg dark:bg-neutral-800'
                    }`}
                  >
                    <span>
                      {lang.country?.emoji} {capitalize(lang.language)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {providers && (
            <SelectInput
              name="providers"
              options={providers}
              className="text-xs mb-2 w-full"
              value={filters?.provider ?? 'All providers'}
              onChange={(value: string) =>
                setFilters({
                  ...filters,
                  provider: value ?? 'All providers'
                })
              }
            />
          )}
        </div>
      </div>
      <div>
        <Dropdown label="Filters" icon={<BsFilter className="w-5 h-5" />}>
          <div className="pt-5 pl-3.5 pr-4 pb-4">
            <div className="flex justify-between w-full items-baseline">
              <span className="text-sm font-inter font-semibold dark:text-white">
                Filters
              </span>
              <button
                className={`text-sm font-inter font-semibold ${
                  genders.length
                    ? 'text-secondary'
                    : 'text-gray-300 pointer-events-none'
                }`}
                onClick={() => handleClearFilters()}
              >
                Reset
              </button>
            </div>
            <div className="flex flex-col items-start gap-4 justify-evenly mt-5">
              <Checkbox
                key="filter-male"
                label="Male"
                onClick={(value) => changeGender(value as GenderOptions)}
                choosenValues={genders}
                value="male"
              />
              <Checkbox
                key="filter-female"
                label="Female"
                onClick={(value) => changeGender(value as GenderOptions)}
                choosenValues={genders}
                value="female"
              />
            </div>
          </div>
        </Dropdown>
      </div>
      <div className="border-t border-neutral-200 my-2 dark:border-neutral-700" />
      <div className="h-full flex flex-col gap-y-2 overflow-auto no-scrollbar">
        {loading ? (
          <div className="flex justify-center">
            <Loader />
          </div>
        ) : filteredVoices?.length ? (
          filteredVoices.map((voice: any, inx: any) => (
            <Voice
              key={voice.voiceId}
              voice={voice}
              doesGenderMatch={
                activeAvatar?.gender?.toLowerCase() === voice.gender
              }
              onPause={handlePauseAudio}
              selected={selectedVoice?.voiceId === voice.voiceId}
              onPlay={handlePlayAudio}
              currentAudio={currentAudio}
              isPlaying={isPlaying}
              onHandleChange={chooseVoice}
            />
          ))
        ) : (
          <div className="text-base text-neutral-500 w-full mx-auto p-3 text-center">
            No voices found matching filters
          </div>
        )}
      </div>
    </div>
  );
};

export default VoicesPanel;
