import React from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import talkingPhotoDialogImage from '../images/talkPhotoDialogImg.png';
import logo from '../images/logo.png';

type SideBarProps = {
  currentStep: number | null;
  showSupportButton?: boolean;
};

const SideBar: React.FC<SideBarProps> = ({
  currentStep,
  showSupportButton = false
}) => {
  return (
    <div className="md:w-[300px] bg-dark-primary relative pt-4 sm:pl-3 rounded-tl-lg rounded-tr-lg md:rounded-tr-none md:rounded-bl-lg ">
      <div>
        <img
          src={logo}
          alt="logo"
          className="inline-block shrink-0 w-8 xl:w-12 mr-2"
        />
        <span className="text-xs text-gray-400">My Talking Photos</span>
      </div>
      {(currentStep || currentStep === 0) && (
        <div className="flex justify-center md:block text-xs md:text-base mt-4 md:mt-11 sm:ml-2 text-gray-400 pb-4 relative">
          <div
            className={`mb-1 md:mb-4 mr-3 md:mr-0 ${
              currentStep > 0 && 'text-secondary'
            }`}
          >
            {currentStep > 1 ? (
              <BsFillCheckCircleFill className="inline-block mr-1 md:mr-2 text-secondary" />
            ) : (
              <span className="mr-2 inline-block w-3">1.</span>
            )}
            Upload photo
          </div>
          <div
            className={`mb-1 md:mb-4 mr-3 md:mr-0 ${
              currentStep > 1 && 'text-secondary'
            }`}
          >
            {currentStep > 2 ? (
              <BsFillCheckCircleFill className="inline-block mr-1 md:mr-2 text-secondary" />
            ) : (
              <span className="mr-2 inline-block w-3">2.</span>
            )}
            Details
          </div>
          <div
            className={`mb-1 md:mb-4 mr-3 md:mr-0 ${
              currentStep > 2 && 'text-secondary'
            }`}
          >
            {currentStep > 3 ? (
              <BsFillCheckCircleFill className="inline-block mr-1 md:mr-2 text-secondary" />
            ) : (
              <span className="mr-2 inline-block w-3">3.</span>
            )}
            Review
          </div>
          {/* <div className={`${currentStep > 3 && 'text-secondary'}`}>
            <span className="mr-1 md:mr-2 inline-block w-3">4.</span> Review
          </div> */}
        </div>
      )}
      <img
        src={talkingPhotoDialogImage}
        alt="talking photo"
        className="hidden md:block absolute md:bottom-0 right-0 md:left-0"
      />
      {showSupportButton && (
        <button
          className="underline text-gray-400 absolute hidden sm:block bottom-5 right-3 md:bottom-3 md:right-1/2 text-sm opacity-80 hover:opacity-100"
          // @ts-ignore
          onClick={() => window.HubSpotConversations.widget.open()}
        >
          Need help?
        </button>
      )}
    </div>
  );
};

export default SideBar;
