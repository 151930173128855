import axios, { AxiosRequestConfig } from 'axios';

const chatHost = import.meta.env.VITE_YEPIC_CHAT_BACKEND;

const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token')
  };
};

export const instance = axios.create({
  baseURL: chatHost,
  timeout: 60000
});

const httpService = {
  get: async (url: string, headers: AxiosRequestConfig['headers'] = {}) => {
    return instance.get(url, {
      headers: {
        ...getHeaders(),
        ...headers
      }
    });
  },
  post: async (
    url: string,
    payload: any = {},
    headers: AxiosRequestConfig['headers'] = {}
  ) => {
    return instance.post(url, payload, {
      headers: {
        ...getHeaders(),
        ...headers
      }
    });
  },
  delete: async (url: string, headers: AxiosRequestConfig['headers'] = {}) => {
    return instance.delete(url, {
      headers: {
        ...getHeaders(),
        ...headers
      }
    });
  },
  patch: async (
    url: string,
    payload: any = {},
    headers: AxiosRequestConfig['headers'] = {}
  ) => {
    return instance.patch(url, payload, {
      headers: {
        ...getHeaders(),
        ...headers
      }
    });
  }
};

export default httpService;
