/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios';
import httpService from './http/http.service';
import { IUser, IUserData } from '../types/types';
import {
  IUserLoginReq,
  IUserRegisterReq,
  IUserRes
} from '../types/fetch-types';

const host = import.meta.env.VITE_YEPIC_AUTH_BACKEND;
const domain = import.meta.env.VITE_YEPIC_EMAIL_DOMAIN;

export const userToCamelCase = ({
  is_verified,
  company_name,
  last_name,
  first_name,
  email,
  id
}: IUserRes): IUser => ({
  isVerified: is_verified,
  companyName: company_name,
  lastName: last_name,
  firstName: first_name,
  email,
  id
});

export const authService = {
  register: async function (values: IUserRegisterReq): Promise<IUser> {
    return await axios
      .post(`${host}/auth/register`, values, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((res): IUserRes => res.data)
      .then(userToCamelCase);
  },
  registerFromMemberStack: (values: {
    email: string;
    password: string;
    memberstack_jwt: string;
  }) => axios.post(`${host}/accounts/`, values),
  login: async (values: IUserLoginReq) => {
    const data = {
      username: values.email,
      password: values.password
    };
    const res = await axios.post(`${host}/auth/jwt/login`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return res.data;
  },
  logout: async () => {
    const res = await httpService.post(`${host}/auth/jwt/logout`);
    return res.data;
  },
  requestVerify: (email: string) =>
    httpService.post(`${host}/auth/request-verify-token`, {
      email,
      domain
    }),
  verify: (token: string): Promise<AxiosResponse<IUserRes>> =>
    httpService.post(`${host}/auth/verify`, { token }),
  checkIfUserExist: async (email: string) => {
    return await httpService
      .get(`${host}/users/?email=${email}`)
      .then((res) => res.data);
  },
  registerMemberStack: async (values: IUserLoginReq): Promise<string> => {
    return await axios
      .post(`${host}/users/memberstack`, {
        email: values.email,
        password: values.password
      })
      .then((res) => res.data.member.id);
  }
};
