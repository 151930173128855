import React, { useEffect, useState } from 'react';
import { BsGenderFemale, BsGenderMale } from 'react-icons/bs';
import { FaStop } from 'react-icons/fa';
import { IVoice } from '../../../types/types';
import { useNotificationStore } from '../../../stores/useNotificationStore';

type VoiceProps = {
  voice: IVoice;
  onPlay: (audio: HTMLAudioElement) => void;
  onPause: () => void;
  isPlaying: boolean;
  currentAudio: any;
  selected: boolean;
  doesGenderMatch: boolean;
  onHandleChange: (voice: IVoice) => void;
};

const Voice: React.FC<VoiceProps> = ({
  voice,
  onPlay,
  onPause,
  isPlaying,
  currentAudio,
  selected,
  doesGenderMatch,
  onHandleChange
}) => {
  const showWarn = useNotificationStore((state) => state.showWarn);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (voice.audioSampleUrl) {
      setAudio(new Audio(voice.audioSampleUrl));
    }
  }, []);

  const handlePlay = () => {
    if (isPlaying && currentAudio === audio) {
      onPause();
      return;
    }
    if (audio) onPlay(audio);
  };

  const handlePlayNotification = () => {
    showWarn(
      "We're sorry, but at the moment voice preview is disabled due to technical problems."
    );
  };

  return (
    <div
      className={`flex justify-between p-1 py-3 w-full border ${
        selected
          ? 'border-secondary'
          : 'border-neutral-200 cursor-pointer dark:border-neutral-700'
      } text-neutral-600 flex items-center text-sm disabled:opacity-50 rounded-lg dark:text-neutral-300`}
      onClick={() => onHandleChange(voice)}
    >
      <div className="flex items-center space-x-2 w-full">
        <button
          className="inline-flex items-center justify-center rounded-lg pl-2.5"
          onClick={voice.audioSampleUrl ? handlePlay : handlePlayNotification}
        >
          {isPlaying && currentAudio === audio ? (
            <FaStop className="w-[11px] h-[14px] text-secondary" />
          ) : (
            <svg
              width="11"
              height="14"
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.525 13.0258C1.19167 13.2425 0.854333 13.2552 0.513 13.0638C0.171666 12.8725 0.000666667 12.5765 0 12.1758V1.82582C0 1.42582 0.171 1.12982 0.513 0.937822C0.855 0.745822 1.19233 0.758489 1.525 0.975822L9.675 6.15082C9.975 6.35082 10.125 6.63416 10.125 7.00082C10.125 7.36749 9.975 7.65082 9.675 7.85082L1.525 13.0258Z"
                fill="#C34DFF"
              />
            </svg>
          )}
        </button>
        <div
          title={
            doesGenderMatch
              ? ''
              : "This voice doesn't match chosen actor's gender"
          }
          className={`${doesGenderMatch ? '' : 'text-red-400'}`}
        >
          {voice?.gender === 'male' ? (
            <BsGenderMale className={`inline-block text-lg`} />
          ) : (
            <BsGenderFemale className={`inline-block text-lg `} />
          )}
        </div>
        <div className="text-left w-[70%] inline-flex">
          <span className="text-sm mr-2 font-medium w-fit">
            {voice.name.charAt(0).toUpperCase() + voice.name.slice(1)}
          </span>
          <span className="text-sm overflow-hidden w-full text-ellipsis whitespace-nowrap inline-block">
            {voice.language}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Voice;
