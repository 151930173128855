import 'react-toastify/dist/ReactToastify.min.css';
import { useAuth } from './hooks/useAuth';
import AuthModal from './components/Modals/AuthModal';
import ChatPage from './pages/ChatPage';
import { Route, Routes } from 'react-router';
import GenerateApiKeyPage from './pages/GenerateApiKeyPage';
import EnterpriseCall from './pages/EnterpriseCall';
import VerifyEmailModal from './components/Modals/VerifyEmailModal';
import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';

const App = () => {
  const { authModal, setAuthModal } = useAuth();

  useEffect(() => {
    // * Microsoft Clarity
    if (import.meta.env.VITE_ENVIRONMENT !== 'development') {
      clarity.init('hjq7qa3dcn');
    }
  }, []);
  return (
    <>
      <VerifyEmailModal />
      <AuthModal open={authModal} setOpen={setAuthModal} />
      <Routes>
        <Route path="generate-api-key" element={<GenerateApiKeyPage />} />
        <Route path="enterprise-call" element={<EnterpriseCall />} />
        {/* <Route path="stream" element={<ChatPage isWebRTC />} /> */}
        {/* <Route path="stream-test" element={<ChatPage isWebRTC />} />
        <Route path="stream-chunk" element={<ChatPage isWebRTC />} /> */}
        <Route path="*" element={<ChatPage isWebRTC={false} />} />
      </Routes>
    </>
  );
};

export default App;
