import { useChat } from '../../hooks/useChat';
import { useEffect, useRef, useState } from 'react';
import { useKinesis } from '../../hooks/useKinesis';
import { useAuth } from '../../hooks/useAuth';

const credentials = {
  accessKeyId: '',
  secretAccessKey: ''
};

const WebRtcVideo = () => {
  const { handleVideoEnd, avatarVideos, onVideoLoaded, channelARN } = useChat();
  const { user } = useAuth();
  const remoteRef = useRef<HTMLVideoElement | null>(null);
  const { startViewer, stopViewer, refreshIce } = useKinesis(
    remoteRef,
    channelARN,
    {
      region: import.meta.env.VITE_REGION,
      forceTURN: 'relay',
      natTraversalDisabled: false,
      openDataChannel: false,
      useTrickleICE: true,
      ...credentials
    },
    () => {
      console.log('start');
      setIsStarted(true);
      onVideoLoaded(null);
    },
    () => {
      console.log('end');
      setIsStarted(false);

      if (isStarted) {
        handleVideoEnd();
      }
    },
    (e) => {
      console.log('[WEBRTC]', e.message);
      setTimeout(() => {
        setIsStarted(false);
      }, 1000);
    }
  );
  const [isStarted, setIsStarted] = useState(false);
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (user && channelARN && remoteRef.current) {
      if (!isFirstRender.current) refreshIce();
      isFirstRender.current = false;
    }
  }, [user, channelARN, avatarVideos]);

  useEffect(() => {
    if (user && channelARN && remoteRef.current) {
      console.log({ channelARN });
      startViewer();

      return () => {
        stopViewer().catch();
      };
    }
  }, [user, channelARN]);

  return (
    <div>
      <div
        className={`w-full h-full absolute z-10 transition-all ease-in-out duration-150 ${
          isStarted ? 'opacity-100' : 'delay-100 opacity-0'
        }`}
      >
        <video
          className="border w-full h-full bg-transparent"
          ref={remoteRef}
          autoPlay
          muted
          playsInline
          onLoadedData={(e) => {
            e.currentTarget.play();
            remoteRef.current!.muted = false;
          }}
        />
      </div>
    </div>
  );
};

export default WebRtcVideo;
