import Profile from './Profile';
import { useChat } from '../../hooks/useChat';
import { useState, MouseEvent } from 'react';
import InviteFriends from '../Modals/InviteFriends';
import { useGuide } from '../../hooks/useGuide';
import { useAuth } from '../../hooks/useAuth';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import FilledButton from '../shared/Buttons/FilledButton';
import OutlinedButton from '../shared/Buttons/OutlinedButton';
import { IoClose, IoLogoDiscord } from 'react-icons/io5';
import { GrClose } from 'react-icons/gr';
import useAvatarStore from '../../stores/useAvatarStore';
import useVoiceStore from '../../stores/useVoiceStore';
import { useNotificationStore } from '../../stores/useNotificationStore';

interface ILink {
  disabled: boolean;
  link: string;
  text: string;
  onClick?: () => void;
}

type Props = {
  onClose: () => void;
  onCloseDevSection: () => void;
  navbarOpened: boolean;
  devSectionOpened: boolean;
  onOpenNavbar: () => void;
  onOpenDevSection: () => void;
};

const NavBar = ({
  onClose,
  onOpenNavbar,
  navbarOpened,
  devSectionOpened,
  onOpenDevSection,
  onCloseDevSection
}: Props) => {
  const { createNewChat, message } = useChat();
  const [isInviteFriendModal, setIsInviteFriendModal] = useState(false);
  const { isAuth, logout, setAuthModal, userData } = useAuth();
  const isSMWidth = window.innerWidth >= 640;
  const links: ILink[] = [
    {
      text: 'Start new chat',
      disabled: !isAuth,
      link: '/chatIcon.svg',
      onClick: createNewChat
    },
    {
      text: 'Invite a friend',
      disabled: false,
      link: '/shareIcon.svg',
      onClick: () => {
        if (!isAuth) return setAuthModal('login');
        setIsInviteFriendModal(true);
      }
    },
    {
      text: 'For developers',
      disabled: false,
      link: 'devIcon.svg',
      onClick: () =>
        devSectionOpened ? onCloseDevSection() : onOpenDevSection()
    },
    {
      text: isAuth ? 'Log out' : 'Sign in / Sign up',
      disabled: false,
      link: '/signoutIcon.svg',
      onClick: async () => {
        if (isAuth) {
          await logout();
        } else {
          setAuthModal('login');
        }
        if (window.innerWidth < 1024) onClose();
      }
    }
  ];
  const { handleStartGuide } = useGuide();
  const showSuccessMessage = useNotificationStore(
    (state) => state.showSuccessMessage
  );
  const isMinimizedNavbar = !isSMWidth && devSectionOpened;
  const avatarId = useAvatarStore((state) => state.activeAvatar?.id);
  const voiceId = useVoiceStore((state) => state.selectedVoice?.voiceId);
  return (
    <>
      <nav
        className={`xl:static top-0 max-xl:z-20 h-auto min-h-screen transition-transform absolute xl:translate-x-[0%] flex-row flex
        ${
          isMinimizedNavbar || navbarOpened
            ? 'translate-x-[0%]'
            : 'translate-x-[-100%]'
        }
        
      `}
      >
        <button
          className={`w-6 h-11 bg-secondary absolute left-full top-20 rounded-r-lg xl:block
          ${isMinimizedNavbar || navbarOpened ? 'block' : 'hidden'}`}
          onClick={navbarOpened ? onClose : onOpenNavbar}
        >
          {navbarOpened ? (
            <IoIosArrowBack
              width="7px"
              height="11px"
              color="white"
              style={{ margin: '0 auto' }}
            />
          ) : (
            <IoIosArrowForward
              width="7px"
              height="11px"
              color="white"
              style={{ margin: '0 auto' }}
            />
          )}
        </button>
        <div
          className={` py-8 px-4 bg-white dark:bg-neutral-900 border-r flex border-solid border-neutral-200 dark:border-neutral-800 xl:py-12  xl:static flex-col z-2 h-screen xl:flex top-0 w-[280px] xl:h-full
          ${navbarOpened ? 'xl:w-[232px] px-6' : ' xl:w-auto px-2'} ${
            isMinimizedNavbar ? 'w-auto' : ''
          }`}
        >
          <div className="flex items-center justify-center mb-10 xl:mb-[60px] mx-auto">
            <img src="/logo.svg" className="w-12 h-12" alt="logo" />
            <h3
              className={`uppercase text-black dark:text-white text-[30px] font-semibold mx-2
            ${navbarOpened ? 'block' : 'hidden'}`}
            >
              Yepic
            </h3>
            <div
              className={`flex h-8 items-center ${
                navbarOpened ? 'block' : 'hidden'
              }`}
            >
              <img src="/ai1.svg" className="mr-1.5 w-auto h-auto" />
              <img src="/ai2.svg" className="mr-0.5 w-auto h-auto" />
              <img src="/ai3.svg" className="w-auto h-auto" />
            </div>
          </div>
          <ul
            className={`text-[#404040] dark:text-neutral-100 text-base flex flex-col ${
              navbarOpened ? 'items-start' : 'items-center'
            }`}
          >
            {links.map(({ link, text, disabled, onClick }) => (
              <li className="py-3" key={text + link}>
                <button
                  type="button"
                  className={`flex items-center disabled:opacity-50 mx-0 min-h-[24px]
                  ${navbarOpened ? 'mx-0' : 'mx-auto'}`}
                  disabled={disabled}
                  onClick={onClick}
                >
                  <img
                    src={link}
                    className={navbarOpened ? 'mr-[19px]' : 'mr-0'}
                  />
                  <div className={navbarOpened ? 'block' : 'hidden'}>
                    {text}
                  </div>
                </button>
              </li>
            ))}
          </ul>
          <div className="my-auto">
            <div
              className={`p-2 border border-neutral-200 dark:border-neutral-700 rounded-lg 
            ${navbarOpened ? 'block' : 'hidden'}`}
            >
              <span className="text-neutral-900 font-semibold dark:text-neutral-50">
                How it works
              </span>
              <p className="text-neutral-600 text-sm dark:text-neutral-300">
                Learn what you can do it Chat GP-Me
              </p>
              <button
                className="text-secondary font-semibold mt-2"
                onClick={() => {
                  handleStartGuide();
                  if (window.innerWidth < 1280) onClose();
                }}
              >
                Show me
              </button>
            </div>
          </div>
          <Profile navbarOpened={navbarOpened} />
        </div>
        {devSectionOpened && (
          <div className="z-2 w-[212px] min-[400px]:w-[282px] xl:h-full xl:w-[17.625rem] h-screen max-h-screen overflow-y-auto flex flex-col py-8 px-6 gap-8 bg-white border-r border-solid border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800">
            <div className="flex justify-between items-center dark:!text-white">
              <h2 className="text-lg font-semibold">For developers</h2>
              <IoClose
                className="w-5 h-5 cursor-pointer"
                onClick={() => onCloseDevSection()}
              />
            </div>
            <FilledButton
              className="w-full py-2 !text-primary-700 text-sm font-semibold !bg-primary-50 dark:!bg-primary-700 dark:!text-primary-100"
              onClick={() =>
                window.open('https://docs.yepic.ai/docs', '_blank')
              }
            >
              API Documents
            </FilledButton>
            <div className="flex flex-col gap-4">
              <h3 className="text-sm font-medium text-neutral-700 dark:text-neutral-300">
                Your API key
              </h3>
              <span
                className="px-4 py-3.5 border border-neutral-300 shadow-sm rounded-md text-md text-neutral-500 break-all bg-neutral-50 cursor-copy dark:border-neutral-700 dark:bg-neutral-900"
                onMouseDown={() =>
                  navigator.clipboard
                    .writeText(userData?.apiKey || '')
                    .then(() => showSuccessMessage('API Key Copied'))
                }
              >
                {userData?.apiKey}
              </span>
            </div>
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
            <div className="flex flex-col gap-2">
              <h3 className="text-sm font-medium text-neutral-700 dark:text-neutral-200">
                Example code
              </h3>
              <div
                className="px-4 py-3.5 border border-neutral-300 shadow-sm rounded-md text-md text-neutral-500 break-all cursor-copy dark:text-neutral-200 dark:border-neutral-700"
                onMouseDown={() =>
                  navigator.clipboard
                    .writeText(
                      `curl --request POST \\
     --url https://fast-video-api-vktictsuea-nw.a.run.app/api/v1/videos \\
     --header 'X-API-Key: ${userData?.apiKey || 'YOUR API KEY'}' \\
     --header 'accept: application/json' \\
     --header 'content-type: application/json' \\
     --data '
{
  "videoFormat": "mp4",
  "videoName": "Fastvideo",
  "avatarId": "${avatarId || 'avatarId'}",
  "voiceId": "${voiceId || 'voiceId'}",
  "script": "${message || ''}"
}
'`
                    )
                    .then(() => showSuccessMessage('Example code copied'))
                }
              >
                curl --request POST \<br />
                --url
                https://fast-video-api-vktictsuea-nw.a.run.app/api/v1/videos \
                <br />
                --header &apos;X-API-Key: {userData?.apiKey || 'YOUR API KEY'}
                &apos; \<br />
                --header &apos;accept: application/json&apos; \<br />
                --header &apos;content-type: application/json&apos; \<br />
                --data &apos;
                <br />
                {'{'}
                <br />
                &quot;videoFormat&quot;: &quot;mp4&quot;,
                <br />
                &quot;videoName&quot;: &quot;Fastvideo&quot;,
                <br />
                &quot;avatarId&quot;: &quot;{avatarId || 'avatarId'}&quot;,
                <br />
                &quot;voiceId&quot;: &quot;{voiceId || 'voiceId'}&quot;,
                <br />
                &quot;script&quot;: &quot;{message || ''}&quot;
                <br />
                {'}'}
              </div>
            </div>
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
            {/* <OutlinedButton
              className="w-full px-4 py-2 !text-md font-semibold"
              disabled
              onClick={() => {}}
            > */}
            <a
              href="https://discord.gg/zSkDkBzeR5"
              target="_blank"
              rel="noreferrer noopener"
              className="w-full px-4 py-2 !text-md font-semibold flex items-center text-sm disabled:opacity-50 rounded-lg border-neutral-200 dark:border-neutral-700 border hover:bg-neutral-50 justify-center dark:hover:bg-neutral-900 dark:text-white"
            >
              <IoLogoDiscord className="w-6 h-6 mr-3 text-neutral-400" />
              Join Discord
            </a>

            {/* </OutlinedButton> */}
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
            <div className="w-full px-4 py-5 flex flex-col gap-4 bg-neutral-50 rounded-lg dark:bg-neutral-900">
              <div className="flex flex-col gap-1">
                <h3 className="text-sm font-semibold text-neutral-900/50 dark:text-neutral-50">
                  Get 1000 credits
                </h3>
                <span className="text-sm text-neutral-600/50 dark:text-neutral-200">
                  Create your app and share it in Discord
                </span>
              </div>
              <button className="w-fit text-primary-700/50 text-sm font-semibold">
                See details
              </button>
            </div>
          </div>
        )}
      </nav>
      <InviteFriends
        open={isInviteFriendModal}
        onClose={() => setIsInviteFriendModal(false)}
      />
    </>
  );
};

export default NavBar;
