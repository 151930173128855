import axios from 'axios';
import { getMemberstackJWT } from '../../utils/getMemberstackJWT';

const baseURL = import.meta.env.VITE_SHARE_ENDPOINT;

const instance = axios.create({
  baseURL,
  timeout: 60000
});

const httpInviteService = {
  get: async (url: string) => {
    const data = instance.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getMemberstackJWT()}`
      }
    });
    return data;
  },
  post: async (url: string, payload: any = {}) => {
    const data = instance.post(url, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getMemberstackJWT()}`
      }
    });
    return data;
  },
  delete: async (url: string) => {
    const data = instance.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getMemberstackJWT()}`
      }
    });
    return data;
  }
};

export default httpInviteService;
