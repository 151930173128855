import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const FormError = ({ children }: Props) => {
  return (
    <p className="mt-2 text-sm text-red-600 text-left block" id="email-error">
      {children}
    </p>
  );
};

export default FormError;
