import clsx from 'clsx';

type SwitchButtonProps = {
  onSwitch: () => void;
  isActive: boolean;
  label: string;
};

const SwitchButton = ({ onSwitch, isActive, label }: SwitchButtonProps) => {
  return (
    <div
      className={clsx(
        'w-32 sm:w-48 h-9 flex justify-center items-center rounded-lg ',
        isActive
          ? 'bg-white cursor-default border border-neutral-200 shadow-sm text-neutral-700 dark:bg-neutral-800 dark:text-neutral-100 dark:border-neutral-700'
          : 'bg-neutral-100 cursor-pointer text-neutral-500 dark:bg-neutral-700 dark:text-neutral-300'
      )}
      onClick={onSwitch}
    >
      {label}
    </div>
  );
};

export default SwitchButton;
