import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useSpeak } from '../../hooks/useSpeak';
import Input from '../shared/Input';
import { chatService } from '../../services/chat.service';
import { useChat } from '../../hooks/useChat';
import FilledButton from '../shared/Buttons/FilledButton';
import Step from '../Step';
import { useGuide } from '../../hooks/useGuide';
import { useAuth } from '../../hooks/useAuth';
import clsx from 'clsx';
import Loader from '../Loader';
import { useNotificationStore } from '../../stores/useNotificationStore';

type MessageInputProps = {
  isChatbot: boolean;
};

const isMobile =
  !!window.navigator.maxTouchPoints ||
  /iphone|ipod|android|ie|blackberry|fennec/i.test(navigator.userAgent);

const MessageInput = ({ isChatbot }: MessageInputProps) => {
  const {
    sendMessage,
    sendDefaultMessage,
    assistantMessage,
    defaultMessages,
    message,
    onMessageChange
  } = useChat();
  const { checkCreditBalanceAndShowError, isAuth, setAuthModal } = useAuth();
  const [isTextLoading, setIsTextLoading] = useState(false);
  const showError = useNotificationStore((state) => state.showError);

  const handleSendMessageWithVoice = async (audio: Blob) => {
    setIsTextLoading(true);
    try {
      const text = await chatService.translateAudio(audio);
      setIsTextLoading(false);

      if (text.trim() === '') return;
      checkCreditBalanceAndShowError();

      return sendMessage(text);
    } catch (error) {
      setIsTextLoading(false);
      if (error instanceof Error) {
        showError(
          `Sorry, there was a problem when we tried to transcribe audio. Error message: ${error.message}`
        );
      }
    }
  };

  const { startRecording, isRecording, stopRecording } = useSpeak(
    handleSendMessageWithVoice
  );

  const handleStartRecording = () => {
    if (!isAuth) return setAuthModal('login');
    startRecording();
  };

  const handleSendMessage = () => {
    // checkCreditBalanceAndShowError();

    return sendMessage(message);
  };

  const { stepIndex } = useGuide();

  useEffect(() => {
    if (!isMobile) {
      document.addEventListener('mouseup', stopRecording);
      document.addEventListener('mouseleave', stopRecording);
    }

    return () => {
      if (!isMobile) {
        document.removeEventListener('mouseup', stopRecording);
        document.removeEventListener('mouseleave', stopRecording);
      }
    };
  }, [isRecording]);

  return (
    <div
      className={clsx(
        'relative w-full flex flex-col gap-y-4 justify-center items-center px-6 py-4 bg-white dark:bg-dark-primary',
        stepIndex === 1 && 'z-20',
        isChatbot && 'bg-white'
      )}
    >
      <Step mobileSteps={1} className="-top-full -mt-9 xl:-mt-3 " />
      {!isChatbot && defaultMessages && defaultMessages.length > 0 && (
        <div className="flex w-full h-fit gap-4 overflow-x-auto no-scrollbar">
          {defaultMessages
            .filter((msg) => !msg.hidden)
            .map((msg) => (
              <button
                key={msg._id}
                type="button"
                className="p-1 bg-neutral-100 rounded-[0.675rem] whitespace-nowrap dark:bg-neutral-800 dark:text-neutral-100 "
                onClick={() => sendDefaultMessage(msg)}
              >
                {msg.question}
              </button>
            ))}
        </div>
      )}
      <div className="w-full flex gap-x-4">
        <div className="w-full h-fit text-end">
          <Input
            className={clsx(
              'w-full h-10 mb-3 px-3.5 py-3',
              isChatbot ? 'mb-0' : 'mb-3'
            )}
            placeholder="Send a message"
            value={message}
            onChange={(e) => onMessageChange(e.target.value)}
            onKeyUp={(e) =>
              (message || !assistantMessage) &&
              e.code === 'Enter' &&
              handleSendMessage()
            }
          />
          <div className="flex justify-between max-sm:text-xs">
            <span className="text-red-600">
              {isRecording && 'Listening...'}
            </span>
            {!isChatbot && (
              <span className="text-neutral-900">{message.length}/350</span>
            )}
          </div>
        </div>
        <FilledButton
          className="h-fit px-4 py-2.5"
          disabled={!message || !!assistantMessage}
          onClick={handleSendMessage}
        >
          Send
        </FilledButton>
        <div className="relative">
          <Step steps={1} className="bottom-full mb-5 right-1/4" />
          <FilledButton
            className={clsx(
              `w-9 h-9 p-2 !rounded-full`,
              isRecording && '!bg-red-500'
            )}
            onMouseDown={() => {
              if (!isMobile || !isRecording) return handleStartRecording();
              if (isMobile && isRecording) return stopRecording();
            }}
            onMouseUp={() => !isMobile && stopRecording()}
          >
            {isTextLoading ? (
              <Loader color="#fff" size={15} />
            ) : (
              <svg
                className="w-6 h-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M12 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3m7 9c0 3.53-2.61 6.44-6 6.93V21h-2v-3.07c-3.39-.49-6-3.4-6-6.93h2a5 5 0 0 0 5 5a5 5 0 0 0 5-5h2Z"
                />
              </svg>
            )}
          </FilledButton>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
