import {
  Dispatch,
  FC,
  Fragment,
  ReactNode,
  SetStateAction,
  useRef
} from 'react';
import { Dialog, Transition } from '@headlessui/react';

export interface ModalProps {
  open?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  closeButton?: ReactNode;
  children?: ReactNode;
  modalTitle?: string | ReactNode;
  icon?: ReactNode;
  noPadding?: boolean;
}

const Modal: FC<ModalProps> = ({
  open,
  setOpen,
  closeButton,
  children,
  modalTitle,
  noPadding = false
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-indigo-300 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className={`fixed inset-0 z-10 overflow-y-auto`}>
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-screen-md w-11/12 md:w-11/12 lg:w-2/3 xl:w-7/12">
                <div
                  className={noPadding ? '' : 'px-4 pt-5 pb-4 sm:p-8 sm:pb-4'}
                >
                  <div className="sm:flex sm:items-start">
                    <div className="text-center w-full sm:text-left">
                      <div className="relative flex flex-row justify-center">
                        <Dialog.Title as="h3" className="flex">
                          <div className="my-auto">{modalTitle}</div>
                        </Dialog.Title>
                        {closeButton}
                      </div>
                      <div className={modalTitle ? `mt-2` : ''}>{children}</div>
                    </div>
                  </div>
                </div>
                {/* <div className="bg-gray px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6" /> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
