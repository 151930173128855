import type { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';

const Input: FC<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({ className, ...props }) => {
  return (
    <input
      className={`text-base border border-neutral-300 rounded-md text-neutral-900 placeholder:text-neutral-500 focus-visible:outline-secondary disabled:bg-white disabled:opacity-50 dark:bg-neutral-800 dark:text-neutral-100 dark:border-neutral-700 dark:ring-0 ${className}`}
      {...props}
    />
  );
};

export default Input;
