import { IIntegrateFormValues } from '../types/types';
import httpService from './http/http.service';

const integrationService = {
  postIntegrationRequest: async (payload: IIntegrateFormValues) => {
    const data = await httpService.post('/integrations/', payload);
    return data;
  }
};

export default integrationService;
