import type { FC } from 'react';
import { useGuide } from '../hooks/useGuide';
import { GrClose } from 'react-icons/gr';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { PositionT } from '../types/types';

const Step: FC<{
  className?: string;
  steps?: number | number[];
  mobileSteps?: number | number[];
  customPosition?: PositionT;
  customMobilePosition?: PositionT;
}> = ({
  className = '',
  steps,
  mobileSteps,
  customPosition: customPositionDesktop,
  customMobilePosition
}) => {
  const {
    stepIndex,
    handleNextStep,
    handleEndGuide,
    stepsLength,
    isMobile,
    step
  } = useGuide();
  if (
    !step ||
    !stepIndex ||
    (!isMobile
      ? typeof steps === 'number'
        ? steps !== stepIndex
        : !steps?.includes(stepIndex)
      : typeof mobileSteps === 'number'
      ? mobileSteps !== stepIndex
      : !mobileSteps?.includes(stepIndex))
  )
    return null;
  const customPosition = isMobile
    ? customMobilePosition
    : customPositionDesktop;
  const position = (() => {
    switch (customPosition || step.position) {
      case 'top-right':
        return 'border-b-0 !border-l border-r-0 right-2 top-[-0.5rem]';
      case 'top-center':
        return 'border-b-0 !border-l border-r-0 left-[calc(50%-0.45rem)] bottom-[calc(100%-0.45rem)]';
      case 'bottom-center':
        return 'border-t-0 left-[calc(50%-0.45rem)] top-[calc(100%-0.45rem)]';
      case 'bottom-right':
        return 'border-t-0 right-2 top-[calc(100%-0.45rem)]';
      case 'right-top':
        return 'border-b-0 left-[calc(100%-0.45rem)] top-2';
    }
  })();
  return (
    <div
      className={`bg-white border border-neutral-200 rounded-lg w-[18.75rem] p-3 z-100 absolute cursor-default ${className}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between h-[1.375rem]">
        <span className="text-neutral-700 font-semibold self-center">
          {stepIndex}/{stepsLength} {step.name}
        </span>
        <GrClose
          className="w-4 h-4 self-start cursor-pointer"
          onClick={handleEndGuide}
        />
      </div>
      <div className="mt-1 text-sm text-neutral-500 font-medium text-left">
        {step.text}
      </div>
      <button
        className="text-md mt-2 flex gap-x-2 items-center text-secondary ml-auto"
        onClick={handleNextStep}
      >
        {stepIndex !== stepsLength ? (
          <>
            Next <HiOutlineChevronRight className="w-5 h-5" />
          </>
        ) : (
          'Finish'
        )}
      </button>
      <div
        className={`w-4 h-4 border-l-0 bg-white border border-neutral-200 rotate-45 absolute ${position}`}
      />
    </div>
  );
};

export default Step;
