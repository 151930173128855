import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import GuideProvider from './contexts/GuideContext';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './contexts/AuthContext';
import ChatProvider from './contexts/ChatContext';
import * as Sentry from '@sentry/react';

if (import.meta.env.VITE_ENVIRONMENT !== 'development') {
  Sentry.init({
    dsn: 'https://4e79b6c8f54a4681839a2252f0f1a81e@o230804.ingest.sentry.io/4505154450882560',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: import.meta.env.VITE_ENVIRONMENT,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <AuthProvider>
    <BrowserRouter>
      <GuideProvider>
        <ChatProvider>
          <App />
        </ChatProvider>
      </GuideProvider>
    </BrowserRouter>
  </AuthProvider>
);
