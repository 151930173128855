import MobileTabsGroup from '../shared/MobileTabsGroup';
import { useState, useEffect, useRef } from 'react';
import { VscChevronDown } from 'react-icons/vsc';
import Input from '../shared/Input';
import FilledButton from '../shared/Buttons/FilledButton';
import OutlinedButton from '../shared/Buttons/OutlinedButton';
import { useLocation } from 'react-router';
import Step from '../Step';
import { useGuide } from '../../hooks/useGuide';
import TabsGroup, { Tab } from '../shared/TabsGroup';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import './sideBarAnimation.css';
import { CSSTransition } from 'react-transition-group';
import EnterpriseModal from '../Modals/EnterpiseModal';
import AvatarsList from './Avatars/AvatarsList';
import VoicesPanel from './Voices/VoicesPanel';
import useAvatarStore from '../../stores/useAvatarStore';
import { useAuth } from '../../hooks/useAuth';
import useVoiceStore from '../../stores/useVoiceStore';
import { IWebRTCProps } from '../../types/types';

const tabs: readonly Tab[] = [
  {
    name: 'avatar',
    title: 'Avatar',
    icon: '/avatarIcon.svg'
  },
  {
    name: 'voice',
    title: 'Voice',
    icon: '/voiceIcon.svg'
  },
  {
    name: 'style',
    title: 'Style',
    icon: '/styleIcon.svg'
  },
  {
    name: 'engine',
    title: 'Engine',
    icon: '/engineIcon.svg'
  }
] as const;

type SideBarProps = {
  sidebarOpened: boolean;
  onClose: () => void;
  onOpenSidebar: () => void;
};

const styles = ['Friendly', 'Cheeky', 'Professional', 'Happy'] as const;
const engines = [
  {
    name: 'GPT-3.5 Turbo (Yepic demo)',
    provider: 'Open AI',
    icon: <img src="/gpt.png" alt="gpt" className="w-6 h-6" />,
    apiKey: false
  },
  {
    name: 'GPT-3.5 Turbo (Custom)',
    provider: 'Open AI',
    icon: <img src="/gpt.png" alt="gpt" className="w-6 h-6" />,
    apiKey: false
  },
  {
    name: 'Jasper Chat',
    provider: 'Jasper AI',
    icon: <img src="/jasper.png" alt="jasper" className="w-6 h-6" />,
    apiKey: false
  },
  {
    name: 'Luminous (Custom)',
    provider: 'Aleph Alpha',
    icon: <img src="/luminous.png" alt="aleph" className="w-6 h-6" />,
    apiKey: false
  }
] as const;
type NameT = (typeof tabs)[number]['name'];

const SideBar = ({
  sidebarOpened,
  onClose,
  onOpenSidebar,
  isWebRTC
}: SideBarProps & IWebRTCProps) => {
  const [activeTab, setActiveTab] = useState<NameT>(tabs[0].name);
  const [enterpriseModalOpened, setEnterpriseModalOpened] = useState(false);

  const sideBarRef = useRef(null);

  const { hash } = useLocation();

  useEffect(() => {
    const slug = hash.split('#')[1];
    if (tabs.some(({ name }) => name === slug)) setActiveTab(slug as NameT);
  }, [hash]);

  const [activeStyle, setActiveStyle] =
    useState<(typeof styles)[number]>('Friendly');

  const [activeEngine, setActiveEngine] = useState<
    (typeof engines)[number]['name']
  >('GPT-3.5 Turbo (Yepic demo)');

  const { stepIndex, isMobile } = useGuide();

  const { user } = useAuth();

  const fetchAvatars = useAvatarStore((state) => state.fetchAvatars);
  const fetchVoices = useVoiceStore((state) => state.fetchVoices);

  useEffect(() => {
    fetchAvatars();
    fetchVoices(isWebRTC);
  }, [user]);

  return (
    <div
      className={`max-sm:w-full w-[276px] flex grow xl:h-full fixed xl:w-auto
      min-h-screen h-auto transition-transform xl:translate-x-0 xl:static top-0 right-0 z-10
        ${
          sidebarOpened
            ? 'translate-x-[-100%] left-full'
            : 'translate-x-[100%] left-full'
        } ${
        (stepIndex &&
          stepIndex >= 2 + +isMobile &&
          stepIndex <= 5 + +isMobile) ||
        sidebarOpened
          ? 'z-20'
          : 'z-10'
      }
      `}
    >
      <CSSTransition
        timeout={{
          enter: 100,
          exit: 100
        }}
        classNames="sidebar"
        nodeRef={sideBarRef}
        in={sidebarOpened}
      >
        <div
          className={`sidebar flex flex-col grow shrink-0 h-screen max-sm:w-full pb-4 w-[276px] xl:pt-5 transition-transform bg-white -z-10 sm:border-l border-solid border-neutral-200 dark:bg-neutral-900 dark:border-neutral-800`}
          ref={sideBarRef}
        >
          <button
            className={`w-6 h-11 bg-secondary absolute right-full top-20 rounded-l-lg
              ${sidebarOpened ? 'xl:block' : 'hidden'}`}
            onClick={sidebarOpened ? onClose : onOpenSidebar}
          >
            {sidebarOpened ? (
              <IoIosArrowForward
                width="7px"
                height="11px"
                color="white"
                style={{ margin: '0 auto' }}
              />
            ) : (
              <IoIosArrowBack
                width="7px"
                height="11px"
                color="white"
                style={{ margin: '0 auto' }}
              />
            )}
          </button>
          <div className="relative">
            <MobileTabsGroup
              tabs={tabs}
              activeTab={activeTab}
              toggleTab={(tab) => stepIndex === null && setActiveTab(tab)}
              className="grid grid-cols-4 mb-8 xl:hidden"
            />
          </div>
          <Step
            steps={[2, 3, 4, 5]}
            mobileSteps={[3, 4, 5, 6]}
            className={`top-44 xl:top-40 right-[calc(100%+20px)] hidden sm:block`}
            customMobilePosition="right-top"
          />
          {isMobile &&
            typeof stepIndex === 'number' &&
            stepIndex >= 3 &&
            stepIndex <= 6 && (
              <div
                className={`absolute left-0 right-0 bottom-0 sm:hidden bg-[#000000]/70 ${
                  (stepIndex === 6 && 'top-[calc(378px)]') ||
                  (stepIndex === 3 && 'top-[calc(250px+100vw/2)] z-10') ||
                  'top-[calc(343px)]'
                }`}
              />
            )}
          <Step
            mobileSteps={[3]}
            className="left-1/2 -translate-x-1/2 sm:hidden top-[calc(280px+100vw/2)] z-20"
          />
          <Step
            mobileSteps={[4, 5]}
            className="left-1/2 -translate-x-1/2 sm:hidden top-[calc(375px)]"
          />
          <Step
            mobileSteps={[6]}
            className="left-1/2 -translate-x-1/2 sm:hidden top-[calc(410px)]"
          />
          {activeTab === 'avatar' && <AvatarsList />}
          {activeTab === 'voice' && <VoicesPanel />}
          {activeTab === 'style' && (
            <div className="px-4 overflow-auto flex flex-col gap-y-2">
              {styles.map((style, inx) => (
                <OutlinedButton
                  key={`${inx}-style`}
                  className="w-full justify-start px-4 py-3"
                  borderSecondary={style === activeStyle}
                  textSecondary={style === activeStyle}
                  onClick={() => setActiveStyle(style)}
                >
                  {style}
                </OutlinedButton>
              ))}
            </div>
          )}

          {activeTab === 'engine' && (
            <div className="px-4 overflow-auto flex flex-col gap-y-2">
              {engines.map(({ icon, provider, name, apiKey }) => {
                const isActive: boolean = name === activeEngine;

                return (
                  <div
                    key={`${name}-engine`}
                    className={`border ${
                      isActive
                        ? 'border-secondary'
                        : 'border-neutral-200 dark:border-neutral-700'
                    } items-center text-sm disabled:opacity-50 rounded-lg w-full justify-start flex flex-col px-4 py-3 gap-3 cursor-pointer`}
                    onClick={() => {
                      // setActiveEngine(name);
                      setEnterpriseModalOpened(true);
                    }}
                  >
                    <div className="w-full flex flex-row gap-x-3 items-center dark:text-white">
                      {icon}
                      <div className="flex flex-col text-start">
                        <span>{name}</span>
                        <span>{provider}</span>
                      </div>
                    </div>
                    {apiKey && isActive && (
                      <>
                        <Input
                          type="text"
                          className="w-full"
                          placeholder="Enter API Key"
                        />
                        <FilledButton
                          className="w-full py-2"
                          onClick={() => setEnterpriseModalOpened(true)}
                        >
                          Submit
                        </FilledButton>
                        <OutlinedButton className="w-full py-2">
                          Cancel
                        </OutlinedButton>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <div className="p-4 pb-0 border-t border-neutral-200 sm:hidden mt-auto dark:border-neutral-800">
            <OutlinedButton
              className="py-2.5 w-full text-md font-semibold rounded-md border-neutral-300 dark:border-neutral-800"
              onClick={onClose}
            >
              Close
            </OutlinedButton>
          </div>
        </div>
      </CSSTransition>
      <TabsGroup
        tabs={tabs}
        activeTab={activeTab}
        toggleTab={(tab) => stepIndex === null && setActiveTab(tab)}
        onOpen={sidebarOpened ? () => null : onOpenSidebar}
        sidebarOpened={sidebarOpened}
      />
      <EnterpriseModal
        open={enterpriseModalOpened}
        onClose={() => setEnterpriseModalOpened(false)}
      />
    </div>
  );
};

export default SideBar;
